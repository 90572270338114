import { gql } from "@apollo/client";

export const CREATE_USER_TAGS = gql`
    mutation CreateUserTag($userTagInput: UserTagInput!) {
        createUserTag(userTagInput: $userTagInput) {
            id
            isSuccess
            error
        }
    }
`;

export const GET_USER_TAG = gql`
    query GetUserTag($userId: ID!) {
        getUserTag(userId: $userId) {
            id
            userId
            tag
        }
    }
`;