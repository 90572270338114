import { FC, useEffect, useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Box,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import TimerIcon from "@mui/icons-material/Timer";
import {
  CREATE_USER_EVENT_FAVOURITE,
  DELETE_USER_EVENT_FAVOURITE,
} from "../../gql/events";
import { useAuth } from "../../contexts/AuthContext";
import { useMutation } from "@apollo/client";

interface EventCardProps {
  event: any;
  isFavourite: boolean;
}

export const EventCard: FC<EventCardProps> = ({ event, isFavourite }) => {
  const navigate = useNavigate();
  const { currentUser, loading } = useAuth();
  const [favToggle, setFavToggled] = useState(isFavourite);
  const [createUserEventFavouriteMutation] = useMutation(
    CREATE_USER_EVENT_FAVOURITE,
    {
      refetchQueries: ["GetUserEventFavouriteByUserId"],
    }
  );
  const [deleteUserEventFavouriteMutation] = useMutation(
    DELETE_USER_EVENT_FAVOURITE,
    {
      refetchQueries: ["GetUserEventFavouriteByUserId"],
    }
  );

  useEffect(() => {
    setFavToggled(isFavourite);
  }, [isFavourite]);

  const toggleFavButton = (id: string) => {
    if (!loading && !currentUser) {
      navigate('/login'); 
      return;
    }
    const eventFavouriteObj = {
      eventId: id,
      userId: currentUser?.profileInfo?.id,
    };
    const mutation = !favToggle
      ? createUserEventFavouriteMutation
      : deleteUserEventFavouriteMutation;
    mutation({
      variables: {
        userEventFavouriteInput: {
          ...eventFavouriteObj,
        },
      },
      onCompleted: (data: any) => {
        if (!favToggle) {
          if (data.createUserEventFavourite) {
            setFavToggled(true);
          }
        } else {
          if (data.deleteUserEventFavourite) {
            setFavToggled(false);
          }
        }
      },
      onError: (error: any) => {
        console.log(error);
        alert(
          "Error while processing this request, please try again after sometime."
        );
      },
    });
  };

  const joinEvent = (e: any) => {
    e?.stopPropagation();
    navigate(`/event-detail?id=${event.id}`);
  };

  const getEventDate = (date: String) => {
    return date?.slice(3, 10);
  };

  return (
    <Box>
      <Card
        style={{ borderRadius: 20, cursor: "pointer" }}
        onClick={(e: any) => joinEvent(e)}
      >
        <Box position={"relative"}>
          <CardMedia
            component="img"
            alt="Event"
            height="180"
            image={event.image}
            style={{ borderRadius: "14px", objectFit: "cover" }}
          />
        </Box>
        <CardContent style={{ paddingBottom: 0 }}>
          <Box>
            <Box sx={[styled.flex, { justifyContent: "space-between" }]}>
              <Typography
                variant="h5"
                component="h5"
                fontSize={16}
                fontWeight={600}
              >
                {event.title}
              </Typography>
              <IconButton
                onClick={(e: any) => {
                  e.stopPropagation();
                  toggleFavButton(event.id);
                }}
              >
                {favToggle && (
                  <FavoriteIcon style={{ color: "#008080" }}></FavoriteIcon>
                )}
                {!favToggle && (
                  <FavoriteBorder style={{ color: "#008080" }}></FavoriteBorder>
                )}
              </IconButton>
            </Box>

            <Typography
              color="#717171"
              fontSize={12}
              style={{ height: "36px", overflow: "hidden" }}
            >
              {event.address}
            </Typography>
          </Box>
          <hr style={styled.hr} />
          <Box
            flexDirection={"row"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            paddingX={1}
          >
            <Box
              flexDirection={"column"}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              <CalendarMonthIcon
                style={{ color: "#008080", fontSize: 20 }}
              ></CalendarMonthIcon>
              <Typography variant="h6" fontSize={12} fontWeight={500}>
                {event?.date}
              </Typography>
            </Box>
            <Box
              flexDirection={"column"}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              <AccessTimeIcon
                style={{ color: "#008080", fontSize: 20 }}
              ></AccessTimeIcon>
              <Typography variant="h6" fontSize={12} fontWeight={500}>
                {event?.time}
              </Typography>
            </Box>
            <Box
              flexDirection={"column"}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              <CurrencyRupeeIcon
                style={{ color: "#008080", fontSize: 20 }}
              ></CurrencyRupeeIcon>
              <Typography variant="h6" fontSize={12} fontWeight={500}>
                {event?.price ? event?.price : "FREE"}
              </Typography>
            </Box>
            <Box
              flexDirection={"column"}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              <TimerIcon style={{ color: "#008080", fontSize: 20 }}></TimerIcon>
              <Typography variant="h6" fontSize={12} fontWeight={500}>
                {event?.duration} hr
              </Typography>
            </Box>
          </Box>
          <hr style={styled.hr} />
          <Box>
            <Typography
              color="#717171"
              fontSize={12}
              fontWeight={"bold"}
              sx={{ height: 90, overflow: "hidden" }}
            >
              {event?.description}
            </Typography>
          </Box>
          <hr style={styled.hr} />
        </CardContent>
        <CardActions
          sx={{ backgroundColor: "white", paddingX: "15px", paddingTop: 0 }}
        >
          <Box sx={styled.flex} justifyContent={"space-between"} width={"100%"}>
            <Box sx={styled.flex} justifyContent={"flex-start"}>
              {event?.category?.toLowerCase() !== "marketplace" && (
                <>
                  <Box>
                    <ConfirmationNumberIcon
                      sx={styled.icon}
                    ></ConfirmationNumberIcon>
                  </Box>
                  <Box>
                    <Typography color="#1B1B1B" fontSize={12}>
                      {event.remainingCapacity} Remaining
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
            <Box>
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "#008080",
                  padding: "5px 8px",
                  fontSize: "12px",
                  opacity: 0.9,
                  ":hover": {
                    bgcolor: "#008080", // theme.palette.primary.main
                    color: "white",
                    opacity: 1,
                  },
                }}
                onClick={(e: any) => joinEvent(e)}
              >
                <Typography
                  component="span"
                  textTransform={"none"}
                  fontSize={12}
                >
                  Join
                </Typography>
              </Button>
            </Box>
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

const styled = {
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    color: "#008080",
    height: "15px",
    marginTop: "5px",
  },
  button: {
    color: "white",
    backgroundColor: "#008080",
    padding: "2px",
    fontSize: "12px",
  },
  hr: {
    marginLeft: 2,
    marginRight: 2,
    borderColor: "##EFEFEF",
  },
};
