import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Avatar,
  Chip,
  Button,
  Box,
  Typography,
  Theme,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import HubIcon from "@mui/icons-material/Hub";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useMutation } from "@apollo/client";
import {
  CREATE_USER_CONNECTION,
  DELETE_USER_CONNECTION,
  UPDATE_USER_CONNECTION,
} from "../../gql/user-connection";
import { useAuth } from "../../contexts/AuthContext";
import { ConfirmDialog } from "../confirm-dialog";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: "auto",
    padding: 16,
  },
  cardContent: {
    padding: 16,
    paddingBottom: 8,
    paddingTop: 8,
  },
  chipContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: 1,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingY: 8,
    paddingX: 16,
    gap: 8,
  },
  header: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#008080",
    color: "white",
    padding: 8,
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: "space-between",
  },
  title: {
    fontSize: 16,
  },
  closeButton: {
    cursor: "pointer",
    fontSize: 22,
  },
}));

interface UserInfoTileProps {
  user: any;
  buttonText?: string;
  id?: string;
  removeCreatedConnection?: (item: any) => void;
}
export const UserInfoTile: React.FC<UserInfoTileProps> = ({
  user,
  buttonText,
  id,
  removeCreatedConnection,
}) => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const [openSendConfirm, setOpenSendConfirm] = useState(false);
  const [openApproveConfirm, setOpenApproveConfirm] = useState(false);
  const [openRejectConfirm, setOpenRejectConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [
    createUserConnectionMutation,
    { loading: createUserConnectionLoading },
  ] = useMutation(CREATE_USER_CONNECTION, {
    refetchQueries: ["GetUserConnection"],
  });
  const [
    updateUserConnectionMutation,
    { loading: updateUserConnectionLoading },
  ] = useMutation(UPDATE_USER_CONNECTION, {
    refetchQueries: ["GetUserConnection"],
  });

  const [
    deleteUserConnectionMutation,
    { loading: deleteUserConnectionLoading },
  ] = useMutation(DELETE_USER_CONNECTION, {
    refetchQueries: ["GetUserConnection"],
  });

  const handleConnectButtonClick = () => {
    if (buttonText === "Approve") {
      setConfirmMessage('Your phone number or email address will be shared with the connected user upon approving this request.')
      setOpenApproveConfirm(true);
    } else {
      setConfirmMessage('Your contact information will be shared with the connected user after this request is approved.')
      setOpenSendConfirm(true);
    }
  };

  const handleRejectRequest = () => {
    setConfirmMessage('Please confirm if you want to reject this connection request.')
    setOpenRejectConfirm(true);
  };

  const approveConnectionRequest = () => {
    setOpenApproveConfirm(false);
    if (!id) return;
    updateUserConnectionMutation({
      variables: {
        userConnectionInput: {
          id: id,
          status: "APPROVED",
        },
      },
      onCompleted: (data: any) => {
        if (data?.updateUserConnection?.isSuccess) {
          console.log("Connection Approved");
          if (removeCreatedConnection) removeCreatedConnection(user);
        } else {
          alert(
            "We are facing an issue while processing your request. Please try again later."
          );
        }
      },
    });
  };

  const sendConnectionRequest = () => {
    setOpenSendConfirm(false);
    createUserConnectionMutation({
      variables: {
        userConnectionInput: {
          userId: currentUser?.profileInfo?.id,
          connectedTo: user?.id,
        },
      },
      onCompleted: (data: any) => {
        if (data?.createUserConnection?.isSuccess) {
          console.log("Connection Created");
          if (removeCreatedConnection) removeCreatedConnection(user);
        } else {
          alert(
            "We are facing an issue while processing your request. Please try again later."
          );
        }
      },
    });
  };

  const rejectConnectionRequest = () => {
    setOpenRejectConfirm(false);
    if (!id) return;
    deleteUserConnectionMutation({
      variables: {
        id: id,
      },
      onCompleted: (data: any) => {
        if (data?.deleteUserConnection?.isSuccess) {
          console.log("Connection Rejected");
          if (removeCreatedConnection) removeCreatedConnection(user);
        } else {
          alert(
            "We are facing an issue while processing your request. Please try again later."
          );
        }
      },
    });
  };

  const getUserAvatar = () => {
    if (user?.userInfo?.image) {
      if (user?.userInfo?.image?.length === 1) {
        return require(`../../images/avatar${user?.userInfo?.image}.png`);
      } else {
        return user?.userInfo?.image;
      }
    } else {
      return require(`../../images/avatar${Math.floor(Math.random() * 5) + 1}.png`);
    }
  };
  return (
    <>
      <Card
        className={classes.card}
        sx={{
          borderRadius: "16px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          width: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                sx={{ height: "55px", width: "55px" }}
                src={getUserAvatar()}
              />
            }
            sx={{
              padding: 0,
              "& .MuiCardHeader-content": {
                flex: "none",
              },
              "& .MuiCardHeader-title": {
                fontSize: 18,
                fontWeight: 600,
              },
            }}
            title={`${user?.userInfo?.firstName} ${user?.userInfo?.lastName}`}
            // subheader={user?.userInfo?.bio}
          />
          <Box
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            gap={1}
            justifyContent={"center"}
          >
            <Button
              sx={{
                color: "white",
                backgroundColor: "#008080",
                padding: "5px 8px",
                fontSize: "12px",
                opacity: 0.9,
                ":hover": {
                  bgcolor: "#008080", // theme.palette.primary.main
                  color: "white",
                  opacity: 1,
                },
                "&.Mui-disabled": {
                  color: "white",
                  backgroundColor: "grey",
                },
              }}
              onClick={handleConnectButtonClick}
              disabled={buttonText === "Pending" || buttonText === "Connected"}
            >
              {createUserConnectionLoading || updateUserConnectionLoading ? (
                <CircularProgress
                  sx={{ color: "white" }}
                  style={{ width: 16, height: 16 }}
                />
              ) : (
                <Typography
                  component="span"
                  textTransform={"none"}
                  fontSize={12}
                >
                  {buttonText ?? "Connect"}
                </Typography>
              )}
            </Button>
            {buttonText === "Approve" && (
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "red",
                  padding: "5px 8px",
                  fontSize: "12px",
                  opacity: 0.9,
                  ":hover": {
                    bgcolor: "red", // theme.palette.primary.main
                    color: "white",
                    opacity: 1,
                  },
                }}
                onClick={handleRejectRequest}
              >
                {deleteUserConnectionLoading ? (
                  <CircularProgress
                    sx={{ color: "white" }}
                    style={{ width: 16, height: 16 }}
                  />
                ) : (
                  <Typography
                    component="span"
                    textTransform={"none"}
                    fontSize={12}
                  >
                    Reject
                  </Typography>
                )}
              </Button>
            )}
          </Box>
        </Box>
        {user?.userInfo?.bio && <Divider sx={{ marginY: 1 }} />}
        <Box display={"flex"} alignItems={"center"}>
          <Typography fontSize={14} style={{ wordBreak: "break-word" }}>
            {user?.userInfo?.bio}
          </Typography>
        </Box>
      </Card>
      {openSendConfirm && (
        <ConfirmDialog
          open={openSendConfirm}
          onClose={() => {
            setOpenSendConfirm(false);
          }}
          onConfirm={sendConnectionRequest}
          message={confirmMessage}
        />
      )}
      {openApproveConfirm && (
        <ConfirmDialog
          open={openApproveConfirm}
          onClose={() => {
            setOpenApproveConfirm(false);
          }}
          onConfirm={approveConnectionRequest}
          message={confirmMessage}
        />
      )}
      {openRejectConfirm && (
        <ConfirmDialog
          open={openRejectConfirm}
          onClose={() => {
            setOpenRejectConfirm(false);
          }}
          onConfirm={rejectConnectionRequest}
          message={confirmMessage}
        />
      )}
    </>
  );
};
