import { FC } from "react";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

interface CreatedEventsPopupProps {
  open: boolean;
  onClose: () => void;
  createdEventsData: [];
  isHost?: boolean;
}

export const CreatedEventsPopup: FC<CreatedEventsPopupProps> = ({
  open,
  onClose,
  createdEventsData,
  isHost = false,
}) => {
  const navigate = useNavigate();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{ textAlign: "center" }}>
        <Typography variant="h6" fontWeight="bold">
          Events created by {isHost ? "host" : "you"}
        </Typography>
        {createdEventsData && createdEventsData?.length > 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            alignItems="center"
            mt={2}
          >
            {createdEventsData.map((event: any, index) => (
              <Link
                key={index}
                to={`/event-detail?id=${event.id}`}
                style={{ color: "#008080" }}
              >
                <Typography>{event.title}</Typography>
              </Link>
            ))}
          </Box>
        ) : (
          <>
            {isHost ? (
              <Typography>Host hasn't created any event yet.</Typography>
            ) : (
              <Typography>
                You haven't created any event yet. Click on create button to
                create one.
              </Typography>
            )}
          </>
        )}
        <Box display={"flex"} flexDirection={"column"} gap={1} mt={2}>
          {!isHost && (
            <>
              {createdEventsData && createdEventsData?.length > 0 && (
                <Button
                  variant="contained"
                  onClick={() => navigate("/manage-events")}
                  sx={styled.button}
                >
                  <Typography fontSize={14} textTransform={"none"}>
                    Manage
                  </Typography>
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() => navigate("/create-event")}
                sx={styled.button}
              >
                <Typography fontSize={14} textTransform={"none"}>
                  Create
                </Typography>
              </Button>
            </>
          )}
          <Button variant="contained" onClick={onClose} sx={styled.button}>
            <Typography fontSize={14} textTransform={"none"}>
              Close
            </Typography>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const styled = {
  button: {
    alignContent: "center",
    backgroundColor: "#c05942",
    paddingX: 10,
    opacity: 0.9,
    ":hover": {
      bgcolor: "#c05942",
      opacity: 1,
    },
  },
};
