import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Admin } from "../Admin";
import BlockIcon from '@mui/icons-material/Block';

export const ProtectedRoute: FC<any> = ({ route }) => {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (!loading && !currentUser) {
      navigate(`/login?returnUrl=${route}`);
    } else if (currentUser?.isAdmin) {
      setIsAdmin(true);
    }
  }, [currentUser, navigate, route, loading]);

  return <Box>{isAdmin ? <Admin /> : <NotAuthorized />}</Box>;
};

const NotAuthorized = () => {
  const { logout } = useAuth();
  const logOut = async () => {
    await logout();
    localStorage.removeItem("accessToken");
    window.location.reload();
  };
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#008080",
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box>
        <BlockIcon style={{ color: '#c7c7c7', fontSize: 64 }} />
      </Box>
      <Box>
        <Typography color="white" fontWeight={'bold'} fontSize={24}>You're not authorized</Typography>
      </Box>
      <Box textAlign={'center'}>
        <Typography color="#c7c7c7">
          It seems like you don't have the permission to access this resource.
        </Typography>
        <Typography color="#c7c7c7">Please sign in with a different account.</Typography>
      </Box>
      <Box>
        <Typography
          color="#ff5000"
          fontSize={24}
          style={{ cursor: "pointer" }}
          fontWeight={'bold'}
          onClick={logOut}
        >
          Continue Logout
        </Typography>
      </Box>
    </Box>
  );
};
