import {
  Modal,
  Box,
  Typography,
  styled,
  alpha,
  InputBase,
  Chip,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "@apollo/client";
import { GET_COMMUNITIES } from "../../gql/communities";
import { GET_EVENTS } from "../../gql/events";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const SearchBar = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  // [theme.breakpoints.up("sm")]: {
  //   marginLeft: theme.spacing(0),
  //   width: "auto",
  // },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "10ch",
    //   [theme.breakpoints.up("sm")]: {
    //     width: "15ch",
    //   },
  },
}));

export const Search: FC<any> = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const [eventsCommunitiesData, setEventsCommunitiesData] = useState([]);
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventError,
  } = useQuery(GET_EVENTS);
  const {
    data: communitiesData,
    loading: communitiesLoading,
    error: communitiesError,
  } = useQuery(GET_COMMUNITIES);

  useEffect(() => {
    let eventsCommunitiesArr: any = [];
    eventsData?.getEvent?.map((event: any) => {
      const eventData = {
        name: event.title,
        type: "Event",
        route: `/event-detail?id=${event.id}`,
      };
      eventsCommunitiesArr.push(eventData);
    });
    communitiesData?.getCommunity?.map((community: any) => {
      const communityData = {
        name: community.name,
        type: "Community",
        route: `/community-detail?id=${community.id}`,
      };
      eventsCommunitiesArr.push(communityData);
    });
    setEventsCommunitiesData(eventsCommunitiesArr);
  }, [eventsData, communitiesData]);
  const filterData = (query: string) => {
    if (!query) {
      return eventsCommunitiesData;
    } else {
      return eventsCommunitiesData.filter((d: any) =>
        d.name.toLowerCase().includes(query.toLowerCase())
      );
    }
  };
  const [searchQuery, setSearchQuery] = useState("");
  const dataFiltered = filterData(searchQuery);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: { xs: "10%", sm: "20%" },
          left: { xs: "10%", sm: "20%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          width: { xs: "80%", sm: "60%" },
          height: { xs: "80%", sm: "60%" },
          overflow: "scroll",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#008080",
          }}
          p={2}
        >
          <Typography color={"white"}>Search Events or Communities</Typography>
          <CloseIcon style={{ color: "white", cursor: "pointer" }} onClick={handleClose} />
        </Box>
        <Box p={1}>
          <SearchBar sx={{ marginLeft: 0, border: "1px solid #ddd" }}>
            <SearchIconWrapper>
              <SearchIcon sx={{ color: "#008080", marginRight: 0, zIndex: 1000000 }} />
            </SearchIconWrapper>
            <StyledInputBase
              margin={"dense"}
              placeholder="Search..."
              inputProps={{ "aria-label": "search" }}
              sx={{
                color: "rgb(13, 18, 22)",
                backgroundColor: "#E7E9EB"
              }}
              fullWidth={true}
              value={searchQuery}
              onChange={(e: any) => setSearchQuery(e.target.value)}
            />
          </SearchBar>
          <Box style={{ padding: 3 }}>
            {dataFiltered && dataFiltered.length > 0 ? (
              dataFiltered?.map((data: any, index: number) => (
                <Box
                  style={{
                    borderBottom: "1px solid #ddd",
                  }}
                  key={index}
                  py={1}
                >
                  <Box
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                    gap={2}
                    onClick={() => {
                      handleClose();
                      navigate(data.route);
                    }}
                  >
                    <Typography color={"#008080"}>{data.name}</Typography>
                    <Chip
                      label={data.type}
                      color={data.type === 'Event' ? "error" : "warning"}
                    />
                  </Box>
                </Box>
              ))
            ) : (
              <Box textAlign={'center'} pt={5}>
                <Typography>No data found</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
