export const dialogHeaderStyles = {
    background: '#008080',
    textAlign: 'center',
    color: 'white',
};

export const dialogContentStyles = {
    textAlign: 'center',
    paddingX: '24px !important',
    paddingY: '24px !important',
};

export const dialogActionsStyles = {
    color: '#008080',
};