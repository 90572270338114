import { FC } from "react";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

interface CreatedCommunitiesPopupProps {
  open: boolean;
  onClose: () => void;
  createdCommunitiesData: [];
  isHost?: boolean;
}

export const CreatedCommunitiesPopup: FC<CreatedCommunitiesPopupProps> = ({
  open,
  onClose,
  createdCommunitiesData,
  isHost = false,
}) => {
  const navigate = useNavigate();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{ textAlign: "center" }}>
        <Typography variant="h6" fontWeight="bold">
          Communities created by {isHost ? "host" : "you"}
        </Typography>
        {createdCommunitiesData && createdCommunitiesData?.length > 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            alignItems="center"
            mt={2}
          >
            {createdCommunitiesData.map((community: any, index) => (
              <Link
                key={index}
                to={`/community-detail?id=${community.id}`}
                style={{ color: "#008080" }}
              >
                <Typography>{community.name}</Typography>
              </Link>
            ))}
          </Box>
        ) : (
          <>
            {isHost ? (
              <Typography>Host hasn't created any community yet.</Typography>
            ) : (
              <Typography>
                You haven't created any community yet. Click on create button to
                create one.
              </Typography>
            )}
          </>
        )}
        <Box display={"flex"} flexDirection={"column"} gap={1} mt={2}>
          {!isHost && (
            <>
              {createdCommunitiesData && createdCommunitiesData?.length > 0 && (
                <Button
                  variant="contained"
                  onClick={() => navigate("/manage-communities")}
                  sx={styled.button}
                >
                  <Typography fontSize={14} textTransform={"none"}>
                    Manage
                  </Typography>
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() => navigate("/create-community")}
                sx={styled.button}
              >
                <Typography fontSize={14} textTransform={"none"}>
                  Create
                </Typography>
              </Button>
            </>
          )}
          <Button variant="contained" onClick={onClose} sx={styled.button}>
            <Typography fontSize={14} textTransform={"none"}>
              Close
            </Typography>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const styled = {
  button: {
    alignContent: "center",
    backgroundColor: "#c05942",
    paddingX: 10,
    opacity: 0.9,
    ":hover": {
      bgcolor: "#c05942",
      opacity: 1,
    },
  },
};
