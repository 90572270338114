import { FC } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Box,
} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuth } from "../../contexts/AuthContext";

interface CommunityCardProps {
  community: any;
}

export const CommunityCard: FC<CommunityCardProps> = ({ community }) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const joinCommunity = (e: any) => {
    e?.stopPropagation();
    navigate(`/community-detail?id=${community?.id}`);
  };

  return (
    <Box>
      <Card style={{ borderRadius: "20px", cursor: 'pointer' }} onClick={(e: any) => joinCommunity(e)}>
        <Box position={"relative"}>
          <CardMedia
            component="img"
            alt="Community"
            height="180"
            image={community?.image}
            style={{ borderRadius: "14px", objectFit: "cover" }}
          />
        </Box>
        <CardContent style={{ paddingBottom: 0 }}>
          <Typography
            variant="h5"
            component="h5"
            fontSize={16}
            pb={1}
            fontWeight={600}
          >
            {community?.name}
          </Typography>
          <Box>
            <Box sx={styled.flex} justifyContent={"flex-start"}>
              <Box>
                <VisibilityIcon sx={styled.icon}></VisibilityIcon>
              </Box>
              <Box>
                <Typography color="#717171" fontSize={12}>
                  {community?.isPrivate ? "Private" : "Public"} Group
                </Typography>
              </Box>
            </Box>
            <Box sx={styled.flex} justifyContent={"flex-start"}>
              <Box>
                <AccountCircleIcon sx={styled.icon}></AccountCircleIcon>
              </Box>
              <Box>
                <Typography color="#717171" fontSize={12}>
                  Organized by<br />
                  {community?.createdBy === currentUser?.profileInfo?.id ? (
                    <Typography component='span' fontSize={14}>
                      <b>You</b>
                    </Typography>
                  ) : (
                    <Typography component='span' fontSize={14}
                    >
                      <b>
                        {community.organizedBy?.firstName}&nbsp;
                        {community.organizedBy?.lastName}
                      </b>
                    </Typography>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
        <hr
          style={{
            marginLeft: 25,
            marginRight: 25,
            borderColor: "#E1E1E1",
          }}
        />
        <CardActions
          sx={{ backgroundColor: "white", paddingX: "15px", paddingTop: 0 }}
        >
          <Box sx={styled.flex} justifyContent={"space-between"} width={"100%"}>
            <Box sx={styled.flex} justifyContent={"flex-start"}>
              <Box>
                <GroupsIcon sx={styled.icon}></GroupsIcon>
              </Box>
              <Box>
                <Typography color="#1B1B1B" fontSize={12}>
                  {community?.joinedMembers} Member(s) Joined
                </Typography>
              </Box>
            </Box>
            <Box>
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "#c05942",
                  padding: "5px 8px",
                  fontSize: "12px",
                  opacity: 0.9,
                  ":hover": {
                    bgcolor: "#c05942", // theme.palette.primary.main
                    color: "white",
                    opacity: 1,
                  },
                }}
                onClick={(e: any) => joinCommunity(e)}
              >
                <Typography
                  component="span"
                  textTransform={"none"}
                  fontSize={12}
                >
                  Follow
                </Typography>
              </Button>
            </Box>
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

const styled = {
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    color: "#c05942",
    height: "15px",
    marginTop: "5px",
  },
  button: {
    color: "white",
    backgroundColor: "#c05942",
    padding: "2px",
    fontSize: "12px",
  },
};
