import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CATEGORIES } from "../../gql/categories";
import { useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { UPDATE_USER_NAME_AND_BIO } from "../../gql/user";
import { UserAvatar } from "../user-avatar";
import CloseIcon from '@mui/icons-material/Close';

interface TagPopupProps {
  open: boolean;
  onClose: () => void;
  onSave: (selectedTags: string[], refresh?: boolean) => void;
  selectedTags: string[];
}

export const TagPopup: React.FC<TagPopupProps> = ({
  open,
  onClose,
  onSave,
  selectedTags,
}) => {
  const [selected, setSelected] = useState(selectedTags);
  const { currentUser } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [bio, setBio] = useState("");
  const [step, setStep] = useState(1);
  const userName = !currentUser?.profileInfo?.userInfo?.firstName?.includes("HobbyHopp");

  const handleToggleTag = (tag: string) => {
    const isSelected = selected?.includes(tag);

    if (isSelected) {
      setSelected(selected?.filter((s) => s !== tag));
    } else {
      setSelected([...selected, tag]);
    }
  };

  const { data: categoriesData } = useQuery(GET_CATEGORIES);
  const categories = categoriesData?.getCategories;

  const [tags, setTags] = useState<any>([]);

  useEffect(() => {
    setSelected(selectedTags);
    if (selectedTags && selectedTags.length > 0 && !userName) {
      setStep(2);
    }
  }, [selectedTags, userName]);

  useEffect(() => {
    const categoryTags = [];
    if (categories && categories.length > 0) {
      for (let i = 0; i < categories.length; i++) {
        categoryTags.push(categories[i].name);
      }
      setTags(categoryTags);
    }
  }, [categories]);
  const [updateUserNameAndBioMutation] = useMutation(UPDATE_USER_NAME_AND_BIO);

  const handleSave = () => {
    if (!userName) {
      updateUserNameAndBioMutation({
        variables: {
          updateUserNameInput: {
            id: currentUser?.profileInfo?.userInfo?.id,
            firstName: firstName,
            bio: bio,
          },
        },
        onCompleted: (data: any) => {
          if (data.updateUserNameAndBio) {
            console.log("User Info is updated successfully");
            onSave(selected, true);
            setStep(1);
            onClose();
          }
        },
        onError: (error: any) => {
          console.log(error);
          alert(
            "Error while updating user info. Please try again after sometime."
          );
        },
      });
    } else {
      onSave(selected);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setStep(1);
        onClose();
      }}
    >
      <DialogContent sx={{ textAlign: "center" }}>
        {step === 1 && (
          <Box>
            <Typography variant="h6" fontWeight="bold">
              Select hobby categories to connect with like-minded people near
              you
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              alignItems="center"
              mt={3}
            >
              {tags.map((tag: string, index: number) => (
                <Box key={index}>
                  <Chip
                    key={index}
                    label={tag}
                    onClick={() => handleToggleTag(tag)}
                    color={selected?.includes(tag) ? "primary" : "default"}
                    style={{ cursor: "pointer" }}
                    onDelete={
                      selected?.includes(tag)
                        ? () => handleToggleTag(tag)
                        : undefined
                    }
                  />
                </Box>
              ))}
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={1} mt={3}>
              {userName ? (
                <Button
                  variant="contained"
                  onClick={handleSave}
                  sx={styled.button}
                  disabled={selected?.length === 0}
                >
                  Save
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => setStep(2)}
                  sx={styled.button}
                  disabled={selected?.length === 0}
                >
                  Next
                </Button>
              )}
            </Box>
          </Box>
        )}
        {step === 2 && !userName && (
          <Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="h6" fontWeight="bold">
                  Update User Info
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" fontWeight="bold" mt="4px">
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setStep(1);
                      onClose();
                    }}
                  />
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={"4px"} mt={1}>
              <Box display={"flex"} justifyContent={"center"} mb={1}>
                <UserAvatar showName={false} />
              </Box>
              <Box sx={styled.inputBox}>
                {/* <Typography style={styled.label}>Name</Typography> */}
                <TextField
                  key="firstName"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  onBlur={(e) => {
                    setFirstName(e.target.value);
                  }}
                  name="firstName"
                  label="Enter your name*"
                  fullWidth
                  autoComplete="none"
                  type="text"
                  value={firstName}
                />
              </Box>
              <Box sx={styled.inputBox}>
                <TextField
                  key="bio"
                  onChange={(e) => {
                    setBio(e.target.value);
                  }}
                  onBlur={(e) => {
                    setBio(e.target.value);
                  }}
                  name="setBio"
                  label="Enter bio*"
                  fullWidth
                  autoComplete="none"
                  type="text"
                  value={bio}
                  inputProps={{ minLength: 10 }}
                  placeholder="Minimum 10 characters"
                  />
              </Box>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={1} mt={1}>
              <Button
                variant="contained"
                onClick={handleSave}
                sx={styled.button}
                disabled={!firstName || !bio || bio.length < 10}
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={() => setStep(1)}
                sx={styled.button}
              >
                Back
              </Button>
              {/* <Button
                variant="contained"
                onClick={() => {
                  setStep(1);
                  onClose();
                }}
                sx={styled.button}
              >
                Close
              </Button> */}
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

const styled = {
  button: {
    alignContent: "center",
    backgroundColor: "#008080",
    paddingX: 10,
    opacity: 0.9,
    ":hover": {
      bgcolor: "#008080",
      opacity: 1,
    },
  },
  inputBox: {
    marginBottom: 1,
    flex: "100%",
  },
  label: {
    fontSize: 12,
    marginBottom: 5,
    color: "rgba(0, 0, 0, 0.6)",
  },
};