import { useState } from "react";

const PostContactUsForm = async (
  values: any,
  successCallback: any,
  errorCallback: any
) => {
  // store contact information in DB
  // if successful
  if (true) return successCallback();
  else return errorCallback();
};

const initialFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  message: "",
  formSubmitted: false,
  success: false
};

export const useFormControls = () => {
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({} as any);

  const validate: any = (fieldValues = values) => {
    let temp: any = { ...errors };

    if ("firstName" in fieldValues)
      temp.firstName = fieldValues.firstName ? false : "First name is required.";

    // if ("lastName" in fieldValues)
    //   temp.lastName = fieldValues.lastName ? false : "Last name is required.";

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? false : "Email is required.";
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
    }

    if ("message" in fieldValues)
      temp.message =
        fieldValues.message.length !== 0 ? false : "Message is required.";

    setErrors({
      ...temp
    });
  };

  const handleInputValue = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
    validate({ [name]: value });
  };

  const handleSuccess = () => {
    setValues({
      ...initialFormValues,
      formSubmitted: true,
      success: true
    });
    return { isSubmitted: true, message: 'Thanks for contacting us. We will get back to you soon!'};
  };

  const handleError = () => {
    setValues({
      ...initialFormValues,
      formSubmitted: true,
      success: false
    });
    return { isSubmitted: false, message: 'We are facing some issue. Please try again after sometime.'}
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.firstName &&
      fieldValues.email &&
      fieldValues.message

    return isValid;
  };

  const handleFormSubmit = async (e: any): Promise<any> => {
    e.preventDefault();
    if (formIsValid()) {
      //const result = await PostContactUsForm(values, handleSuccess, handleError);
      return values;
    }
  };

  return {
    values,
    errors,
    handleInputValue,
    handleFormSubmit,
    formIsValid
  };
};
