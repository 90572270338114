import { Box, Typography } from "@mui/material";
import { EditEventForm } from "../../components/edit-event-form";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { GET_EVENT_BY_ID } from "../../gql/events";
import { Spinner } from "../../components/spinner";
import { useQuery } from "@apollo/client";

export const EditEvent = () => {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isValid, setIsValid] = useState(true);
  const [data, setData] = useState(null);
  const eventId = searchParams.get("id");
  if (!eventId) navigate("/");
  useEffect(() => {
    if (!loading && !currentUser) {
      navigate(`/login?returnUrl=/edit-event?id=${eventId}`);
    }
  }, [navigate, loading, eventId, currentUser]);

  const {
    data: eventData,
    loading: eventLoading,
  } = useQuery(GET_EVENT_BY_ID, {
    variables: {
      id: eventId,
    },
  });

  useEffect(() => {
    if(eventData && eventData.getEventById) {
      setData(eventData.getEventById);
      if (eventData.getEventById.createdBy !== currentUser?.profileInfo?.id) {
        setIsValid(false);
      }
    }
  }, [currentUser?.profileInfo?.id, eventData]);

  if (eventLoading) {
    return (
      <Box mt={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box style={{ backgroundColor: "#F5F5F5", paddingBottom: 64 }}>
      {data && isValid ? (
        <Box
          sx={{
            paddingX: { xs: 2, sm: 10, lg: 40 },
            paddingY: { xs: 5, sm: 10, lg: 10 },
            textAlign: "center",
          }}
        >
          <Box justifyContent={"center"} alignItems={"center"}>
            <Typography variant="h4" fontWeight={1000}>
              Edit Your Event
            </Typography>
            <Typography
              variant="h5"
              fontSize={16}
              color="#888491"
              marginTop={1}
            >
              Edit an event by updating the form below and get connected to
              millions of users.
            </Typography>
          </Box>
          <Box marginY={5} marginX={3}>
            <EditEventForm event={data}></EditEventForm>
          </Box>
        </Box>
      ) : (
        <>
        {!isValid ? (
        <Box textAlign={"center"} pt={5}>
          <Typography
            variant="h5"
            fontWeight={600}
            fontSize={20}
            color={"#008080"}
          >
            You're not authorized to edit this event.
          </Typography>
        </Box>) : (
        <Box textAlign={"center"} pt={5}>
          <Typography
            variant="h5"
            fontWeight={600}
            fontSize={20}
            color={"#008080"}
          >
            We didn't find the event you're trying to edit.
          </Typography>
        </Box>)}
        </>
      )}
    </Box>
  );
};
