import { Box, Typography } from "@mui/material";
import { CreateCommunityForm } from "../../components/create-community-form";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export const CreateCommunity = () => {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!loading && !currentUser) {
      navigate("/login?returnUrl=/create-community");
    } else if (!loading && !currentUser?.isHost) {
      navigate("/become-a-host?returnUrl=/create-community");
    }
  }, [currentUser, navigate, loading]);
  return (
    <Box style={{ backgroundColor: "#F5F5F5", paddingBottom: 64 }}>
      <Box
        sx={{
          paddingX: { xs: 2, sm: 10, lg: 40 },
          paddingY: { xs: 5, sm: 10, lg: 10 },
          textAlign: "center",
        }}
      >
        <Box justifyContent={"center"} alignItems={"center"}>
          <Typography variant="h4" fontWeight={1000}>
            Create Your Community
          </Typography>
          <Typography variant="h5" fontSize={16} color="#888491" marginTop={1}>
            Create a community by filling up the form below and get connected to
            milions of users.
          </Typography>
        </Box>
        <Box marginY={5} marginX={3}>
          <CreateCommunityForm></CreateCommunityForm>
        </Box>
      </Box>
    </Box>
  );
};
