import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import { FC, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Spinner } from "../../components/spinner";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useLazyQuery } from "@apollo/client";
import { GET_USERS } from "../../gql/user";
import { GET_ALL_EVENTS } from "../../gql/events";
import { GET_ALL_COMMUNITIES } from "../../gql/communities";
import { GET_CONTACT_US } from '../../gql/contact-us';

export const Admin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<any>(null);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState([]);
  const dashboardItems = ["Users", "Events", "Communities", "Contacts"];

  const [getUsersQuery] = useLazyQuery(GET_USERS);
  const [getEventsQuery] = useLazyQuery(GET_ALL_EVENTS);
  const [getCommunitiesQuery] = useLazyQuery(GET_ALL_COMMUNITIES);
  const [getAllContactUsQuery] = useLazyQuery(GET_CONTACT_US);


  const isExpired = (event: any) => {
    if (event.recurringDays) return false;
    let condition =
    new Date(
      `${event?.date?.toString()} ${event?.time?.toString()}`
    ).getTime() < new Date().getTime();
    return condition;
  };

  const expandItem = async (item: string, index: number) => {
    setLoading(true);
    if (currentIndex === index) {
      setLoading(false);
      setCurrentIndex(null);
    } else {
      setCurrentIndex(index);
      if (item === "Users") {
        const getUsersResult = await getUsersQuery();
        const result = getUsersResult?.data?.getUsers;
        setLoading(false);
        let rowData: any = [];
        result?.map((user: any, index: number) => {
          const rowObj = {
            id: user.id,
            phone: user.phone,
            email: user.email,
            name: (user.userInfo?.firstName || '') + ' ' + (user.userInfo?.lastName || ''),
            dob: user.userInfo?.dateOfBirth,
            role: user.userRole?.role.name,
            gender: user.userInfo?.gender,
            address: user.userInfo?.address,
          };
          rowData.push(rowObj);
        });
        setRows(rowData);
        const columns: GridColDef[] = [
          { field: "id", headerName: "Id", width: 300 },
          { field: "phone", headerName: "Phone", width: 150 },
          { field: "email", headerName: "Email", width: 300 },
          { field: "name", headerName: "Name", width: 300 },
          { field: "dob", headerName: "DOB", width: 150 },
          { field: "role", headerName: "Role", width: 150 },
          { field: "gender", headerName: "Gender", width: 150 },
          { field: "address", headerName: "Address", width: 400 },
        ];
        setColumns(columns);
      } else if (item === "Events") {
        const getEventsResult = await getEventsQuery();
        const result = getEventsResult?.data?.getAllEvent;
        setLoading(false);
        let rowData: any = [];
        result?.map((event: any, index: number) => {
          const rowObj = {
            id: event.id,
            title: event.title,
            status: event.cancelledBy ? 'Cancelled' : (isExpired(event) ? 'Expired' : 'Active'),
            createdBy: event.organizedBy?.firstName + ' ' + event.organizedBy?.lastName,
            createdDate: event.createdDate,
            eventDate: event.date,
            eventTime: event.time,
          };
          rowData.push(rowObj);
        });
        setRows(rowData);
        const columns: GridColDef[] = [
          { field: "id", headerName: "Id", width: 300 },
          { field: "title", headerName: "Title", width: 300 },
          { field: "status", headerName: "Status", width: 150 },
          { field: "createdBy", headerName: "Created By", width: 300 },
          { field: "createdDate", headerName: "Created Date", width: 150 },
          { field: "eventDate", headerName: "Event Date", width: 150 },
          { field: "eventTime", headerName: "Event Time", width: 150 },
          {
            field: "action",
            headerName: "Action",
            width: 120,
            renderCell: (data) => {
              return (
                <Button
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/event-detail?id=${data?.id}`)}
                >
                  <Typography
                    textTransform={"none"}
                    style={{ textDecoration: "underline" }}
                   >
                    Open
                  </Typography>
                </Button>
              );
            },
          },
        ];
        setColumns(columns);
      } else if (item === "Communities") {
        const getCommunitiesResult = await getCommunitiesQuery();
        const result = getCommunitiesResult?.data?.getAllCommunity;
        setLoading(false);
        let rowData: any = [];
        result?.map((community: any, index: number) => {
          const rowObj = {
            id: community.id,
            name: community.name,
            status: community.deletedBy ? 'Deleted' : 'Active',
            createdBy: community.organizedBy?.firstName + ' ' + community.organizedBy?.lastName,
            createdDate: community.createdDate,
          };
          rowData.push(rowObj);
        });
        setRows(rowData);
        const columns: GridColDef[] = [
          { field: "id", headerName: "Id", width: 300 },
          { field: "name", headerName: "Name", width: 300 },
          { field: "status", headerName: "Status", width: 150 },
          { field: "createdBy", headerName: "Created By", width: 300 },
          { field: "createdDate", headerName: "Created Date", width: 150 },
          {
            field: "action",
            headerName: "Action",
            width: 120,
            renderCell: (data) => {
              return (
                <Button
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/community-detail?id=${data?.id}`)}
                >
                  <Typography
                    textTransform={"none"}
                    style={{ textDecoration: "underline" }}
                   >
                    Open
                  </Typography>
                </Button>
              );
            },
          },
        ];
        setColumns(columns);
      } else if (item === "Contacts") {
        const getContactsResult = await getAllContactUsQuery();
        const result = getContactsResult?.data?.getContactUs;
        setLoading(false);
        let rowData: any = [];
        result?.map((contact: any, index: number) => {
          const rowObj = {
            id: contact.id,
            firstName: contact.firstName,
            lastName: contact.lastName,
            email: contact.email,
            phone: contact.phone,
            message: contact.message,
          };
          rowData.push(rowObj);
        });
        setRows(rowData);
        const columns: GridColDef[] = [
          { field: "id", headerName: "Id", width: 300 },
          { field: "firstName", headerName: "First Name", width: 300 },
          { field: "lastName", headerName: "Last Name", width: 300 },
          { field: "email", headerName: "Email", width: 150 },
          { field: "phone", headerName: "Phone", width: 300 },
          { field: "message", headerName: "Message", width: 150 },
        ];
        setColumns(columns);
      }
    }
  };
  return (
    <Box>
      <Box
        width={"100%"}
        style={{ backgroundColor: "#008080", display: "flex" }}
        py={2}
        px={2}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography component="span" color={"white"} fontSize={18}>
            Admin Dashboard
          </Typography>
        </Box>
        <Box>
          <Button
            sx={{
              color: "white",
              backgroundColor: "#c05942",
              padding: "5px 8px",
              fontSize: "12px",
              opacity: 0.9,
              ":hover": {
                bgcolor: "#c05942", // theme.palette.primary.main
                color: "white",
                opacity: 1,
              },
            }}
            onClick={(e: any) => navigate("/create-event?category=Marketplace")}
          >
            <Typography component="span" textTransform={"none"} fontSize={12}>
              Create Marketplace Event
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          paddingX: { xs: 2, sm: 10 },
          paddingY: 2,
        }}
      >
        <Box display={"flex"} flexDirection={"column"}>
          {dashboardItems.map((item: string, index: number) => {
            return (
              <List key={index}>
                <ListItemButton
                  onClick={() => expandItem(item, index)}
                  style={{
                    backgroundColor: "#c05942",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  <ListItemText primary={item} />
                  {currentIndex === index ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={currentIndex === index}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box>
                    {loading && (
                      <Box mt={4}>
                        <Spinner />
                      </Box>
                    )}
                    {rows && rows.length > 0 ? (
                      <div style={{ width: "100%" }}>
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 20 },
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                        />
                      </div>
                    ) : (
                      <>
                        {!loading && (
                          <Box textAlign={"center"} pt={5}>
                            <Typography
                              component="span"
                              color={"#777777"}
                              fontSize={14}
                              style={{
                                textDecoration: "underline",
                              }}
                            >
                              No data found.
                            </Typography>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                </Collapse>
              </List>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
