import { useState, useEffect, useContext, FC } from "react";
import { useFormControls } from "./controls";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { v4 as uuid } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../config/firebase";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { days, inputFieldValues } from "./data";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_EVENT } from "../../gql/events";
import {
  GET_COMMUNITY_BY_USER_ID,
  GET_COMMUNITY_JOINED_BY_USER_ID,
} from "../../gql/communities";
import { LocationContext } from "../../contexts/LocationContext";
import { Location } from "../location";
import { GET_CATEGORIES } from "../../gql/categories";

export const CreateEventForm: FC<any> = ({ isMarketplace }) => {
  const navigate = useNavigate();
  const marketplaceCategory = isMarketplace ? "Marketplace" : "";
  const { currentUser } = useAuth();
  const [processing, setProcessing] = useState(false);
  const [result, setResult] = useState({ isSubmitted: false, message: "" });
  const [img, setImg] = useState(null);
  const [eventName, setEventName] = useState(uuid());
  const { handleInputValue, handleFormSubmit, formIsValid, errors } =
    useFormControls();
  const [createEventMutation] = useMutation(CREATE_EVENT, {
    refetchQueries: ["GetEvents"],
  });
  const [selectedCommunity, setSelectedCommunity] = useState("");
  const [createdJoinedCommunities, setCreatedJoinedCommunities] = useState<any>(
    []
  );
  const { currentLocation, currentAddress } = useContext(LocationContext);
  const [eventLocation, setEventLocation] = useState(currentLocation);
  const [mapOpen, setMapOpen] = useState(false);
  const [recurringDays, setRecurringDays] = useState<string[]>([]);
  const [address, setAddress] = useState<string | undefined>(currentAddress);
  const [eventLink, setEventLink] = useState("");

  const { data: categoriesData } = useQuery(GET_CATEGORIES);

  const categories = categoriesData?.getCategories;
  if (isMarketplace && !categories.includes(marketplaceCategory))
    categories.push(marketplaceCategory);
  const [selectedCategory, setSelectedCategory] = useState(marketplaceCategory);

  useEffect(() => {
    if (categories && !marketplaceCategory) {
      setSelectedCategory(categories[0]?.name);
    }
  }, [categories, marketplaceCategory]);

  useEffect(() => {
    setAddress(currentAddress);
  }, [currentAddress]);

  const onLocationSelected = (coordinates: any, address?: string) => {
    setEventLocation(coordinates);
    setAddress(address);
  };

  const handleDayChange = (day: string) => {
    const daysArr = recurringDays;
    daysArr.push(day);
    setRecurringDays(daysArr);
  };

  const { data: joinedCommunitiesData } = useQuery(
    GET_COMMUNITY_JOINED_BY_USER_ID,
    {
      variables: {
        userId: currentUser?.profileInfo?.id,
      },
    }
  );

  const { data: createdCommunitiesData } = useQuery(GET_COMMUNITY_BY_USER_ID, {
    variables: {
      userId: currentUser?.profileInfo?.id,
    },
  });

  useEffect(() => {
    let createdJoinedCommunitiesArr: any = [
      { id: null, name: "Do not link any community" },
    ];
    joinedCommunitiesData?.getCommunityJoinedByUserId?.map((item: any) => {
      const community = {
        id: item?.community?.id,
        name: item?.community?.name,
      };
      createdJoinedCommunitiesArr.push(community);
    });

    createdCommunitiesData?.getCommunityByUserId?.map((communityItem: any) => {
      const community = {
        id: communityItem?.id,
        name: communityItem?.name,
      };
      createdJoinedCommunitiesArr.push(community);
    });
    setCreatedJoinedCommunities(createdJoinedCommunitiesArr);
    setSelectedCommunity(createdJoinedCommunitiesArr[0]?.name);
  }, [joinedCommunitiesData, createdCommunitiesData]);

  const handleSubmit: any = async (event: any) => {
    setProcessing(true);
    if (img) {
      const storageRef = ref(storage, `events/${eventName}`);

      const uploadTask = uploadBytesResumable(storageRef, img);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log("Uploading");
        },
        (error) => {
          alert(
            "Error while uploading event image. Please try again after sometime."
          );
          console.log(error);
          return false;
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            const values = await handleFormSubmit(event);
            const eventObj = {
              title: values.name,
              description: values.description,
              date:
                recurringDays && recurringDays.length > 0
                  ? null
                  : values.date
                  ? values.date
                  : new Date().toDateString(),
              time: values.time,
              venue: values.venue,
              address: address,
              image: downloadURL,
              capacity: parseInt(values.capacity),
              price: parseInt(values.price),
              createdBy: currentUser?.profileInfo?.id,
              category: selectedCategory,
              duration: parseInt(values.duration),
              communityId: createdJoinedCommunities.filter(
                (item: any) => item.name === selectedCommunity
              )[0]?.id,
              coordinates: JSON.stringify(eventLocation),
              recurringDays: recurringDays.toString(),
              eventLink: values.eventLink,
            };
            createEventMutation({
              variables: {
                eventInput: {
                  ...eventObj,
                },
              },
              onCompleted: (data: any) => {
                if (data.createEvent?.isSuccess) {
                  setResult({
                    isSubmitted: true,
                    message: "Thanks for creating an event.",
                  });
                  setProcessing(false);
                  navigate(`/event-detail?id=${data.createEvent.id}`);
                } else {
                  alert(data.createEvent?.error);
                }
              },
              onError: (error: any) => {
                console.log(error);
                setResult({
                  isSubmitted: false,
                  message:
                    "We are facing some issue. Please try again after sometime.",
                });
                setProcessing(false);
              },
            });
          });
        }
      );
    }
  };

  const handleMediaChange = (event: any) => {
    setImg(event.target.files[0]);
    const e = {
      target: {
        name: "image",
        value: event.target.files[0],
      },
    };
    handleInputValue(e);
  };

  return (
    <>
      {categories && (
        <Box style={{ backgroundColor: "white", borderRadius: 2 }}>
          {result && result.message && (
            <Typography
              color={result.isSubmitted ? "#008080" : "red"}
              margin={2}
            >
              {result.message}
            </Typography>
          )}
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={"5px"}
            paddingTop={"20px"}
            paddingLeft={"10px"}
          >
            <InfoIcon style={{ fontSize: 16, fontWeight: "bold" }}></InfoIcon>
            <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
              Provide detail of your event
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "15px",
              marginBottom: "15px",
              borderTop: "1px solid #E1E1E1;",
            }}
          ></Box>
          <form autoComplete="off">
            <Box
              display={"flex"}
              flexDirection={"row"}
              flexWrap={"wrap"}
              gap={"1%"}
              paddingX={"20px"}
            >
              {inputFieldValues.map((inputFieldValue, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      textAlign: "start",
                      flex: { xs: "100%", md: inputFieldValue.flex },
                    }}
                  >
                    <Box>
                      {!inputFieldValue.isMarketplace && (
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            marginBottom: 5,
                          }}
                        >
                          {inputFieldValue.label}
                        </Typography>
                      )}
                      {inputFieldValue.isMedia && (
                        <Box>
                          <Button
                            style={{
                              width: "100%",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            <TextField
                              key={index}
                              onChange={handleMediaChange}
                              name={inputFieldValue.name}
                              type="file"
                              style={{
                                width: "100%",
                                padding: 20,
                                borderColor: "#f5f5f5",
                              }}
                            />
                          </Button>
                        </Box>
                      )}
                      {inputFieldValue.isCategory && selectedCategory && (
                        <Select
                          value={selectedCategory}
                          onChange={(e) => {
                            setSelectedCategory(e.target.value);
                            handleInputValue(e);
                          }}
                          fullWidth
                          style={{ backgroundColor: "#f5f5f5" }}
                          name={inputFieldValue.name}
                        >
                          {categories?.map((category: any, index: number) => {
                            return (
                              <MenuItem key={index} value={category.name}>
                                {category.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                      {/* {inputFieldValue.isState && (
                    <Select
                      value={selectedState}
                      onChange={(e) => {
                        setSelectedState(e.target.value);
                        handleInputValue(e);
                      }}
                      fullWidth
                      style={{ backgroundColor: "#f5f5f5" }}
                      name={inputFieldValue.name}
                    >
                      {indianStates.map((state, index) => {
                        return (
                          <MenuItem key={index} value={state}>
                            {state}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )} */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {/* <DemoContainer components={['DatePicker', 'DatePicker']}> */}
                        {inputFieldValue.isDate && (
                          <DatePicker
                            onChange={(newValue: any) => {
                              const e = {
                                target: {
                                  name: inputFieldValue.name,
                                  value: newValue.$d.toDateString(),
                                },
                              };
                              handleInputValue(e);
                            }}
                            minDate={dayjs(new Date())}
                            name={inputFieldValue.name}
                            label={inputFieldValue.placeholder}
                            sx={{ backgroundColor: "#f5f5f5" }}
                          />
                        )}
                        {inputFieldValue.isTime && (
                          <TimePicker
                            onChange={(newValue: any) => {
                              let hour =
                                newValue.$H?.toString().length === 1
                                  ? `0${newValue.$H}`
                                  : newValue.$H;
                              let min =
                                newValue.$m?.toString().length === 1
                                  ? `0${newValue.$m}`
                                  : newValue.$m;
                              const e = {
                                target: {
                                  name: inputFieldValue.name,
                                  value: hour + ":" + min,
                                },
                              };
                              handleInputValue(e);
                            }}
                            ampm={false}
                            value={dayjs("2023-12-17T10:00")}
                            name={inputFieldValue.name}
                            label={inputFieldValue.placeholder}
                            sx={{ backgroundColor: "#f5f5f5" }}
                            ampmInClock={false}
                          />
                        )}
                        {/* </DemoContainer> */}
                      </LocalizationProvider>
                      {!inputFieldValue.isMedia &&
                        !inputFieldValue.isDate &&
                        !inputFieldValue.isTime &&
                        !inputFieldValue.isCategory &&
                        !inputFieldValue.isAddress &&
                        !inputFieldValue.isCommunity &&
                        !inputFieldValue.isRecurring &&
                        !inputFieldValue.isMarketplace && (
                          <TextField
                            key={index}
                            onChange={(e) => {
                              if (inputFieldValue.name === "name")
                                setEventName(e.target.value + "-" + uuid());
                              handleInputValue(e);
                            }}
                            onBlur={handleInputValue}
                            name={inputFieldValue.name}
                            label={inputFieldValue.placeholder}
                            error={errors[inputFieldValue.name]}
                            multiline={inputFieldValue.multiline ?? false}
                            fullWidth
                            rows={inputFieldValue.rows ?? 1}
                            autoComplete="none"
                            {...(errors[inputFieldValue.name] && {
                              error: true,
                              helperText: errors[inputFieldValue.name],
                            })}
                            style={{ backgroundColor: "#f5f5f5" }}
                            // value={inputFieldValue.value}
                            // disabled={inputFieldValue.disabled}
                            type={inputFieldValue.type}
                          />
                        )}
                      {inputFieldValue.isAddress && (
                        <TextField
                          key={index}
                          onClick={() => setMapOpen(true)}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                          onBlur={handleInputValue}
                          name={inputFieldValue.name}
                          label={address ? "" : inputFieldValue.placeholder}
                          error={errors[inputFieldValue.name]}
                          multiline={inputFieldValue.multiline ?? false}
                          fullWidth
                          rows={inputFieldValue.rows ?? 1}
                          autoComplete="none"
                          {...(errors[inputFieldValue.name] && {
                            error: true,
                            helperText: errors[inputFieldValue.name],
                          })}
                          style={{ backgroundColor: "#f5f5f5" }}
                          type={inputFieldValue.type}
                          value={address}
                        />
                      )}
                      {inputFieldValue.isCommunity && (
                        <Select
                          value={selectedCommunity}
                          onChange={(e) => {
                            setSelectedCommunity(e.target.value);
                            handleInputValue(e);
                          }}
                          fullWidth
                          style={{ backgroundColor: "#f5f5f5" }}
                          name={inputFieldValue.name}
                        >
                          {createdJoinedCommunities?.map(
                            (community: any, index: number) => {
                              return (
                                <MenuItem key={index} value={community.name}>
                                  {community.name}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      )}
                      {isMarketplace && inputFieldValue.isMarketplace && (
                        <>
                          <Typography
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              marginBottom: 5,
                            }}
                          >
                            {inputFieldValue.label}
                          </Typography>
                          <TextField
                            key={index}
                            onChange={(e) => {
                              setEventLink(e.target.value);
                            }}
                            onBlur={handleInputValue}
                            name={inputFieldValue.name}
                            label={eventLink ? "" : inputFieldValue.placeholder}
                            error={errors[inputFieldValue.name]}
                            multiline={inputFieldValue.multiline ?? false}
                            fullWidth
                            rows={inputFieldValue.rows ?? 1}
                            autoComplete="none"
                            {...(errors[inputFieldValue.name] && {
                              error: true,
                              helperText: errors[inputFieldValue.name],
                            })}
                            style={{ backgroundColor: "#f5f5f5" }}
                            type={inputFieldValue.type}
                            value={eventLink}
                          />
                          <Box
                            sx={{
                              marginTop: "20px",
                              marginBottom: "15px",
                              borderTop: "1px solid #E1E1E1;",
                            }}
                          ></Box>
                        </>
                      )}
                      {inputFieldValue.isRecurring && (
                        <>
                          {days.map((day: string) => {
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => handleDayChange(day)}
                                  />
                                }
                                label={day}
                              />
                            );
                          })}
                        </>
                      )}
                    </Box>
                    {index !== inputFieldValues.length &&
                      !inputFieldValue.isMarketplace && (
                        <Box
                          sx={{
                            marginTop: "20px",
                            marginBottom: "15px",
                            borderTop: "1px solid #E1E1E1;",
                          }}
                        ></Box>
                      )}
                  </Box>
                );
              })}
            </Box>
            <Typography
              fontSize={14}
              textTransform={"none"}
              textAlign="right"
              mr={2}
              color="#008080"
            >
              *If it is not a recurring event then provide event date.
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              padding={"20px"}
              gap={1}
            >
              {/* <Button
            variant="contained"
            style={{
              backgroundColor: "#008080",
              color: "white",
              padding: "10px 15px",
            }}
            onClick={() => setMapOpen(true)}
          >
            <Typography fontSize={14} textTransform={"none"}>
              Select Location
            </Typography>
          </Button> */}
              <Button
                variant="contained"
                disabled={
                  !formIsValid() || (isMarketplace ? eventLink === "" : false)
                }
                sx={{
                  backgroundColor: "#008080",
                  color: "white",
                  padding: "10px 15px",
                }}
                onClick={handleSubmit}
              >
                {!processing ? (
                  <Typography fontSize={14} textTransform={"none"}>
                    Create Event
                  </Typography>
                ) : (
                  <Typography fontSize={14} textTransform={"none"}>
                    Creating...
                  </Typography>
                )}
              </Button>
            </Box>
          </form>
          {mapOpen && (
            <Location
              open={mapOpen}
              handleClose={() => setMapOpen(false)}
              onLocationSelected={onLocationSelected}
              location={eventLocation}
              currentAddress={address}
            />
          )}
        </Box>
      )}
    </>
  );
};
