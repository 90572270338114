import { FC, useState } from "react";
import { Box, Avatar, Typography } from "@mui/material";
import { UserInfoPopup } from "../user-info-popup";

interface NearbyPeopleTileProps {
  item: any;
  buttonText?: string;
  id?: string
  removeCreatedConnection?: (item: any) => void;
  openChat?: boolean;
  showName?: boolean;
  contactInfo?: string;
}

export const NearbyPeopleTile: FC<NearbyPeopleTileProps> = ({ item, buttonText, id, removeCreatedConnection, openChat, showName = true, contactInfo }) => {
  const [openUserInfoPopup, setOpenUserInfoPopup] = useState(false);

  const tileAction = () => {
    if (openChat) {
      alert('Chat is coming soon!');
    } else {
      setOpenUserInfoPopup(true);
    }
  };

  const getUserAvatar = () => {
    if (item?.userInfo?.image) {
      if (item?.userInfo?.image?.length === 1) {
        return require(`../../images/avatar${item?.userInfo?.image}.png`);
      } else {
        return item?.userInfo?.image;
      }
    } else {
      return require(`../../images/avatar${Math.floor(Math.random() * 5) + 1}.png`);
    }
  };

  return (
    <Box>
      <Box justifyContent={"center"} display={"flex"}>
        <Avatar
          src={getUserAvatar()}
          sx={[
            styled.avatar,
            {
              "& .MuiAvatar-img": {
                objectFit: "contain",
                width: 80,
                height: 80,
              },
            },
          ]}
          onClick={tileAction}
        />
      </Box>
      {showName && (<Box textAlign={"center"}>
        <Typography
          fontSize={16}
          color={"#008080"}
          style={{ overflowWrap: "anywhere", cursor: 'pointer', }}
          onClick={tileAction}
        >
          {item?.userInfo?.firstName}
          &nbsp;
          {item?.userInfo?.lastName}
        </Typography>
      </Box>)}
      {openUserInfoPopup && (
        <UserInfoPopup
          user={item}
          open={openUserInfoPopup}
          onClose={(connectionCreated?: boolean) => {
            if (connectionCreated && removeCreatedConnection) {
              removeCreatedConnection(item);
            }
            setOpenUserInfoPopup(false);
          }}
          buttonText={buttonText}
          id={id}
          contactInfo={contactInfo}
        />
      )}
    </Box>
  );
};

const styled = {
  avatar: {
    width: 80,
    height: 80,
    cursor: "pointer",
  },
};
