import { Box, Typography } from "@mui/material";
import { CreateEventForm } from "../../components/create-event-form";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export const CreateEvent = () => {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get("category");
  const isMarketplace = category ? category.toLowerCase() === 'marketplace' : false;
  useEffect(() => {
    if (!loading && !currentUser) {
      navigate("/login?returnUrl=/create-event");
    } else 
    if (!loading && !currentUser?.isHost) {
      navigate("/become-a-host?returnUrl=/create-event");
    }
    if (!loading && isMarketplace && !currentUser?.isAdmin) {
      navigate("/create-event");
    }
  }, [currentUser, navigate, loading, isMarketplace]);
  return (
    <Box style={{ backgroundColor: "#F5F5F5", paddingBottom: 64 }}>
      <Box
        sx={{
          paddingX: { xs: 2, sm: 10, lg: 40 },
          paddingY: { xs: 5, sm: 10, lg: 10 },
          textAlign: "center",
        }}
      >
        <Box justifyContent={"center"} alignItems={"center"}>
          <Typography variant="h4" fontWeight={1000}>
            Create Your Event
          </Typography>
          <Typography variant="h5" fontSize={16} color="#888491" marginTop={1}>
            Create an event by filling up the form below and get connected to
            millions of users.
          </Typography>
        </Box>
        <Box marginY={5} marginX={3}>
          <CreateEventForm isMarketplace={isMarketplace}></CreateEventForm>
        </Box>
      </Box>
    </Box>
  );
};
