import { Box, Card, CardContent, Typography, Avatar, CircularProgress, Button } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import {
  DELETE_USER_EVENT_FAVOURITE,
  GET_USER_EVENT_FAVOURITE_BY_USER_ID,
} from "../../gql/events";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "../spinner";
import { useState } from "react";

export const FavouriteEvents = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<any>(null);
  const {
    data: userEventFavouriteData,
    loading: userEventFavourite,
    error,
  } = useQuery(GET_USER_EVENT_FAVOURITE_BY_USER_ID, {
    variables: {
      userId: currentUser?.profileInfo?.id,
    },
  });

  const [deleteUserEventFavouriteMutation] = useMutation(
    DELETE_USER_EVENT_FAVOURITE,
    {
      refetchQueries: ["GetUserEventFavouriteByUserId"],
    }
  );

  const removeFav = (id: string, index: number) => {
    setCurrentIndex(index);
    setLoading(true);
    const eventFavouriteObj = {
      eventId: id,
      userId: currentUser?.profileInfo?.id,
    };
    deleteUserEventFavouriteMutation({
      variables: {
        userEventFavouriteInput: {
          ...eventFavouriteObj,
        },
      },
      onCompleted: (data: any) => {
        if (data?.deleteUserEventFavourite?.isSuccess) {
          console.log("Removed from favourite list");
          setLoading(false);
          setCurrentIndex(null);
        }
      },
      onError: (error: any) => {
        console.log(error);
        setLoading(false);
        setCurrentIndex(null);
        alert(
          "Error while processing this request, please try again after sometime."
        );
      },
    });
  };

  if (userEventFavourite) {
    return (
      <Box mt={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box>
      {userEventFavouriteData &&
      userEventFavouriteData?.getUserEventFavouriteByUserId?.length > 0 ? (
        <Box>
          {userEventFavouriteData?.getUserEventFavouriteByUserId.map(
            (item: any, index: number) => {
              return (
                <Box padding="16px" alignItems="center" key={index} style={{ cursor: 'pointer' }} onClick={() =>
                  navigate(`/event-detail?id=${item?.event?.id}`)
                }>
                  <Card
                    sx={{
                      borderRadius: "16px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      alt="Event Image"
                      src={item?.event?.image}
                      sx={{ width: "92px", height: "92px", margin: "10px" }}
                      variant="rounded"
                    />
                    <CardContent
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "16px 16px 16px 0",
                          width: "100%",
                        }}>
                      <Box>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "12px",
                          color: "#717171",
                        }}
                      >
                        {item?.event.date}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "16px",
                        }}
                      >
                        {item?.event?.title}
                      </Typography>
                      {/* <Typography
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              color: "#008080",
                            }}
                          >
                            {item?.user?.userInfo?.firstName}&nbsp;
                            {item?.user?.userInfo?.lastName}
                          </Typography> */}
                      </Box>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                            {loading && index === currentIndex ? (
                              <CircularProgress sx={{ color: "#c05942" }} style={{ width: 15, height: 15 }} />
                            ) : (
                              <Button
                                variant="outlined"
                                sx={{
                                  color: "#c05942",
                                  backgroundColor: "white",
                                  padding: "2px",
                                  fontSize: "12px",
                                  opacity: 0.9,
                                  border: "1px solid #c05942",
                                  zIndex: "1000",
                                  ":hover": {
                                    bgcolor: "white", // theme.palette.primary.main
                                    color: "#c05942",
                                    opacity: 1,
                                    border: "1px solid #c05942",
                                  },
                                }}
                                onClick={(event: any) => {
                                  removeFav(item?.event?.id, index);
                                  event.stopPropagation();
                                }}
                              >
                                  <Typography textTransform={"none"} fontSize={12}>
                                    Remove
                                  </Typography>
                              </Button>
                            )}
                        </Box>
                    </CardContent>
                  </Card>
                </Box>
              );
            }
          )}
        </Box>
      ) : (
        <Box mt={1}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "16px",
            }}
          >
            You don't have any favourite event yet.&nbsp;
            <Link to="/events">Click</Link> to explore events.
          </Typography>
        </Box>
      )}
    </Box>
  );
};
