import { gql } from "@apollo/client";

export const GET_ALL_EVENTS = gql`
    query GetAllEvents {
        getAllEvent {
            id
            title
            description
            address
            venue
            coordinates
            date
            time
            image
            capacity
            price
            category
            duration
            cancelledBy
            createdDate
            organizedBy {
                id
                firstName
                lastName
            }
            community {
                id
                name
                image
            }
            remainingCapacity
            recurringDays
            eventLink
        }
    }
`;

export const GET_EVENTS = gql`
    query GetEvents {
        getEvent {
            id
            title
            description
            address
            venue
            coordinates
            date
            time
            image
            capacity
            price
            category
            duration
            cancelledBy
            organizedBy {
                id
                firstName
                lastName
            }
            community {
                id
                name
                image
            }
            remainingCapacity
            recurringDays
            eventLink
        }
    }
`;

export const GET_EVENT_BY_ID = gql`
    query GetEventById($id: ID) {
        getEventById(id: $id) {
            id
            title
            description
            address
            coordinates
            date
            time
            image
            capacity
            price
            category
            duration
            createdBy
            createdDate
            cancelledBy
            venue
            organizedBy {
                id
                firstName
                lastName
            }
            community {
                id
                name
                image
            }
            remainingCapacity
            recurringDays
            eventLink
        }
    }
`;

export const GET_EVENT_BY_USER_ID = gql`
    query GetEventByUserId($userId: ID) {
        getEventByUserId(userId: $userId) {
            id
            title
            description
            address
            coordinates
            date
            time
            image
            capacity
            price
            category
            duration
            organizedBy {
                id
                firstName
                lastName
            }
            community {
                id
                name
                image
            }
            remainingCapacity
            cancelledBy
            cancelledDate
            recurringDays
            eventLink
        }
    }
`;

export const CREATE_EVENT = gql`
    mutation CreateEvent($eventInput: EventInput) {
        createEvent(eventInput: $eventInput) {
            id
            isSuccess
            error
        }
    }
`;

export const UPDATE_EVENT = gql`
    mutation UpdateEvent($eventInput: EventInput!) {
        updateEvent(eventInput: $eventInput) {
            id
            isSuccess
            error
        }
    }
`;

export const CREATE_EVENT_BOOKING = gql`
    mutation CreateEventBooking($eventBookingInput: EventBookingInput) {
        createEventBooking(eventBookingInput: $eventBookingInput) {
            id
            isSuccess
            error
        }
    }
`;

export const GET_EVENT_BOOKING_BY_ID = gql`
    query GetEventBookingById($id: ID) {
        getEventBookingById(id: $id) {
            id
            bookedDate
            numberOfTickets
            cancelledBy
            cancelledDate
            rejectedBy
            rejectedDate
            approvedBy
            approvedDate
            eventDate
            event {
                id
                title
                image
                date     
                cancelledBy
                cancelledDate
                recurringDays
                eventLink
            }
            user {
                id
                email
                phone
                userInfo {
                firstName
                lastName
            }
            }
        }
    }
`;

export const GET_EVENT_BOOKING_BY_USER_ID = gql`
    query GetEventBookingByUserId($userId: ID) {
        getEventBookingByUserId(userId: $userId) {
            id
            bookedDate
            numberOfTickets
            cancelledBy
            cancelledDate
            rejectedBy
            rejectedDate
            approvedBy
            approvedDate
            eventDate
            event {
                id
                title
                image
                date
                recurringDays
                cancelledBy
                cancellationReason
                eventLink
                category
                organizedBy {
                id
                firstName
                lastName
            }
            }
            user {
                id
                email
                phone
                userInfo {
                firstName
                lastName
            }
            }
        }
    }
`;

export const GET_USER_EVENT_FAVOURITE_BY_USER_ID = gql`
    query GetUserEventFavouriteByUserId($userId: ID) {
        getUserEventFavouriteByUserId(userId: $userId) {
            id
            userId
            eventId
            event {
                id
                title
                image
                date
                recurringDays
                eventLink
            }
            user {
                id
                email
                phone
                userInfo {
                firstName
                lastName
            }
            }
        }
    }
`;

export const CREATE_USER_EVENT_FAVOURITE = gql`
    mutation CreateUserEventFavourite($userEventFavouriteInput: UserEventFavouriteInput) {
        createUserEventFavourite(userEventFavouriteInput: $userEventFavouriteInput) {
            id
            isSuccess
            error
        }
    }
`;

export const DELETE_USER_EVENT_FAVOURITE = gql`
    mutation DeleteUserEventFavourite($userEventFavouriteInput: UserEventFavouriteInput) {
        deleteUserEventFavourite(userEventFavouriteInput: $userEventFavouriteInput) {
            id
            isSuccess
            error
        }
    }
`;

export const APPROVE_EVENT_BOOKING = gql`
    mutation ApproveEventBooking($updateBookingStatusInput: UpdateBookingStatusInput) {
        approveEventBooking(updateBookingStatusInput: $updateBookingStatusInput) {
            id
            isSuccess
            error
        }
    }
`;

export const REJECT_EVENT_BOOKING = gql`
    mutation RejectEventBooking($updateBookingStatusInput: UpdateBookingStatusInput) {
        rejectEventBooking(updateBookingStatusInput: $updateBookingStatusInput) {
            id
            isSuccess
            error
        }
    }
`;

export const CANCEL_EVENT_BOOKING = gql`
    mutation CancelEventBooking($updateBookingStatusInput: UpdateBookingStatusInput) {
        cancelEventBooking(updateBookingStatusInput: $updateBookingStatusInput) {
            id
            isSuccess
            error
        }
    }
`;

export const CANCEL_EVENT = gql`
    mutation CancelEvent($cancelEventInput: CancelEventInput) {
        cancelEvent(cancelEventInput: $cancelEventInput) {
            id
            isSuccess
            error
        }
    }
`;

export const DELETE_EVENT = gql`
    mutation DeleteEvent($id: ID!) {
        deleteEvent(id: $id) {
            id
            isSuccess
            error
        }
    }
`;

export const GET_NEARBY_EVENTS = gql`
    query GetNearbyEvents($nearbyEventsInput: NearbyEventsInput!) {
        getNearbyEvents(nearbyEventsInput: $nearbyEventsInput) {
            id
            title
            description
            address
            venue
            coordinates
            date
            time
            image
            capacity
            price
            category
            duration
            cancelledBy
            createdDate
            organizedBy {
                id
                firstName
                lastName
            }
            community {
                id
                name
                image
            }
            remainingCapacity
            recurringDays
            eventLink
            distance
        }
    }
`;