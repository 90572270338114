import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import {
  CANCEL_EVENT,
  GET_EVENT_BOOKING_BY_ID,
  GET_EVENT_BY_USER_ID,
  DELETE_EVENT,
} from "../../gql/events";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Spinner } from "../../components/spinner";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { BookingAction } from "../../components/booking-action";
import { ConfirmDialog } from "../../components/confirm-dialog";

export const ManageEvents = () => {
  let navigate = useNavigate();
  const { currentUser, loading } = useAuth();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [eventBookingData, setEventBookingData] = useState([]);
  const [getEventBookingByIdQuery] = useLazyQuery(GET_EVENT_BOOKING_BY_ID);
  const [mLoading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentBooking, setCurrentBooking] = useState<any>(null);
  const [currentEvent, setCurrentEvent] = useState<any>(null);
  const [openCancelConfirm, setOpenCancelConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [deleteMessage, setDeleteMessage] = useState("");
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [cancelEventMutation] = useMutation(CANCEL_EVENT, {
    refetchQueries: ["GetEventByUserId", "GetEvents"],
  });
  const [deleteEventMutation] = useMutation(DELETE_EVENT, {
    refetchQueries: ["GetEventByUserId", "GetEvents"],
  });

  const [events, setEvents] = useState([]);
  const [validEvents, setValidEvents] = useState([]);
  const [expiredEvents, setExpiredEvents] = useState([]);

  const [checked, setChecked] = useState(false);

  const getBookedBy = (booking: any) => {
    let bookedByUser = booking?.user?.email
    ? booking?.user?.email
    : booking?.user?.phoneNumber;
  if (booking?.user?.userInfo?.firstName) {
    const userInfo = booking?.user?.userInfo;
    bookedByUser = userInfo?.firstName + " " + userInfo?.lastName;
  }
  return bookedByUser;
  }

  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setEvents([...validEvents, ...expiredEvents]);
    } else {
      setEvents([...validEvents]);
    }
  };


  useEffect(() => {
    if (!loading && !currentUser) navigate("/login?returnUrl=/manage-events");
  }, [loading, currentUser, navigate]);

  const { data: createdEventsData, loading: createdEventsLoading } = useQuery(
    GET_EVENT_BY_USER_ID,
    {
      variables: {
        userId: currentUser?.profileInfo?.id,
      },
    }
  );

  const isExpired = (event: any) => {
    if (event.recurringDays) return false;
    let condition =
    new Date(
      `${event?.date?.toString()} ${event?.time?.toString()}`
    ).getTime() < new Date().getTime();
    return condition;
  };

  useEffect(() => {
    if (createdEventsData && createdEventsData?.getEventByUserId) {
      const valEvents: any = [];
      const expEvents: any = [];
    createdEventsData?.getEventByUserId.map((event: any) => {
      if (isExpired(event)) {
        expEvents.push(event);
      } else {
        valEvents.push(event);
      }
    });
    setValidEvents(valEvents);
    setExpiredEvents(expEvents);
    setEvents(valEvents);
  }
  }, [createdEventsData])

  const expandEventBookings = async (event: any, index: any) => {
    setLoading(true);
    setCurrentEvent(event);
    if (currentIndex === index) {
      setLoading(false);
      setCurrentIndex(null);
    } else {
      setCurrentIndex(index);
      const getEventBookingByIdResult = await getEventBookingByIdQuery({
        variables: {
          id: event?.id,
        },
      });
      const result = getEventBookingByIdResult?.data?.getEventBookingById;
      setLoading(false);
      console.log(result);
      setEventBookingData(result);
      let rowData: any = [];
      result?.map((eventBooking: any, index: number) => {
        const rowObj = {
          id: eventBooking.id,
          eventDate: eventBooking.eventDate || 'NA',
          bookingId: eventBooking.id,
          status: isExpired(eventBooking?.event)
            ? "Expired"
            : eventBooking?.cancelledBy
            ? "Cancelled"
            : eventBooking?.rejectedBy
            ? "Rejected"
            : eventBooking?.approvedBy
            ? "Approved"
            : "Pending",
          bookedOn: eventBooking?.bookedDate,
          tickets: eventBooking?.numberOfTickets,
        };
        rowData.push(rowObj);
      });
      setRows(rowData);
    }
  };

  const cancelEvent = (event: any) => {
    setSelectedEventId(event?.id);
    setConfirmMessage("Are you sure you want to cancel this event?");
    setOpenCancelConfirm(true);
  };

  const onCancelConfirm = (reason?: string) => {
    setOpenCancelConfirm(false);
    setLoading(true);
    const cancelEventObj = {
      eventId: selectedEventId,
      updatedBy: currentUser?.profileInfo?.id,
      cancellationReason: reason,
    };
    cancelEventMutation({
      variables: {
        cancelEventInput: {
          ...cancelEventObj,
        },
      },
      onCompleted: (data: any) => {
        if (data?.cancelEvent?.isSuccess) {
          console.log("Cancelled Event");
          setLoading(false);
          setOpenCancelConfirm(false);
        }
      },
      onError: (error: any) => {
        console.log(error);
        setLoading(false);
        alert(
          "Error while processing this request, please try again after sometime."
        );
      },
    });
  };

  const deleteEvent = (event: any) => {
    setSelectedEvent(null);
    setSelectedEventId(event?.id);
    setDeleteMessage("Are you sure you want to delete this event?");
    if (event?.capacity - event?.remainingCapacity > 0) {
      setSelectedEvent(event);
      setDeleteMessage("You cannot delete this event as it has active bookings. Although, you can cancel it.");
    }
    setOpenDeleteConfirm(true);
  };

  const onDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
    if (selectedEvent?.capacity - selectedEvent?.remainingCapacity > 0) return;
    setLoading(true);
    deleteEventMutation({
      variables: {
        id: selectedEventId,
      },
      onCompleted: (data: any) => {
        if (data?.deleteEvent?.isSuccess) {
          console.log("Event Deleted");
          setLoading(false);
          setOpenDeleteConfirm(false);
        }
      },
      onError: (error: any) => {
        console.log(error);
        setLoading(false);
        alert(
          "Error while processing this request, please try again after sometime."
        );
      },
    });
  };
  const columns: GridColDef[] = [
    { field: "bookingId", headerName: "Id", width: 300 },
    { field: "eventDate", headerName: "Event Date", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "bookedBy",
      headerName: "Booked By",
      width: 200,
      renderCell: (data) => {
        const booking: any = eventBookingData?.filter(
          (item: any) => item.id === data?.id
        )[0];
        // const bookedBy = booking?.user?.phone
        // ? booking?.user?.phone
        // : booking?.user?.email
        return (
          <Button
            onClick={() => {
              navigate(`/host-profile?id=${booking?.user?.id}`);
            }}
          >
            <Typography
              textTransform={"none"}
              style={{ textDecoration: "underline" }}
            >
              {getBookedBy(booking)}
            </Typography>
          </Button>
        );
      },
    },
    { field: "bookedOn", headerName: "Booked On", width: 150 },
    { field: "tickets", headerName: "Tickets", width: 120 },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (data) => {
        return (
          <Button
            onClick={() => {
              const booking = eventBookingData?.filter(
                (item: any) => item.id === data?.id
              )[0];
              setCurrentBooking(booking);
              setOpen(true);
            }}
          >
            <Typography
              textTransform={"none"}
              style={{ textDecoration: "underline" }}
            >
              Action
            </Typography>
          </Button>
        );
      },
    },
  ];

  if (createdEventsLoading) {
    return (
      <Box mt={4}>
        <Spinner />
      </Box>
    );
  }
  return (
    <>
      <Box>
        <Box
          width={"100%"}
          style={{ backgroundColor: "#008080", display: "flex" }}
          py={2}
          pl={2}
        >
          <KeyboardArrowLeftIcon
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <Typography component="span" color={"white"} fontSize={18}>
            Manage Events
          </Typography>
        </Box>
        <Box
          sx={{
            paddingX: { xs: 2, sm: 10 },
            paddingY: 2,
          }}
        >
          <Box textAlign={'end'}>
            <FormControlLabel
              control={
                <Checkbox checked={checked} onChange={handleCheckedChange} />
              }
              label="Show expired events"
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            {events &&
              events.map((item: any, index: number) => {
                return (
                  <List key={index}>
                    <ListItemButton
                      onClick={() => expandEventBookings(item, index)}
                      style={{
                        backgroundColor: !isExpired(item)
                          ? "#c05942"
                          : "#d9d9d9",
                        color: "white",
                      }}
                    >
                      <ListItemText primary={item?.title} />
                      {currentIndex === index ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                      in={currentIndex === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box>
                        <Box
                          m={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={1}
                          justifyContent={"space-between"}
                        >
                          <Box>
                            <Typography
                              component="span"
                              color={"#008080"}
                              fontSize={14}
                            >
                              Date & Time:&nbsp;
                            </Typography>

                            <Typography
                              component="span"
                              color={"#777777"}
                              fontSize={14}
                            >
                              {item?.date}&nbsp;{item?.time}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component="span"
                              color={"#008080"}
                              fontSize={14}
                            >
                              Status:&nbsp;
                            </Typography>

                            <Typography
                              component="span"
                              color={"#777777"}
                              fontSize={14}
                            >
                              {isExpired(item)
                                ? "Expired"
                                : item?.cancelledBy
                                ? "Cancelled"
                                : "Active"}
                            </Typography>
                          </Box>
                          {item.recurringDays && (
                            <Box>
                              <Typography
                                component="span"
                                color={"#008080"}
                                fontSize={14}
                              >
                                Recurring Days:&nbsp;
                              </Typography>

                              <Typography
                                component="span"
                                color={"#777777"}
                                fontSize={14}
                              >
                                {item.recurringDays}
                              </Typography>
                            </Box>
                          )}
                          <Box>
                            <Typography
                              component="span"
                              color={"#008080"}
                              fontSize={14}
                            >
                              Tickets Booked:&nbsp;
                            </Typography>

                            <Typography
                              component="span"
                              color={"#777777"}
                              fontSize={14}
                            >
                              {item?.capacity - item?.remainingCapacity}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component="span"
                              color={"#008080"}
                              fontSize={14}
                            >
                              Event Link:&nbsp;
                            </Typography>

                            <Typography
                              component="span"
                              color={"#777777"}
                              fontSize={14}
                            >
                              <a
                                href={item?.eventLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item?.eventLink}
                              </a>
                            </Typography>
                          </Box>
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                          >
                            <Box>
                              <Typography
                                component="span"
                                color={"#c05942"}
                                fontSize={14}
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  navigate(`/event-detail?id=${item.id}`)
                                }
                              >
                                View
                              </Typography>
                            </Box>
                            {!isExpired(item) && !item?.cancelledBy && (
                              <>
                                <Box>
                                  <Typography
                                    component="span"
                                    color={"#777777"}
                                    fontSize={14}
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      navigate(`/edit-event?id=${item.id}`)
                                    }
                                  >
                                    Edit
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    component="span"
                                    color={"#777777"}
                                    fontSize={14}
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => cancelEvent(item)}
                                  >
                                    Cancel
                                  </Typography>
                                </Box>
                              </>
                            )}

                            <Box>
                              <Typography
                                component="span"
                                color={"red"}
                                fontSize={14}
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => deleteEvent(item)}
                              >
                                Delete
                              </Typography>
                            </Box>
                            {mLoading && (
                              <Box mt={1}>
                                <Spinner />
                              </Box>
                            )}
                          </Box>
                        </Box>
                        {rows && rows.length > 0 ? (
                          <div style={{ width: "100%" }}>
                            <DataGrid
                              rows={rows}
                              columns={columns}
                              initialState={{
                                pagination: {
                                  paginationModel: { page: 0, pageSize: 5 },
                                },
                              }}
                              pageSizeOptions={[5, 10]}
                            />
                          </div>
                        ) : (
                          <>
                            {!mLoading && (
                              <Box textAlign={"center"}>
                                <Typography
                                  component="span"
                                  color={"#777777"}
                                  fontSize={14}
                                  style={{
                                    textDecoration: "underline",
                                  }}
                                >
                                  We haven't received any booking for this
                                  event.
                                </Typography>
                              </Box>
                            )}
                          </>
                        )}
                        {/* <List component="div" disablePadding>
                          {eventBookingData && (
                            <ListItem style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 8 }}>
                              {eventBookingData.map(
                                (booking: any, index: number) => {
                                  return (
                                    <Box
                                      key={index}
                                      px={2}
                                      py={1}
                                      style={{ backgroundColor: "#f5f5f5", width: '100%' }}
                                    >
                                      <ListItemText
                                        primary={booking?.user?.phone}
                                      />
                                    </Box>
                                  );
                                }
                              )}
                            </ListItem>
                          )}
                        </List> */}
                      </Box>
                      {open && (
                        <BookingAction
                          open={open}
                          booking={currentBooking}
                          onClose={() => {
                            setOpen(false);
                            //expandEventBookings(currentEvent, currentIndex);
                          }}
                        />
                      )}
                    </Collapse>
                  </List>
                );
              })}
          </Box>
        </Box>
      </Box>
      {openCancelConfirm && (
        <ConfirmDialog
          open={openCancelConfirm}
          onClose={() => setOpenCancelConfirm(false)}
          onConfirm={onCancelConfirm}
          message={confirmMessage}
          askForReason={true}
        />
      )}
      {openDeleteConfirm && (
        <ConfirmDialog
          open={openDeleteConfirm}
          onClose={() => setOpenDeleteConfirm(false)}
          onConfirm={onDeleteConfirm}
          message={deleteMessage}
        />
      )}
    </>
  );
};