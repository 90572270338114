import { useEffect, useState } from "react";
import { Box, Typography, Paper, Chip } from "@mui/material";
import {
  About,
  CreatedEventsPopup,
  CreatedCommunitiesPopup,
} from "../../components/profile";
import { useLazyQuery } from "@apollo/client";
import { GET_EVENT_BY_USER_ID } from "../../gql/events";
import { GET_COMMUNITY_BY_USER_ID } from "../../gql/communities";
import { useSearchParams } from "react-router-dom";
import { GET_HOST_BY_ID } from "../../gql/user";
import { HostAvatar } from "../../components/host-avatar";

export const HostProfile = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const hostId = searchParams.get("id");
  const [getHostById] = useLazyQuery(GET_HOST_BY_ID);
  const [getEventsByUserId] = useLazyQuery(GET_EVENT_BY_USER_ID);
  const [getCommunitiesByUserId] = useLazyQuery(GET_COMMUNITY_BY_USER_ID);
  const [host, setHost] = useState(null);
  const [createdEventsData, setCreatedEventsData] = useState<any>([]);
  const [createdCommunitiesData, setCreatedCommunitiesData] = useState<any>([]);

  useEffect(() => {
    const getHostUserById = async () => {
      const user = await getHostById({
        variables: {
          id: hostId,
        },
      });
      setHost(user?.data?.getHostById);
    };
    const getHostEventsById = async () => {
      const events = await getEventsByUserId({
        variables: {
          userId: hostId,
        },
      });
      setCreatedEventsData(events?.data?.getEventByUserId);
    };
    const getHostCommunitiesById = async () => {
      const communities = await getCommunitiesByUserId({
        variables: {
          userId: hostId,
        },
      });
      setCreatedCommunitiesData(communities?.data?.getCommunityByUserId);
    };
    getHostUserById();
    getHostEventsById();
    getHostCommunitiesById();
  }, [hostId, getHostById, getEventsByUserId, getCommunitiesByUserId]);
  const [openCreatedEventsPopup, setOpenCreatedEventsPopup] = useState(false);
  const [openCreatedCommunitiesPopup, setOpenCreatedCommunitiesPopup] =
    useState(false);

  return (
    <Box>
      {host ? (
        <Box>
          <Box
            sx={{
              backgroundColor: "#008080",
              borderBottomLeftRadius: 60,
              borderBottomRightRadius: 60,
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              paddingBottom: { xs: 4, sm: 6 },
              paddingTop: { xs: 2, sm: 6 },
              gap: { xs: 2, sm: 1 },
            }}
            //px={"5%"}
          >
            <Box sx={styled.flex} px={2} gap={2}>
              <HostAvatar host={host} />
              <Chip
                label="HOST"
                sx={{ backgroundColor: "#c05942", color: "white" }}
              />
            </Box>

            <Box sx={[styled.flex, { justifyContent: "center" }]} px={2}>
              <Box sx={styled.flex}>
                {createdEventsData && (
                  <Paper
                    sx={{
                      ...styled.commonPaperStyles,
                      backgroundColor: "rgba(187, 220, 220, 0.45)",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenCreatedEventsPopup(true)}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: "white", ...styled.commonTypographyStyles }}
                    >
                      {createdEventsData?.length}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "white",
                        fontSize: 12,
                        fontWeight: "medium",
                      }}
                    >
                      Event(s)
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "white",
                        fontSize: 12,
                        fontWeight: "medium",
                      }}
                    >
                      Created
                    </Typography>
                  </Paper>
                )}
                {createdCommunitiesData && (
                  <Paper
                    sx={{
                      ...styled.commonPaperStyles,
                      backgroundColor: "white",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenCreatedCommunitiesPopup(true)}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#008080",
                        ...styled.commonTypographyStyles,
                      }}
                    >
                      {createdCommunitiesData?.length}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#008080",
                        fontSize: 12,
                        fontWeight: "medium",
                      }}
                    >
                      Community(s)
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#008080",
                        fontSize: 12,
                        fontWeight: "medium",
                      }}
                    >
                      Created
                    </Typography>
                  </Paper>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            padding="15px"
            sx={{
              paddingX: { xs: 2, sm: 5, md: 10 },
            }}
          >
            <About host={host} />
          </Box>
          {createdEventsData && (
            <CreatedEventsPopup
              open={openCreatedEventsPopup}
              onClose={() => setOpenCreatedEventsPopup(false)}
              createdEventsData={createdEventsData}
              isHost={true}
            />
          )}
          {createdCommunitiesData && (
            <CreatedCommunitiesPopup
              open={openCreatedCommunitiesPopup}
              onClose={() => setOpenCreatedCommunitiesPopup(false)}
              createdCommunitiesData={createdCommunitiesData}
              isHost={true}
            />
          )}
        </Box>
      ) : (
        <Box sx={[styled.flex, { justifyContent: 'center', height: '90vh'}]}>
          <Typography fontSize={24} color="#008080">Nothing to show here.</Typography>
        </Box>
      )
    }
    </Box>
  );
};

const styled = {
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  commonPaperStyles: {
    borderRadius: "6.5px",
    padding: "10px",
    marginLeft: "20px",
    textAlign: "center",
    width: "100px",
  },
  commonTypographyStyles: {
    fontSize: 18,
    fontWeight: "semibold",
  },
};
