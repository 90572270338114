import { Box, Button, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { useAuth } from "../../contexts/AuthContext";
import generatePDF, { Resolution, Margin, Options } from "react-to-pdf";

interface BookingConfirmedProps {
  event: any;
  numberOfTickets: number;
  bookingId: string;
}

export const BookingConfirmed: FC<BookingConfirmedProps> = ({
  event,
  numberOfTickets,
  bookingId,
}) => {
  const { currentUser } = useAuth();
  const [bookedBy, setBookedBy] = useState("");
  const pdfName = `${bookingId}_${event.title}.pdf`;

  useEffect(() => {
    if (currentUser) {
      let bookedByUser = currentUser?.email
        ? currentUser?.email
        : currentUser?.phoneNumber;
      if (currentUser?.profileInfo?.userInfo?.firstName) {
        const userInfo = currentUser?.profileInfo?.userInfo;
        bookedByUser = userInfo?.firstName + " " + userInfo?.lastName;
      }
      setBookedBy(bookedByUser);
    }
  }, [currentUser]);
  const options: Options = {
    filename: pdfName,
    resolution: Resolution.MEDIUM,
    page: {
      margin: Margin.LARGE,
      format: "A4",
    },
    canvas: {
      mimeType: "image/jpeg",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
      canvas: {
        useCORS: true,
      },
    },
  };
  const getTargetElement = () => document.getElementById("ticket_container");

  const downloadTicket = () => generatePDF(getTargetElement, options);

  return (
    <Box
      sx={{
        paddingX: { xs: 2, sm: 15, lg: 60 },
        paddingY: { xs: 2, sm: 5, lg: 7 },
      }}
    >
      <Box style={{ backgroundColor: "white" }} pb={4}>
        <Box id="ticket_container">
          <Box pt={4} textAlign={"center"}>
            <Box
              component={"img"}
              src={require("../../images/logo.png")}
              style={{
                height: "40px",
              }}
            />
          </Box>
          <Box
            padding={2}
            display={"flex"}
            flexDirection={"column"}
            gap={"4px"}
            alignItems={"center"}
          >
            <Box textAlign={"center"}>
              <EventAvailableIcon
                style={{ fontSize: 40, color: "#008080" }}
              ></EventAvailableIcon>
            </Box>
            <Box textAlign={"center"}>
              <Typography variant="h4" fontWeight={1000}>
                Booking Confirmed
              </Typography>
            </Box>
            <Box textAlign={"center"}>
              <Typography fontSize={12} color="#717171" paddingX={2}>
                Your reservation request has been received
              </Typography>
            </Box>
            <Box
              sx={{ backgroundColor: "#F5F5F5", borderRadius: 2 }}
              marginTop={1}
            >
              <Box padding={4}>
                <Box
                  display={"flex"}
                  gap={2}
                  alignItems={"center"}
                  sx={{ flexDirection: { xs: "column", sm: "row" } }}
                >
                  <Box flex={"40%"}>
                    <img
                      src={event?.image}
                      alt="event_img"
                      width="100%"
                      height="250px"
                    />
                  </Box>
                  <Box
                    gap={"5px"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                  >
                    <Typography fontSize={16} fontWeight={"bold"} variant="h2">
                      {event?.title}
                    </Typography>
                    <Typography fontSize={12} color="#717171">
                      <b>Booking Id:</b>
                      {bookingId}
                    </Typography>
                    <Typography fontSize={12} color="#717171">
                      <b>Date:</b>
                      {event?.date}
                    </Typography>
                    <Typography fontSize={12} color="#717171">
                      <b>Duration:</b> {event?.duration}h
                    </Typography>
                    <Typography fontSize={12} color="#717171">
                      <b>Organised By:</b> {event?.organizedBy?.firstName}
                      &nbsp;
                      {event?.organizedBy?.lastName}
                    </Typography>
                    <Typography fontSize={12} color="#717171">
                      <b>Booked By:</b> {bookedBy}
                    </Typography>
                    <Typography fontSize={12} color="#717171">
                      <b>Booking Date:</b> {new Date().toDateString()}
                    </Typography>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                    >
                      <ConfirmationNumberIcon
                        style={{
                          fontSize: 18,
                          marginRight: 5,
                          color: "#717171",
                        }}
                      ></ConfirmationNumberIcon>
                      <Typography fontSize={14} color="#717171">
                        {numberOfTickets} x Ticket(s)
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} paddingTop={"12px"} justifyContent={"center"}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#008080",
              color: "white",
              padding: "10px 55px",
            }}
            onClick={downloadTicket}
          >
            <ConfirmationNumberIcon
              style={{ fontSize: 14, marginRight: 5 }}
            ></ConfirmationNumberIcon>
            <Typography fontSize={12}>Download Ticket</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
