import { FC, useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  GoogleMap,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { Box, Button, Typography } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";

interface GoogleMapComponentProps {
  location: google.maps.LatLngLiteral;
  currentAddress?: string;
  onLocationSelected?: (
    coord: {
      lat: number;
      lng: number;
    },
    address?: string
  ) => void;
  disableSelection?: boolean;
  onClose?: () => void;
}

const gMapLibraries: any = ["places", "drawing"];
export const GoogleMapComponent: FC<GoogleMapComponentProps> = ({
  onLocationSelected,
  location,
  currentAddress,
  disableSelection = false,
  onClose,
}) => {
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const [center, setCenter] = useState(location);
  const goToCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      console.log("Location: ", position);
      const { latitude: lat, longitude: lng } = position.coords;
      setCenter({ lat, lng });
    });
  };

  const [selectedMarker, setSelectedMarker] = useState<{
    lat: number;
    lng: number;
  } | null>(center);
  const mapRef = useRef<google.maps.Map>();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [autoComplete, setAutoComplete] = useState<any>(null);
  const [address, setAddress] = useState(currentAddress);

  useEffect(() => {
    setSelectedMarker(center);
  }, [center]);

  const onSelect = (e: google.maps.MapMouseEvent) => {
    if (!e.latLng || disableSelection) return;

    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setSelectedMarker({ lat, lng });
    if (onLocationSelected) {
      onLocationSelected({ lat, lng });
    }

    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
    }
  };

  const onMapLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    // Add other onLoad logic here if needed
  };

  const gMapApiKey: string =
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY! ||
    process.env.VERCEL_GOOGLE_MAPS_API_KEY!;

  const onAutoCompleteIsLoad = (inputAutoComplete: any) => {
    setAutoComplete(inputAutoComplete);
  };

  const onAutoCompletePlaceIsChanged = () => {
    if (autoComplete !== null) {
      //get selected place's coordinates in { lat, lng }
      setAddress(autoComplete?.getPlace()?.formatted_address);
      const lat = autoComplete?.getPlace()?.geometry?.location?.lat();
      const lng = autoComplete?.getPlace()?.geometry?.location?.lng();
      if (onLocationSelected) {
        onLocationSelected(
          {
            lat: lat,
            lng: lng,
          },
          autoComplete?.getPlace()?.formatted_address
        );
      }
      setSelectedMarker({ lat, lng });
      if (mapRef.current) {
        mapRef.current.panTo({ lat, lng });
      }
    } else console.log("Autocomplete is not loaded yet");
  };
  return (
    <LoadScript googleMapsApiKey={gMapApiKey} libraries={gMapLibraries}>
      {!disableSelection && (
        <>
          <Box display={"flex"} gap={"4px"}>
            <Box flexGrow="1">
              <Autocomplete
                onLoad={onAutoCompleteIsLoad}
                onPlaceChanged={onAutoCompletePlaceIsChanged}
              >
                <input
                  ref={searchInputRef}
                  type="text"
                  placeholder="Search for places..."
                  style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    height: `32px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    width: "100%",
                    marginBottom: "8px",
                  }}
                  onChange={(event) => setAddress(event["target"]["value"])}
                  value={address}
                />
              </Autocomplete>
            </Box>
            <Box>
              <Button
                sx={{
                  background: "#008080",
                  color: "white",
                  padding: "8px",
                  "&: hover": {
                    background: "#008080",
                  },
                }}
                onClick={() => {
                  // if (onLocationSelected)
                  //   onLocationSelected(currentLocation, currentAddress);
                  if (onClose) onClose();
                }}
              >
                <Typography
                  component="span"
                  textTransform={"none"}
                  fontSize={12}
                >
                  Done
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box>
            <Button
              sx={{
                position: "absolute",
                zIndex: 1000,
                top: "280px",
                right: 0,
                marginRight: "18px",
                background: "white",
                padding: 1,
                minWidth: 30,
                "&: hover": {
                  background: "white",
                },
              }}
              onClick={() => goToCurrentLocation()}
              size="small"
            >
              <MyLocationIcon />
            </Button>
          </Box>
        </>
      )}
      <GoogleMap
        onLoad={onMapLoad} // Set the ref on map load
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={8}
        onClick={onSelect}
      >
        {selectedMarker && <Marker position={selectedMarker} />}
      </GoogleMap>
    </LoadScript>
  );
};
