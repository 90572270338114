import { Box, Typography } from "@mui/material";
import { FC, useState, useEffect } from "react";
import { UserInfoTile } from "../user-info-tile";
import { useAuth } from "../../contexts/AuthContext";

interface RequestsProps {
    requests: [];
    errorMessage: string;
}


export const Requests: FC<RequestsProps> = ({ requests, errorMessage }) => {
    const { currentUser } = useAuth();
    const [connectionRequests, setConnectionRequests] = useState([]);

    useEffect(() => {
      setConnectionRequests(requests);
    }, [requests]);

    const filterRequests = (item: any) => {
      const filteredConnectionRequests = connectionRequests.filter(
        (request: any) => (request?.connectedTo !== item.id && request?.userId !== item.id)
      );
      setConnectionRequests(filteredConnectionRequests);
    };

    const getButtonText = (connection: any): string => {
        if (connection.status === "APPROVED") {
          return "Connected";
        } else if (connection.status === "PENDING") {
          if (connection?.userId === currentUser?.profileInfo?.id) {
            return "Pending";
          } else {
            return "Approve";
          }
        }
        return "";
      };
    return (
        <Box
        sx={{
          paddingX: { xs: 2, md: 5, lg: 20 },
          paddingY: { xs: 2, md: 4, lg: 8 },
        }}
      >
        {connectionRequests &&
        connectionRequests.length > 0 ? (
          <Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              gap={3}
              flexWrap={"wrap"}
              sx={{ justifyContent: { xs: "center", sm: "left" } }}
            >
              {connectionRequests.map((item: any, index: number) => {
                return (
                    <UserInfoTile
                      user={
                        item?.userId === currentUser?.profileInfo?.id
                          ? item?.connectedToUser
                          : item?.user
                      }
                      key={index}
                      buttonText={getButtonText(item)}
                      id={item?.id}
                      removeCreatedConnection={(item: any) =>
                        filterRequests(item)
                      }
                    ></UserInfoTile>
                );
              })}
            </Box>
          </Box>
        ) : (
          <Box textAlign={"center"}>
            <Typography variant="h5" fontSize={16}>
              {errorMessage}
            </Typography>
          </Box>
        )}
      </Box>
    );
}