import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Link,
  Button,
  ButtonGroup,
} from "@mui/material";
import { EventCarousel } from "../../components/event-carousel";
import {
  CREATE_EVENT_BOOKING,
  GET_EVENTS,
  GET_EVENT_BOOKING_BY_USER_ID,
  GET_EVENT_BY_ID,
} from "../../gql/events";
import { useMutation, useQuery } from "@apollo/client";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import evt from "@mui/icons-material/CalendarMonth";
import { BookingConfirmed } from "../../components/booking-confirmed";
import { useSearchParams, useNavigate } from "react-router-dom";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import TimerIcon from "@mui/icons-material/Timer";
import CategoryIcon from "@mui/icons-material/Category";
import GroupsIcon from "@mui/icons-material/Groups";
import { useAuth } from "../../contexts/AuthContext";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import GroupIcon from "@mui/icons-material/Group";
import { Spinner } from "../../components/spinner";
import { EventMap } from "../../components/event-map";
import ShareIcon from "@mui/icons-material/Share";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export const EventDetail = () => {
  const navigate = useNavigate();
  const { currentUser, loading } = useAuth();
  const [mapOpen, setMapOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("id");
  if (!eventId) navigate("/");
  const [bookingConfirmed, setBookingConfirmed] = useState(false);
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
  } = useQuery(GET_EVENTS);

  const {
    data: eventData,
    loading: eventLoading,
    error: eventError,
  } = useQuery(GET_EVENT_BY_ID, {
    variables: {
      id: eventId,
    },
  });
  const [data, setData] = useState(eventData);
  const [otherEvents, setOtherEvents] = useState(eventsData);
  const [numberOfTickets, setNumberOfTickets] = useState(0);
  const { data: bookedEventsData, loading: bookedEventsLoading } = useQuery(
    GET_EVENT_BOOKING_BY_USER_ID,
    {
      variables: {
        userId: currentUser?.profileInfo?.id,
      },
    }
  );
  const [createEventBookingMutation] = useMutation(CREATE_EVENT_BOOKING, {
    refetchQueries: ["GetEventBookingByUserId", "GetEvents", "GetEventById", "GetEventByUserId", "GetUserTag"],
  });
  const [processing, setProcessing] = useState(false);
  const [result, setResult] = useState({ isSubmitted: false, message: "" });
  const [bookingId, setBookingId] = useState("");

  useEffect(() => {
    if (eventData) setData(eventData.getEventById);
    if (eventsData)
      setOtherEvents(
        eventsData.getEvent.filter((item: any) => item.id !== eventId)
      );
  }, [eventData, eventsData, eventId]);

  const isMarketplace = data?.category?.toLowerCase() === 'marketplace';
  const bookEvent = () => {
    setResult({ isSubmitted: false, message: "" });
    setProcessing(true);
    if (!loading && !currentUser) {
      navigate(`/login?returnUrl=/event-detail?id=${data.id}`);
      return;
    }
    const eventBookingObj = {
      eventId: data.id,
      numberOfTickets: numberOfTickets,
      bookedBy: currentUser?.profileInfo?.id,
      eventDate: data.date,
    };
    createEventBookingMutation({
      variables: {
        eventBookingInput: {
          ...eventBookingObj,
        },
      },
      onCompleted: (data: any) => {
        if (data.createEventBooking) {
          setResult({
            isSubmitted: true,
            message: "Thanks for booking an event.",
          });
          setProcessing(false);
          setBookingConfirmed(true);
          setBookingId(data.createEventBooking?.id);
        }
      },
      onError: (error: any) => {
        console.log(error);
        setResult({
          isSubmitted: false,
          message: "We are facing some issue. Please try again after sometime.",
        });
        setProcessing(false);
      },
    });
  };

  const isEventValid = () => {
    if (data.cancelledBy) return false;
    if (data.recurringDays) return true;
    const condition =
      new Date(`${data.date?.toString()} ${data.time?.toString()}`).getTime() >
      new Date().getTime();
    return condition;
  };

  const copyLink = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(document.location.href).then(
      function () {
        toast.success("Copied!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      },
      function (err) {
        toast.error("Something went wrong, please try again after sometime", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    );
  };

  if (eventLoading) {
    return (
      <Box mt={4}>
        <Spinner />
      </Box>
    );
  }
  return (
    <Box style={{ backgroundColor: "#F5F5F5", paddingBottom: 64 }}>
      <ToastContainer />
      <Box>
        <img
          src={require("../../images/bg.png")}
          alt="bg"
          width="100%"
          height="20%"
        />
      </Box>
      {data ? (
        <Box>
          {!bookingConfirmed ? (
            <Box
              sx={{
                paddingX: { xs: 2, md: 5, lg: 20 },
                paddingY: { xs: 1, md: 2, lg: 5 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                mb={2}
              >
                <Box style={{ alignItems: "center" }} pr={2}>
                  <EventAvailableIcon
                    style={{ color: "#008080", fontSize: 50 }}
                  ></EventAvailableIcon>
                </Box>
                <Box sx={styled.flex} style={{ display: "block" }}>
                  <Box>
                    <Typography variant="h6">{data?.title}</Typography>
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <CalendarMonthIcon
                      style={{ color: "#008080", fontSize: 16 }}
                    ></CalendarMonthIcon>
                    <Typography variant="h6" color="#008080" fontSize={16}>
                      {data?.date}&nbsp;{data?.time}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={[
                  styled.flex,
                  {
                    display: { xs: "block", sm: "flex" },
                    alignItems: "flex-start",
                  },
                ]}
                gap={5}
              >
                <Box sx={{ width: { xs: "100%", md: "80vw" } }}>
                  <Box mb={2}>
                    <Box
                      component="img"
                      src={data?.image}
                      alt="event"
                      width={"100%"}
                      sx={{ height: { xs: "30vh", md: "60vh" } }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="h5"
                      color="#717171"
                      fontSize={18}
                      fontWeight={"bold"}
                    >
                      {data?.description}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{ backgroundColor: "white" }}
                  padding={4}
                  sx={{ marginTop: { xs: 3, md: 0 } }}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Box>
                      <Typography fontSize={18} fontWeight={"bold"}>
                        Event Details
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        sx={{
                          color: "white",
                          backgroundColor: "#008080",
                          padding: "5px 8px",
                          fontSize: "12px",
                          opacity: 0.9,
                          ":hover": {
                            bgcolor: "#008080", // theme.palette.primary.main
                            color: "white",
                            opacity: 1,
                          },
                        }}
                        onClick={(e: any) => copyLink(e)}
                      >
                        <Typography
                          component="span"
                          textTransform={"none"}
                          fontSize={12}
                        >
                          Share&nbsp;
                        </Typography>
                        <ShareIcon style={{ fontSize: 12 }} />
                      </Button>
                    </Box>
                  </Box>

                  <Box mb={"20px"}>
                    <hr style={{ borderColor: "##EFEFEF" }} />
                  </Box>
                  <Box>
                    {/* <Box
                      style={{
                        marginBottom: 20,
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid container gap={1}>
                        <Grid
                          item
                          style={{
                            backgroundColor: "#008080",
                            color: "white",
                            borderRadius: 10,
                            textAlign: "center",
                          }}
                          sx={{
                            paddingX: "18px",
                            paddingY: { xs: 2, sm: 2 },
                          }}
                        >
                          <Typography variant="h6" fontSize={26}>
                            10
                          </Typography>
                          <Typography variant="h6" fontSize={12}>
                            DAYS
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          style={{
                            backgroundColor: "#008080",
                            color: "white",
                            borderRadius: 10,
                            textAlign: "center",
                          }}
                          sx={{
                            paddingX: "12px",
                            paddingY: { xs: 2, sm: 2 },
                          }}
                        >
                          <Typography variant="h6" fontSize={26}>
                            10
                          </Typography>
                          <Typography variant="h6" fontSize={12}>
                            HOURS
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          style={{
                            backgroundColor: "#008080",
                            color: "white",
                            borderRadius: 10,
                            textAlign: "center",
                          }}
                          sx={{
                            paddingX: { xs: 1, sm: 1 },
                            paddingY: { xs: 2, sm: 2 },
                          }}
                        >
                          <Typography variant="h6" fontSize={26}>
                            10
                          </Typography>
                          <Typography variant="h6" fontSize={12}>
                            MINUTES
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box> */}
                    <Box display="flex" flexDirection="column" gap={4}>
                      <Box
                        flexDirection={"row"}
                        display={"flex"}
                        alignItems={"center"}
                        gap={1}
                      >
                        <AccountCircleIcon
                          style={{ color: "#008080", fontSize: 30 }}
                        ></AccountCircleIcon>
                        <Box>
                          <Typography
                            variant="h6"
                            fontSize={12}
                            color="#717171"
                          >
                            Organised by
                          </Typography>
                          {data?.createdBy === currentUser?.profileInfo?.id ? (
                            <Typography
                              variant="h6"
                              fontSize={16}
                              fontWeight={600}
                            >
                              You
                            </Typography>
                          ) : (
                            <Typography
                              variant="h6"
                              fontSize={16}
                              fontWeight={600}
                            >
                              {data.organizedBy?.firstName}&nbsp;
                              {data.organizedBy?.lastName}
                            </Typography>
                          )}
                          <Typography
                            color="#008080"
                            fontSize={12}
                            textTransform={"none"}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate(
                                data?.createdBy === currentUser?.profileInfo?.id
                                  ? "/profile"
                                  : `/host-profile?id=${data?.createdBy}`
                              );
                            }}
                          >
                            View Profile
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        flexDirection={"row"}
                        display={"flex"}
                        alignItems={"center"}
                        gap={1}
                      >
                        <CalendarMonthIcon
                          style={{ color: "#008080", fontSize: 30 }}
                        ></CalendarMonthIcon>
                        <Box>
                          <Typography
                            variant="h6"
                            fontSize={12}
                            color="#717171"
                          >
                            Date and Time
                          </Typography>
                          <Typography
                            variant="h6"
                            fontSize={16}
                            fontWeight={600}
                          >
                            {data?.date}
                          </Typography>
                          {/* <Typography
                            variant="h6"
                            fontSize={16}
                            fontWeight={600}
                          >
                            {data?.recurringDays}
                          </Typography> */}
                          <Typography
                            variant="h6"
                            fontSize={16}
                            fontWeight={600}
                          >
                            {data?.time}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        flexDirection={"row"}
                        display={"flex"}
                        alignItems={"center"}
                        gap={3}
                      >
                        <Box
                          flexDirection={"row"}
                          display={"flex"}
                          alignItems={"center"}
                          gap={1}
                        >
                          <CurrencyRupeeIcon
                            style={{ color: "#008080", fontSize: 30 }}
                          ></CurrencyRupeeIcon>
                          <Box>
                            <Typography
                              variant="h6"
                              fontSize={12}
                              color="#717171"
                            >
                              Price
                            </Typography>
                            <Typography
                              variant="h6"
                              fontSize={16}
                              fontWeight={600}
                            >
                              {data?.price ? `INR ${data?.price}` : "FREE"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          flexDirection={"row"}
                          display={"flex"}
                          alignItems={"center"}
                          gap={1}
                        >
                          <TimerIcon
                            style={{ color: "#008080", fontSize: 30 }}
                          ></TimerIcon>
                          <Box>
                            <Typography
                              variant="h6"
                              fontSize={12}
                              color="#717171"
                            >
                              Duration
                            </Typography>
                            <Typography
                              variant="h6"
                              fontSize={16}
                              fontWeight={600}
                            >
                              {data?.duration} hr
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        flexDirection={"row"}
                        display={"flex"}
                        alignItems={"center"}
                        gap={3}
                      >
                        <Box
                          flexDirection={"row"}
                          display={"flex"}
                          alignItems={"center"}
                          gap={1}
                        >
                          <GroupsIcon
                            style={{ color: "#008080", fontSize: 30 }}
                          ></GroupsIcon>
                          <Box>
                            <Typography
                              variant="h6"
                              fontSize={12}
                              color="#717171"
                            >
                              Capacity
                            </Typography>
                            <Typography
                              variant="h6"
                              fontSize={16}
                              fontWeight={600}
                            >
                              {data?.capacity}
                            </Typography>
                          </Box>
                        </Box>
                        {!isMarketplace && (
                          <Box
                            flexDirection={"row"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <GroupIcon
                              style={{ color: "#008080", fontSize: 30 }}
                            ></GroupIcon>
                            <Box>
                              <Typography
                                variant="h6"
                                fontSize={12}
                                color="#717171"
                              >
                                Remaining
                              </Typography>
                              <Typography
                                variant="h6"
                                fontSize={16}
                                fontWeight={600}
                              >
                                {data?.remainingCapacity}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                      <Box
                        flexDirection={"row"}
                        display={"flex"}
                        alignItems={"center"}
                        gap={1}
                      >
                        <CategoryIcon
                          style={{ color: "#008080", fontSize: 30 }}
                        ></CategoryIcon>
                        <Box>
                          <Typography
                            variant="h6"
                            fontSize={12}
                            color="#717171"
                          >
                            Category
                          </Typography>
                          <Typography
                            variant="h6"
                            fontSize={16}
                            fontWeight={600}
                          >
                            {data?.category}
                          </Typography>
                        </Box>
                      </Box>
                      {data.community && (
                        <Box
                          flexDirection={"row"}
                          display={"flex"}
                          alignItems={"center"}
                          gap={1}
                        >
                          <Diversity2Icon
                            style={{ color: "#008080", fontSize: 30 }}
                          ></Diversity2Icon>
                          <Box>
                            <Typography
                              variant="h6"
                              fontSize={12}
                              color="#717171"
                            >
                              Community
                            </Typography>
                            <Typography
                              variant="h6"
                              fontSize={16}
                              fontWeight={600}
                            >
                              {data.community?.name}
                            </Typography>
                            <Link
                              href={`/community-detail?id=${data.community?.id}`}
                              color="#008080"
                              underline="none"
                              fontSize={12}
                            >
                              Visit Community
                            </Link>
                          </Box>
                        </Box>
                      )}
                      <Box
                        flexDirection={"row"}
                        display={"flex"}
                        alignItems={"center"}
                        gap={1}
                      >
                        <LocationOnIcon
                          style={{ color: "#008080", fontSize: 30 }}
                        ></LocationOnIcon>
                        <Box>
                          <Typography
                            variant="h6"
                            fontSize={12}
                            color="#717171"
                          >
                            Location
                          </Typography>
                          <Typography
                            variant="h6"
                            fontSize={16}
                            fontWeight={600}
                          >
                            {data?.venue}
                          </Typography>
                          <Typography
                            variant="h6"
                            fontSize={16}
                            fontWeight={600}
                          >
                            {data?.address}
                          </Typography>
                          <Link
                            href="#"
                            color="#008080"
                            underline="none"
                            fontSize={12}
                            onClick={() => setMapOpen(true)}
                          >
                            View Map
                          </Link>
                        </Box>
                      </Box>
                      {!isMarketplace ? (
                        <Box
                          flexDirection={"column"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={1}
                        >
                          <Box textAlign={"center"}>
                            <ButtonGroup size="large">
                              <Button
                                disabled={
                                  numberOfTickets === 0 ||
                                  data?.remainingCapacity === 0 ||
                                  !isEventValid() ||
                                  data?.createdBy ===
                                    currentUser?.profileInfo?.id
                                }
                                onClick={() =>
                                  setNumberOfTickets(numberOfTickets - 1)
                                }
                                sx={styled.ticketButton}
                              >
                                -
                              </Button>
                              <Button
                                disabled
                                sx={{
                                  "&.Mui-disabled": {
                                    ...styled.ticketButton,
                                    color: "black",
                                  },
                                }}
                              >
                                {numberOfTickets}
                              </Button>
                              <Button
                                disabled={
                                  numberOfTickets >= data?.remainingCapacity ||
                                  data?.remainingCapacity === 0 ||
                                  !isEventValid() ||
                                  data?.createdBy ===
                                    currentUser?.profileInfo?.id
                                }
                                onClick={() =>
                                  setNumberOfTickets(numberOfTickets + 1)
                                }
                                sx={styled.ticketButton}
                              >
                                +
                              </Button>
                            </ButtonGroup>
                          </Box>
                          {isEventValid() ? (
                            <>
                              {data?.createdBy ===
                              currentUser?.profileInfo?.id ? (
                                <Button
                                  sx={{
                                    backgroundColor: "#008080",
                                    color: "white",
                                    paddingX: 12,
                                    paddingY: 2,
                                  }}
                                  onClick={() => navigate("/manage-events")}
                                  variant="contained"
                                >
                                  <Typography
                                    fontSize={14}
                                    textTransform={"none"}
                                  >
                                    Manage
                                  </Typography>
                                </Button>
                              ) : (
                                <Button
                                  sx={{
                                    backgroundColor: "#008080",
                                    color: "white",
                                    paddingX: 12,
                                    paddingY: 2,
                                  }}
                                  onClick={bookEvent}
                                  variant="contained"
                                  disabled={
                                    numberOfTickets <= 0 ||
                                    data?.remainingCapacity === 0
                                  }
                                >
                                  {!processing ? (
                                    <Typography
                                      fontSize={14}
                                      textTransform={"none"}
                                    >
                                      Book Now
                                    </Typography>
                                  ) : (
                                    <Typography
                                      fontSize={14}
                                      textTransform={"none"}
                                    >
                                      Booking...
                                    </Typography>
                                  )}
                                </Button>
                              )}
                            </>
                          ) : (
                            <>
                              {data?.cancelledBy ? (
                                <Button
                                  sx={{
                                    backgroundColor: "#008080",
                                    color: "white",
                                    paddingX: 12,
                                    paddingY: 2,
                                  }}
                                  variant="contained"
                                  disabled={true}
                                >
                                  <Typography
                                    fontSize={14}
                                    textTransform={"none"}
                                  >
                                    Cancelled
                                  </Typography>
                                </Button>
                              ) : (
                                <Button
                                  sx={{
                                    backgroundColor: "#008080",
                                    color: "white",
                                    paddingX: 12,
                                    paddingY: 2,
                                  }}
                                  variant="contained"
                                  disabled={true}
                                >
                                  <Typography
                                    fontSize={14}
                                    textTransform={"none"}
                                  >
                                    Expired
                                  </Typography>
                                </Button>
                              )}
                            </>
                          )}

                          {result && result.message && (
                            <Typography
                              color={result.isSubmitted ? "#008080" : "red"}
                            >
                              {result.message}
                            </Typography>
                          )}
                        </Box>
                      ) : (
                        <Box>
                          <Button
                            sx={{
                              backgroundColor: "#008080",
                              color: "white",
                              paddingX: 12,
                              paddingY: 2,
                              width: '100%'
                            }}
                            onClick={() => {
                              if (
                                data?.createdBy === currentUser?.profileInfo?.id
                              ) {
                                navigate("/manage-events");
                              } else {
                                window.location.href = data?.eventLink;
                              }
                            }}
                            variant="contained"
                          >
                            {data?.createdBy ===
                            currentUser?.profileInfo?.id ? (
                              <Typography fontSize={14} textTransform={"none"}>
                                Manage
                              </Typography>
                            ) : (
                              <Typography fontSize={14} textTransform={"none"}>
                                Book Now
                              </Typography>
                            )}
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                {otherEvents && otherEvents.length > 0 && (
                  <Box mt={7}>
                    <Box sx={styled.flex}>
                      <Box>
                        <Typography variant="h5" fontWeight={600} fontSize={24}>
                          More Events
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="button"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/events")}
                          fontSize={12}
                        >
                          See All
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <EventCarousel events={otherEvents}></EventCarousel>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <BookingConfirmed
              event={data}
              numberOfTickets={numberOfTickets}
              bookingId={bookingId}
            ></BookingConfirmed>
          )}
        </Box>
      ) : (
        <Box textAlign={"center"} pt={5}>
          <Typography
            variant="h5"
            fontWeight={600}
            fontSize={20}
            color={"#008080"}
          >
            We didn't find the event you're looking for.
          </Typography>
        </Box>
      )}
      {mapOpen && (
        <EventMap
          open={mapOpen}
          handleClose={() => setMapOpen(false)}
          location={JSON.parse(data?.coordinates)}
        />
      )}
    </Box>
  );
};

const styled = {
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ticketButton: {
    border: "1px solid #008080",
  },
};
