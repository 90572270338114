import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { FC, useState } from "react";
import { NearbyPeopleTile } from "../nearby-people-tile";

interface NearbyPeopleCarouselProps {
  nearbyPeople: any;
}

export const NearbyPeopleCarousel: FC<NearbyPeopleCarouselProps> = ({
  nearbyPeople,
}) => {
  const [nearbyUsers, setNearbyUsers] = useState(nearbyPeople);
  const filterNearbyUsers = (item: any) => {
    const filteredNearbyUsers = nearbyUsers.filter((nearby: any) => nearby?.id !== item.id);
    setNearbyUsers(filteredNearbyUsers);
  }

  return (
    <ImageList
      className="event-community-carousel"
      sx={{
        gridAutoFlow: "column",
        gridTemplateColumns: {
          xs: "repeat(auto-fit, minmax(30%, 2fr)) !important",
          sm: "repeat(auto-fit, minmax(22%, 2fr)) !important",
          lg: "repeat(auto-fit, minmax(11%, 2fr)) !important",
        },
        gridAutoColumns: {
          xs: "minmax(30%, 2fr)",
          sm: "minmax(22%, 2fr)",
          lg: "minmax(11%, 2fr)",
        },
        overflowX: "auto",
        paddingBottom: 2,
      }}
    >
      {nearbyUsers?.map((item: any, index: number) => (
        <>
        <ImageListItem key={index} sx={{ paddingRight: 1 }}>
          <NearbyPeopleTile item={item} removeCreatedConnection={(item: any) => filterNearbyUsers(item)}></NearbyPeopleTile>
        </ImageListItem>
        </>
      ))}
    </ImageList>
  );
};
