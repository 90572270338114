import { Box, CircularProgress } from "@mui/material";

export const Spinner = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
      }}
    >
      <CircularProgress sx={{ color: "#c05942" }} />
    </Box>
  );
};
