import { useState } from "react";
import { useFormControls } from "./controls";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { CREATE_CONTACT_US } from "../../gql/contact-us";

const inputFieldValues = [
  {
    name: "firstName",
    label: "First Name*",
    id: "first-name",
    flex: "49%",
  },
  {
    name: "lastName",
    label: "Last Name",
    id: "last-name",
    flex: "49%",
  },
  {
    name: "email",
    label: "Email*",
    id: "email",
    flex: "49%",
  },
  {
    name: "phone",
    label: "Phone Number",
    id: "phone",
    flex: "49%",
  },
  {
    name: "message",
    label: "Message*",
    id: "message",
    multiline: true,
    rows: 5,
    flex: "100%",
  },
];

export const ContactUsForm = () => {
  const [processing, setProcessing] = useState(false);
  const [result, setResult] = useState({ isSubmitted: false, message: '' });
  const { handleInputValue, handleFormSubmit, formIsValid, errors } =
    useFormControls();
  const [createContactUsMutation] = useMutation(CREATE_CONTACT_US);

  const handleSubmit = async (event: any) => {
    setProcessing(true);
    const values = await handleFormSubmit(event);
    const contactUsObj = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      message: values.message,
    };
    createContactUsMutation({
      variables: {
        contactUsInput: {
          ...contactUsObj,
        },
      },
      onCompleted: (data: any) => {
        if (data.createContactUs) {
          setResult({
            isSubmitted: true,
            message: "Thanks for contacting us. We'll get back to you soon!",
          });
          document.forms[0]?.reset();
          setProcessing(false);
        }
      },
      onError: (error: any) => {
        console.log(error);
        setResult({
          isSubmitted: false,
          message:
            "We are facing some issue. Please try again after sometime.",
        });
        setProcessing(false);
      },
    });
  };

  return (
    <Box>
      {result && result.message && <Typography color={result.isSubmitted ? '#008080' : 'red'} margin={2}>{result.message}</Typography>}
      <form autoComplete="off" id="contact-us-form">
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          gap={"1%"}
        >
          {inputFieldValues.map((inputFieldValue, index) => {
            return (
              <Box key={index} sx={{ marginBottom: { xs: '4%', md: '2%'}, flex: { xs: '100%', md: inputFieldValue.flex }}}>
                <TextField
                  key={index}
                  onChange={handleInputValue}
                  onBlur={handleInputValue}
                  name={inputFieldValue.name}
                  label={inputFieldValue.label}
                  error={errors[inputFieldValue.name]}
                  multiline={inputFieldValue.multiline ?? false}
                  fullWidth
                  rows={inputFieldValue.rows ?? 1}
                  autoComplete="none"
                  {...(errors[inputFieldValue.name] && {
                    error: true,
                    helperText: errors[inputFieldValue.name],
                  })}
                />
              </Box>
            );
          })}
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            disabled={!formIsValid()}
            sx={{ backgroundColor: "#008080", color: "white", padding: '10px 15px' }}
            onClick={handleSubmit}
          >
            {!processing ? (
              <Typography fontSize={14} textTransform={"none"}>Send Message</Typography>
            ) : (
              <Typography fontSize={14} textTransform={"none"}>Sending...</Typography>
            )}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

