// import { Swiper, SwiperSlide } from "swiper/react";
// import { EffectCards, Autoplay } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/effect-cards";
// import "./styles.css";
import { Box } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_TEASERS } from "../../gql/teasers";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from "react-router-dom";

export const Teaser = () => {
  const navigate = useNavigate();
  const {
    data: teasersData,
    loading: teasersLoading,
    error: teasersError,
  } = useQuery(GET_TEASERS);

  return (
    // <Box sx={{ overflow: "hidden", display: "flex", justifyContent: "center" }}>
    //   <Box sx={{ width: "100%", height: { xs: "220px", sm: "400px" } }}>
    <Carousel autoPlay showThumbs={false} infiniteLoop swipeable useKeyboardArrows showArrows={false} showStatus={false}>
      {teasersData &&
        teasersData?.getTeasers &&
        teasersData?.getTeasers?.map((teaser: any, index: number) => {
          return (
            <Box key={index} sx={{ width: "100%", height: { xs: "250px", sm: "90vh" } }}>
              {/* <a
                    href={teaser.link}
                    target="_blank"
                    rel="noreferrer"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <img
                      src={teaser.image}
                      alt={"teaser-" + index}
                      width="100%"
                      height="100%"
                    />
                  </a> */}
              <Box component="img" alt="Scheme Banner" src={teaser.image} style={{ cursor: 'pointer', height: '100%' }} onClick={() => navigate(teaser.link)} />
            </Box>
          );
        })}
    </Carousel>
    //   </Box>
    // </Box>
  );
};
