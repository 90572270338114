import { Box, Typography } from "@mui/material";
import { BecomeHostForm } from "../../components/become-host-form";

export const BecomeHost = () => {

  return (
    <Box>
      <Box
        style={{
          backgroundImage: "../../../images/Loginbackground.png",
          backgroundColor: "#008080",
        }}
        sx={{
          paddingX: { xs: 3, sm: 12, lg: 20 },
          paddingY: { xs: 8, sm: 12, lg: 13 },
        }}
      >
        <Box sx={[styled.flex, { flexDirection: { xs: 'column', md: 'row' }} ]} gap={6}>
        <Box gap={4} display={'flex'} flexDirection={'column'} sx={{ textAlign: { xs: 'center', md: 'start'}, flex: '60%' }}>
          <Typography variant="h2" fontSize={40} fontWeight={'bold'} color={'white'}>HobbyHopp</Typography>
          <Typography variant="h2" fontSize={24} fontWeight={'700'} color={'white'}>Become a host...</Typography>
          <Typography color={'white'}>Host events to invite people, Explore new hobbies with your crowd.</Typography>
        </Box>
        <Box flex={'40%'}>
          <BecomeHostForm></BecomeHostForm>
        </Box>
        <Box>
        </Box>
        </Box>
      </Box>
    </Box>
  );
};

const styled = {
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};
