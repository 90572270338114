import { FC, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { dialogContentStyles, dialogHeaderStyles } from "../../styles/dialog";
import CloseIcon from "@mui/icons-material/Close";
import { ConfirmDialog } from "../confirm-dialog";
import { useAuth } from "../../contexts/AuthContext";
import { useMutation } from "@apollo/client";
import { CANCEL_EVENT_BOOKING, APPROVE_EVENT_BOOKING, REJECT_EVENT_BOOKING } from "../../gql/events";

interface BookingActionProps {
  open: boolean;
  onClose: () => void;
  booking: any;
}

export const BookingAction: FC<BookingActionProps> = ({
  open = false,
  onClose,
  booking,
}) => {
  const { currentUser } = useAuth();
  const [openCancelConfirm, setOpenCancelConfirm] = useState(false);
  const [openApproveConfirm, setOpenApproveConfirm] = useState(false);
  const [openRejectConfirm, setOpenRejectConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [currentAction, setCurrentAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<any>(null);
  const [cancelEventBookingMutation] = useMutation(CANCEL_EVENT_BOOKING, {
    refetchQueries: ["GetEventBookingById"],
  });
  const [approveEventBookingMutation] = useMutation(APPROVE_EVENT_BOOKING, {
    refetchQueries: ["GetEventBookingById"],
  });
  const [rejectEventBookingMutation] = useMutation(REJECT_EVENT_BOOKING, {
    refetchQueries: ["GetEventBookingById"],
  });
  const isExpired = (event: any) => {
    if (event.recurringDays) return false;
    let condition =
        new Date(
          `${event?.date?.toString()} ${event?.time?.toString()}`
        ).getTime() < new Date().getTime();
    return condition;
  };

  const approveBooking = () => {
    setCurrentAction("approve");
    setConfirmMessage("Are you sure you want to approve this booking?");
    setOpenApproveConfirm(true);
    setCurrentIndex(0);
  };

  const rejectBooking = () => {
    setCurrentAction("reject");
    setConfirmMessage("Are you sure you want to reject this booking?");
    setOpenRejectConfirm(true);
    setCurrentIndex(1);
  };

  const cancelBooking = () => {
    setCurrentAction("cancel");
    setConfirmMessage("Are you sure you want to cancel this booking?");
    setOpenCancelConfirm(true);
    setCurrentIndex(2);
  };

  const onConfirm = () => {
    setLoading(true);
    if (currentAction === "approve") {
      setOpenApproveConfirm(false);
      const updateBookingStatusObj = {
        eventBookingId: booking.id,
        updatedBy: currentUser?.profileInfo?.id,
      };
      approveEventBookingMutation({
        variables: {
          updateBookingStatusInput: {
            ...updateBookingStatusObj,
          },
        },
        onCompleted: (data: any) => {
          if (data?.approveEventBooking?.isSuccess) {
            console.log("Approved Event Booking");
            setLoading(false);
            setCurrentIndex(null);
            onClose();
          }
        },
        onError: (error: any) => {
          console.log(error);
          setLoading(false);
          setCurrentIndex(null);
          alert(
            "Error while processing this request, please try again after sometime."
          );
        },
      });
    } else if (currentAction === "reject") {
      setOpenRejectConfirm(false);
      const updateBookingStatusObj = {
        eventBookingId: booking.id,
        updatedBy: currentUser?.profileInfo?.id,
      };
      rejectEventBookingMutation({
        variables: {
          updateBookingStatusInput: {
            ...updateBookingStatusObj,
          },
        },
        onCompleted: (data: any) => {
          if (data?.rejectEventBooking?.isSuccess) {
            console.log("Rejected Event Booking");
            setLoading(false);
            setCurrentIndex(null);
            onClose();
          }
        },
        onError: (error: any) => {
          console.log(error);
          setLoading(false);
          setCurrentIndex(null);
          alert(
            "Error while processing this request, please try again after sometime."
          );
        },
      });
    } else if (currentAction === "cancel") {
      setOpenCancelConfirm(false);
      const updateBookingStatusObj = {
        eventBookingId: booking.id,
        updatedBy: currentUser?.profileInfo?.id,
      };
      cancelEventBookingMutation({
        variables: {
          updateBookingStatusInput: {
            ...updateBookingStatusObj,
          },
        },
        onCompleted: (data: any) => {
          if (data?.cancelEventBooking?.isSuccess) {
            console.log("Cancelled Event Booking");
            setLoading(false);
            setCurrentIndex(null);
            onClose();
          }
        },
        onError: (error: any) => {
          console.log(error);
          setLoading(false);
          setCurrentIndex(null);
          alert(
            "Error while processing this request, please try again after sometime."
          );
        },
      });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={dialogHeaderStyles}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography textTransform={"none"}>Actions</Typography>
            <CloseIcon
              style={{ color: "white", cursor: "pointer" }}
              onClick={onClose}
            />
          </Box>
        </DialogTitle>
        <Box>
          <DialogContent sx={dialogContentStyles}>
            {!isExpired(booking?.event) ? (
              <Box>
                <Box mb={2}>
                  {booking?.cancelledBy ? (
                    <Typography
                      component="span"
                      fontSize={14}
                      color={"#777777"}
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      This event booking has been cancelled.
                    </Typography>
                  ) : (
                    <Typography component="span" fontSize={14}>
                      Please select the action which you want to perform on this
                      booking.
                    </Typography>
                  )}
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  {!booking?.approvedBy &&
                    !booking?.rejectedBy &&
                    !booking?.cancelledBy && (
                      <Button
                        variant="outlined"
                        disabled={loading || currentIndex}
                        sx={{
                          color: "#008080",
                          backgroundColor: "white",
                          padding: "4px",
                          fontSize: "12px",
                          opacity: 0.9,
                          border: "1px solid #008080",
                          ":hover": {
                            bgcolor: "white", // theme.palette.primary.main
                            color: "#008080",
                            opacity: 1,
                            border: "1px solid #008080",
                          },
                        }}
                        onClick={approveBooking}
                      >
                        {loading && 0 === currentIndex ? (
                          <CircularProgress
                            sx={{ color: "#008080" }}
                            style={{ width: 14, height: 14 }}
                          />
                        ) : (
                          <Typography fontSize={14} textTransform={"none"}>
                            Approve Booking
                          </Typography>
                        )}
                      </Button>
                    )}
                  {!booking?.approvedBy &&
                    !booking?.rejectedBy &&
                    !booking?.cancelledBy && (
                      <Button
                        variant="outlined"
                        disabled={loading || currentIndex}
                        sx={{
                          color: "#c05942",
                          backgroundColor: "white",
                          padding: "4px",
                          fontSize: "12px",
                          opacity: 0.9,
                          border: "1px solid #c05942",
                          ":hover": {
                            bgcolor: "white", // theme.palette.primary.main
                            color: "#c05942",
                            opacity: 1,
                            border: "1px solid #c05942",
                          },
                        }}
                        onClick={rejectBooking}
                      >
                        {loading && 1 === currentIndex ? (
                          <CircularProgress
                            sx={{ color: "#c05942" }}
                            style={{ width: 14, height: 14 }}
                          />
                        ) : (
                          <Typography fontSize={14} textTransform={"none"}>
                            Reject Booking
                          </Typography>
                        )}
                      </Button>
                    )}
                  {!booking?.cancelledBy && (
                    <Button
                      variant="outlined"
                      disabled={loading || currentIndex}
                      sx={{
                        color: "#777777",
                        backgroundColor: "white",
                        padding: "4px",
                        fontSize: "12px",
                        opacity: 0.9,
                        border: "1px solid #777777",
                        ":hover": {
                          bgcolor: "white", // theme.palette.primary.main
                          color: "#777777",
                          opacity: 1,
                          border: "1px solid #777777",
                        },
                      }}
                      onClick={cancelBooking}
                    >
                      {loading && 2 === currentIndex ? (
                        <CircularProgress
                          sx={{ color: "#777777" }}
                          style={{ width: 14, height: 14 }}
                        />
                      ) : (
                        <Typography fontSize={14} textTransform={"none"}>
                          Cancel Booking
                        </Typography>
                      )}
                    </Button>
                  )}
                </Box>
              </Box>
            ) : (
              <Box textAlign={"center"}>
                <Typography
                  component="span"
                  color={"#777777"}
                  fontSize={14}
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  This event is expired.
                </Typography>
              </Box>
            )}
          </DialogContent>
        </Box>
      </Dialog>
      {openApproveConfirm && (
        <ConfirmDialog
          open={openApproveConfirm}
          onClose={() => {
            setLoading(false);
            setCurrentIndex(null);
            setOpenApproveConfirm(false);
          }}
          onConfirm={onConfirm}
          message={confirmMessage}
        />
      )}
      {openRejectConfirm && (
        <ConfirmDialog
          open={openRejectConfirm}
          onClose={() => {
            setLoading(false);
            setCurrentIndex(null);
            setOpenRejectConfirm(false);
          }}
          onConfirm={onConfirm}
          message={confirmMessage}
        />
      )}
      {openCancelConfirm && (
        <ConfirmDialog
          open={openCancelConfirm}
          onClose={() => {
            setLoading(false);
            setCurrentIndex(null);
            setOpenCancelConfirm(false);
          }}
          onConfirm={onConfirm}
          message={confirmMessage}
        />
      )}
    </>
  );
};
