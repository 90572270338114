import { Typography, Box } from '@mui/material';

export const NotFound = () => {

  return (
    <Box style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#008080',
      height: '95vh'
    }}>
      <Box style={{
        width: '350px',
        height: '350px',
        borderRadius: '50%',
        backgroundColor: '#EFEFEF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 16
      }}>
        <Typography style={{ fontSize: 32, fontWeight: 'bold', color: '#201F1F' }}>
          404
        </Typography>
        <Typography style={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center', color: '#201F1F', marginTop: '10px' }}>
          Looks like you are lost.
        </Typography>
        <Typography style={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'center', color: '#201F1F', marginTop: '10px', maxWidth: 250 }}>
          Oops! The page you are looking for does not exist. It might have been moved or deleted.
        </Typography>
      </Box>
    </Box>
  );
};