import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { UserInfoTile } from "../user-info-tile";
import { useLazyQuery } from "@apollo/client";
import { GET_PEOPLE_NEAR_YOU } from "../../gql/user-connection";
import { useAuth } from "../../contexts/AuthContext";

export const NearbyPeople = () => {
  const { currentUser } = useAuth();

  const [getNearbyUsers, { loading: getNearbyUsersLoading }] =
    useLazyQuery(GET_PEOPLE_NEAR_YOU);

  const [distance, setDistance] = useState<number>(10);
  const [nearbyUsers, setNearbyUsers] = useState<any>([]);

  const filterNearbyUsers = (item: any) => {
    const filteredNearbyUsers = nearbyUsers.filter(
      (nearby: any) => nearby?.id !== item.id
    );
    setNearbyUsers(filteredNearbyUsers);
  };

  useEffect(() => {
    if (currentUser) {
      getNearbyUsers({
        variables: {
          getUserBasedonTagsInput: {
            id: currentUser?.profileInfo?.id,
            distance,
          },
        },
        onCompleted: (data: any) => {
          if (
            data &&
            data?.getUserBasedonTags &&
            data?.getUserBasedonTags?.length > 0
          ) {
            setNearbyUsers([...data?.getUserBasedonTags]);
          } else if (distance < 100) {
            setDistance(distance + 10);
          }
        },
      });
    }
  }, [currentUser, distance, getNearbyUsers]);

  return (
    <Box
      sx={{
        paddingX: { xs: 2, md: 5, lg: 20 },
        paddingY: { xs: 2, md: 4, lg: 8 },
      }}
    >
      {getNearbyUsersLoading ? (
        <>
          <Box textAlign={"center"}>
            <img src="/radar.gif" alt="Loading..." height={200} />
          </Box>
        </>
      ) : (
        <>
          {nearbyUsers && nearbyUsers.length > 0 ? (
            <Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={3}
                flexWrap={"wrap"}
                sx={{ justifyContent: { xs: "center", sm: "left" } }}
              >
                {nearbyUsers.map((item: any, index: number) => {
                  return (
                    <UserInfoTile
                      user={item}
                      key={index}
                      removeCreatedConnection={(item: any) =>
                        filterNearbyUsers(item)
                      }
                    ></UserInfoTile>
                  );
                })}
              </Box>
            </Box>
          ) : (
            <Box textAlign={"center"}>
              <Typography variant="h5" fontSize={16}>
                No nearby users found.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
