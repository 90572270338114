import { useMutation, useQuery } from "@apollo/client";
import { useState, useEffect, FC } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { CREATE_USER_TAGS, GET_USER_TAG } from "../../gql/tags";
import { TagPopup } from "../profile";

export const TagInterceptor: FC<any> = () => {
  const { currentUser } = useAuth();
  const userName = !currentUser?.profileInfo?.userInfo?.firstName?.includes("HobbyHopp");

  const [openTagPopup, setOpenTagPopup] = useState(false);

  const handleOpenTagPopup = () => {
    setOpenTagPopup(true);
  };

  const handleCloseTagPopup = () => {
    setOpenTagPopup(false);
  };

  const { data: tagsData } = useQuery(GET_USER_TAG, {
    variables: {
      userId: currentUser?.profileInfo?.id,
    },
  });
  const userTags = tagsData?.getUserTag;

  useEffect(() => {
    const userTagsArr = [];
    if (userTags && userTags.length > 0) {
      for (let i = 0; i < userTags.length; i++) {
        userTagsArr.push(userTags[i].tag);
      }
    }
    const filteredUserTags = userTagsArr?.filter(
      (tag: String, index: number, inputArray: any) =>
        tag !== "Marketplace" &&
        inputArray.indexOf(tag) === index &&
        tag != null
    );
    if ((tagsData && filteredUserTags && filteredUserTags.length === 0) || !userName) {
      handleOpenTagPopup();
    }

  }, [userTags, tagsData, userName]);

  const [createUserTagsMutation] = useMutation(CREATE_USER_TAGS, {
    refetchQueries: ['GetUserTag']
  });


  const saveUserTags = (tags: any, refresh?: boolean) => {
    createUserTagsMutation({
      variables: {
        userTagInput: {
          userId: currentUser?.profileInfo?.id,
          tags: tags,
        },
      },
      onCompleted: (data: any) => {
        if (data.createUserTag) {
          console.log("Tags are saved successfully");
          if (refresh) {
            window.location.reload();
          }
        }
      },
      onError: (error: any) => {
        console.log(error);
        alert("Error while saving tags. Please try again after sometime.");
      },
    });
  };

  return (
    <>
      {openTagPopup && (
        <TagPopup
          open={openTagPopup}
          onClose={handleCloseTagPopup}
          onSave={saveUserTags}
          selectedTags={userTags}
        />
      )}
    </>
  );
};
