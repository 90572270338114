import { gql } from "@apollo/client";

export const GET_USERS = gql`
    query GetUsers {
        getUsers {
            id
            email
            phone
            userInfo {
                id
                firstName
                lastName
                bio
                gender
                address
                dateOfBirth
                image
            }
            userRole {
                role {
                    name
                }
            }
    }
}
`;

export const GET_USER_BY_MAIL = gql`
    query GetUserByMail($mail: String) {
        getUserByMail(mail: $mail) {
            id
            email
            phone
            userInfo {
                id
                firstName
                lastName
                bio
                gender
                address
                dateOfBirth
                image
            }
            userRole {
                role {
                    name
                }
            }
    }
}
`;

export const GET_HOST_BY_ID = gql`
    query GetHostById($id: String) {
        getHostById(id: $id) {
            id
            email
            phone
            userInfo {
                id
                firstName
                lastName
                bio
                gender
                address
                dateOfBirth
                image
            }
    }
}
`;

export const GET_USER_BY_PHONE = gql`
    query GetUserByPhone($phone: String) {
        getUserByPhone(phone: $phone) {
            id
            firstName
            lastName
            email
            phone
            isActive
            blockedBy
            deletedBy
            userRole {
                role {
                    name
                }
            }
    }
}
`;

export const GET_USER_BY_MAIL_OR_PHONE = gql`
    query GetUserByMailOrPhone($mail: String, $phone: String) {
        getUserByMailOrPhone(mail: $mail, phone: $phone) {
            id
            email
            phone
            isActive
            blockedBy
            deletedBy
            userRole {
                role {
                    name
                }
            }
            userInfo {
                id
                firstName
                lastName
                bio
                gender
                address
                dateOfBirth
                image
                userId
                createdBy
                createdDate
                updatedBy
                updatedDate
            }
            userTag { 
                tag
            }
            userConnection {
                id
                userId
                connectedTo
                status
            }
    }
    }
`;

export const CREATE_USER_BY_MAIL = gql`
    mutation CreateUserByMail($mail: String!) {
        createUserByMail(mail: $mail) {
            email
        }
    }
`;

export const CREATE_USER_BY_PHONE = gql`
    mutation CreateUserByPhone($phone: String!) {
        createUserByPhone(phone: $phone) {
            phone
        }
    }
`;

export const CREATE_USER_BY_MAIL_OR_PHONE = gql`
    mutation CreateUserByMailOrPhone($mail: String, $phone: String) {
        createUserByMailOrPhone(mail: $mail, phone: $phone) {
            id
            firstName
            lastName
            email
            phone
            userRoleId
            isActive
            blockedBy
            deletedBy
        }
    }
`;

export const UPDATE_USER_NAME_AND_BIO = gql`
    mutation UpdateUserNameAndBio($updateUserNameInput: UpdateUserNameInput) {
        updateUserNameAndBio(updateUserNameInput: $updateUserNameInput) {
            firstName
            bio
        }
    }
`;

export const UPDATE_USER_LOCATION = gql`
    mutation UpdateUserLocation($updateUserLocationInput: UpdateUserLocationInput!) {
        updateUserLocation(updateUserLocationInput: $updateUserLocationInput) {
            id
        }
    }
`;