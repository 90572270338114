import React, { useState, useEffect } from "react";
import Stories from "react-insta-stories";
import { Box, Stack, Avatar, Button, Typography } from "@mui/material";
import {
  GET_COMMUNITY_BY_USER_ID,
  GET_COMMUNITY_JOINED_BY_USER_ID,
} from "../../gql/communities";
import { useQuery } from "@apollo/client";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export const StoryComponent: React.FC = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [createdJoinedCommunities, setCreatedJoinedCommunities] = useState<any>(
    []
  );

  const [currentAvatarIndex, setCurrentAvatarIndex] = useState(0);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [showStories, setShowStories] = useState(false);
  const { data: joinedCommunitiesData, refetch: joinedCommunitiesRefetch } =
    useQuery(GET_COMMUNITY_JOINED_BY_USER_ID, {
      variables: {
        userId: currentUser?.profileInfo?.id,
      },
    });
  const { data: createdCommunitiesData, refetch: createdCommunitiesRefetch } =
    useQuery(GET_COMMUNITY_BY_USER_ID, {
      variables: {
        userId: currentUser?.profileInfo?.id,
      },
    });

  useEffect(() => {
    let createdJoinedCommunitiesArr: any = [];
    createdCommunitiesData?.getCommunityByUserId?.map((communityItem: any) => {
      const community = {
        id: communityItem?.id,
        name: communityItem?.name,
        isCreated: true,
        image: communityItem?.image,
        events: communityItem?.event,
      };
      if (!communityItem.deletedBy) {
        createdJoinedCommunitiesArr.push(community);
      }
    });
    joinedCommunitiesData?.getCommunityJoinedByUserId?.map((item: any) => {
      const community = {
        id: item?.community?.id,
        name: item?.community?.name,
        image: item?.community?.image,
        isJoined: true,
        events: item?.community?.event,
      };
      if (!item.deletedBy) {
        createdJoinedCommunitiesArr.push(community);
      }
    });

    setCreatedJoinedCommunities(createdJoinedCommunitiesArr);
  }, [joinedCommunitiesData, createdCommunitiesData]);
  useEffect(() => {
    // joinedCommunitiesRefetch();
    // createdCommunitiesRefetch();
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAvatarClick = (index: number) => {
    setCurrentAvatarIndex(index);
    setShowStories(true);
  };

  const handleCancel = () => {
    setShowStories(false);
  };

  const avatarComponents = createdJoinedCommunities.map(
    (community: any, index: number) => (
      <Avatar
        key={community.id}
        alt={community.name}
        src={community.image}
        onClick={() => handleAvatarClick(index)}
        style={{
          width: 70,
          height: 70,
          cursor: "pointer",
        }}
      />
    )
  );

  const getLeft = () => {
    return (window.innerWidth - 100) / 2;
  };

  const getStories = () => {
    let storiesArr = [];
    if (createdJoinedCommunities[currentAvatarIndex].events?.length > 0) {
      storiesArr = createdJoinedCommunities[currentAvatarIndex].events.map(
        (event: any) => ({
          content: () => {
            return (
              <div>
                <img src={event.image} alt={event.name} />
                <Button
                  onClick={() => navigate(`/event-detail?id=${event.id}`)}
                  style={{ zIndex: 100000, bottom: 20, left: getLeft() }}
                  sx={{
                    position: "absolute",
                    color: "white",
                    backgroundColor: "#c05942",
                    padding: "5px 10px",
                    fontSize: "12px",
                    opacity: 0.9,
                    ":hover": {
                      bgcolor: "#c05942",
                      opacity: 1,
                    },
                  }}
                >
                  <Typography
                    textTransform={"none"}
                    fontSize={12}
                    component="span"
                  >
                    Open Event
                  </Typography>
                </Button>
              </div>
            );
          },
        })
      );
    } else {
      storiesArr = [{ url: "/images/no_events.png" }];
    }
    return storiesArr;
  };

  return (
    <Box>
      {createdJoinedCommunities?.length === 0 ? (
        <Box>
          <img
            src={require("../../images/bg.png")}
            alt="bg"
            width="100%"
            height="20%"
          />
        </Box>
      ) : (
        <Box sx={{ backgroundColor: '#008080', backgroundSize: 'cover' }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ padding: 2, overflow: "scroll" }}
          >
            {avatarComponents}
          </Stack>

          {showStories && (
            <Box
              style={{ zIndex: 10000, position: "absolute" }}
              className="hobbyhopp-stories"
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "50%",
                  minWidth: "0px",
                  position: "fixed",
                  top: 20,
                  right: 10,
                  zIndex: 1,
                  padding: "8px 16px",
                  opacity: 0.9,
                  backgroundColor: "#c05942",
                  ":hover": {
                    bgcolor: "#c05942",
                    opacity: 1,
                  },
                }}
                onClick={handleCancel}
              >
                X
              </Button>
              <Stories
                key={currentAvatarIndex}
                keyboardNavigation
                stories={getStories()}
                defaultInterval={6000}
                width={windowDimensions.width}
                height={windowDimensions.height}
                onAllStoriesEnd={() => {
                  if (
                    currentAvatarIndex <
                    createdJoinedCommunities.length - 1
                  ) {
                    handleAvatarClick(currentAvatarIndex + 1);
                  } else {
                    setShowStories(false);
                  }
                }}
                storyContainerStyles={{
                  position: "fixed",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
                progressStyles={{
                  backgroundColor: "#c05942",
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
