import { Box, Typography } from "@mui/material";
import { CommunityCarousel } from "../../components/community-carousel";
import { StoryComponent } from "../../components/story";
import {
  GET_NEARBY_COMMUNITIES,
  GET_ONLINE_COMMUNITIES,
} from "../../gql/communities";
import { useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "../../components/spinner";
import { LocationContext } from "../../contexts/LocationContext";

export const Community = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentLocation } = useContext(LocationContext);
  const communityType = searchParams.get("type");
  const { data: onlineCommunitiesData, loading: onlineCommunitiesLoading } =
    useQuery(GET_ONLINE_COMMUNITIES);
  const { data: nearByCommunitiesData, loading } = useQuery(
    GET_NEARBY_COMMUNITIES,
    {
      variables: {
        nearbyCommunitiesInput: {
          lat: currentLocation.lat,
          lng: currentLocation.lng,
        },
      },
    }
  );

  const [communitiesData, setCommunitiesData] = useState([]);

  useEffect(() => {
    if (nearByCommunitiesData) {
      setCommunitiesData(nearByCommunitiesData.getNearbyCommunities);
    }
    if (communityType) {
      const communitiesData =
        nearByCommunitiesData?.getNearbyCommunities?.filter(
          (item: any) => item.category === communityType
        );
      setCommunitiesData(communitiesData);
    }
  }, [nearByCommunitiesData, communityType]);

  if (loading) {
    return (
      <Box mt={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box style={{ backgroundColor: "#F5F5F5", paddingBottom: 64 }}>
      <StoryComponent />
      {!loading && (
        <Box
          sx={{
            paddingX: { xs: 2, md: 5, lg: 20 },
            paddingY: { xs: 2, md: 2, lg: 5 },
          }}
        >
          <Box>
            <Typography
              variant="h5"
              fontWeight={600}
              fontSize={20}
              color={"#c05942"}
            >
              {communityType} Communities Near You
            </Typography>
          </Box>
          {communitiesData && communitiesData.length > 0 ? (
            <Box>
              <Box>
                <CommunityCarousel
                  communities={communitiesData}
                ></CommunityCarousel>
              </Box>
            </Box>
          ) : (
            <Box textAlign={"center"} pt={5}>
              <Typography
                variant="h5"
                fontWeight={600}
                fontSize={20}
                color={"#c05942"}
              >
                No communities found.
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {!onlineCommunitiesLoading && (
        <Box
          sx={{
            paddingX: { xs: 2, md: 5, lg: 20 },
            paddingY: { xs: 2, md: 2, lg: 5 },
          }}
        >
          {onlineCommunitiesData &&
            onlineCommunitiesData.getOnlineCommunities &&
            onlineCommunitiesData.getOnlineCommunities.length > 0 && (
              <Box>
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    fontSize={20}
                    color={"#c05942"}
                  >
                    Online Communities
                  </Typography>
                </Box>
                <Box>
                  <CommunityCarousel
                    communities={onlineCommunitiesData.getOnlineCommunities}
                  ></CommunityCarousel>
                </Box>
              </Box>
            )}
        </Box>
      )}
    </Box>
  );
};
