import { Box, Avatar, Typography } from "@mui/material";
import { FC } from "react";

export const HostAvatar: FC<any> = ({ host }) => {
  const getRandomNumber = Math.floor(Math.random() * 5) + 1;
  const getUserAvatar = () => {
    if (host?.userInfo?.image) {
      if (host?.userInfo?.image?.length === 1) {
        return require(`../../images/avatar${host?.userInfo?.image}.png`);
      } else {
        return host?.userInfo?.image;
      }
    } else {
      return require(`../../images/avatar${getRandomNumber}.png`);
    }
  };
  return (
    <Box>
      {host ? (
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={1}
          alignItems={"center"}
        >
          {host?.userInfo ? (
            <Box
              display={"flex"}
              flexDirection={"row"}
              gap={1}
              alignItems={"center"}
            >
              <Avatar
                src={getUserAvatar()}
                sx={[
                  styled.avatar,
                  { "& .MuiAvatar-img": { objectFit: "contain" } },
                ]}
              />
              <Box>
                <Typography fontSize={16} color={"white"} style={{ overflowWrap: 'anywhere' }}>
                  {host?.userInfo?.firstName}&nbsp;
                  {host?.userInfo?.lastName}
                </Typography>
                <Typography fontSize={12} color={"white"} style={{ overflowWrap: 'anywhere' }}>
                  {host?.userInfo?.address}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              display={"flex"}
              flexDirection={"row"}
              gap={1}
              alignItems={"center"}
            >
              <Avatar
                src={require(`../../images/avatar${getRandomNumber}.png`)}
                sx={styled.avatar}
              />
              <Typography fontSize={16} color={"white"} style={{ overflowWrap: 'anywhere' }}>
                {host?.email
                  ? host?.email
                  : host?.phoneNumber}
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={1}
          alignItems={"center"}
        >
          <Avatar {...stringAvatar(getFormattedName("Guest"))} />
          <Box>
            <Typography fontSize={16} color={"white"} style={{ overflowWrap: 'anywhere' }}>
              Hello, Guest
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const styled = {
  avatar: {
    border: "3px solid #c05942",
    width: 60,
    height: 60,
  },
};

const getFormattedName = (firstName: any, lastName?: any) => {
  return (
    firstName?.toUpperCase() + (lastName ? " " + lastName?.toUpperCase() : "")
  );
};

const stringAvatar = (name: string) => {
  const children =
    name?.split(" ").length > 1
      ? `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`
      : `${name?.split(" ")[0][0]}`;
  return {
    sx: {
      bgcolor: "#c05942",
    },
    children: children,
  };
};
