export const inputFieldValues = [
    {
      name: "image",
      id: "image",
      label: "Upload event picture*",
      flex: "100%",
      isMedia: true,
      type: "file"
    },
    {
      name: "name",
      label: "Give your event a name*",
      id: "name",
      flex: "100%",
      placeholder: "Enter event name here",
      type: "text"
    },
    {
      name: "category",
      label: "Choose a category for your event",
      id: "category",
      flex: "32%",
      placeholder: "Select Category",
      isCategory: true,
    },
    // {
    //   name: "repeat",
    //   label: "Repeat  event",
    //   id: "repeat",
    //   flex: "32%",
    //   placeholder: "Select Days"
    // },
    {
      name: "capacity",
      label: "Event capacity*",
      id: "capacity",
      flex: "32%",
      placeholder: "Total number of tickets",
      type: "number"
    },
    {
      name: "price",
      label: "Event fee*",
      id: "price",
      flex: "32%",
      placeholder: "Price of one ticket",
      type: "number"
    },
    {
      name: "date",
      label: "Date",
      id: "date",
      flex: "32%",
      placeholder: "MM/DD/YYYY",
      isDate: true,
    },
    {
      name: "time",
      label: "Time",
      id: "time",
      flex: "32%",
      placeholder: "Select event time",
      isTime: true,
    },
    {
      name: "duration",
      label: "Duration",
      id: "duration",
      flex: "32%",
      placeholder: "1 h",
      type: "number"
    },
    {
      name: "description",
      label: "Describe your event*",
      id: "description",
      multiline: true,
      rows: 5,
      flex: "100%",
      placeholder: "Description",
      type: "text"
    },
    {
      name: "venue",
      label: "Provide venue name*",
      id: "venue",
      flex: "100%",
      placeholder: "Venue",
      type: "text"
    },
    {
      name: "address",
      label: "Provide event address*",
      id: "address",
      flex: "100%",
      placeholder: "Select Address",
      type: "text",
      isAddress: true,
    },
    // {
    //   name: "address2",
    //   label: "Address line 2*",
    //   id: "address2",
    //   flex: "49%",
    //   placeholder: "Sector, Landmark etc",
    //   type: "text"
    // },
    // {
    //   name: "country",
    //   label: "Country",
    //   id: "country",
    //   flex: "49%",
    //   placeholder: "Select Country",
    //   value: "India",
    //   disabled: true,
    //   type: "text"
    // },
    // {
    //   name: "state",
    //   label: "State",
    //   id: "state",
    //   flex: "49%",
    //   placeholder: "Select State",
    //   isState: true
    // },
    // {
    //   name: "city",
    //   label: "City*",
    //   id: "city",
    //   flex: "49%",
    //   placeholder: "Enter City",
    //   type: "text"
    // },
    // {
    //   name: "postalCode",
    //   label: "Postal Code*",
    //   id: "postalCode",
    //   flex: "49%",
    //   placeholder: "Post/Zip Code",
    //   type: "number"
    // },
    {
      name: "community",
      label: "Link a community with your event",
      id: "community",
      flex: "100%",
      placeholder: "Select Community",
      isCommunity: true,
    },
    {

      name: "recurringEvent",
      label: "Make it a recurring event",
      id: "recurringEvent",
      flex: "100%",
      placeholder: "Recurring Event",
      isRecurring: true,
    }
    // {
    //   name: "phone",
    //   label: "Link a Phone number with this event",
    //   id: "phone",
    //   flex: "49%",
    //   placeholder: "Phone Number"
    // },
  ]; 

export const indianStates = [ "Andhra Pradesh",
"Arunachal Pradesh",
"Assam",
"Bihar",
"Chhattisgarh",
"Goa",
"Gujarat",
"Haryana",
"Himachal Pradesh",
"Jammu and Kashmir",
"Jharkhand",
"Karnataka",
"Kerala",
"Madhya Pradesh",
"Maharashtra",
"Manipur",
"Meghalaya",
"Mizoram",
"Nagaland",
"Odisha",
"Punjab",
"Rajasthan",
"Sikkim",
"Tamil Nadu",
"Telangana",
"Tripura",
"Uttarakhand",
"Uttar Pradesh",
"West Bengal",
"Andaman and Nicobar Islands",
"Chandigarh",
"Dadra and Nagar Haveli",
"Daman and Diu",
"Delhi",
"Lakshadweep",
"Puducherry"];

export const categories = ['Education', 'Dance & Music', 'Arts & Crafts', 'Dating', 'Trekking & Hiking', 'Sports(Indoor/ Outdoor)', 'Charity', 'Meetups', 'Miscellaneous'];

export const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
