import React from 'react';
import { Typography, Box } from '@mui/material';

export const Reviews = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%" marginTop="50px">
      <Typography variant="h4" sx={{ fontFamily: 'Roboto', color: '#008080', fontWeight: 'bold' }}>
        Coming Soon!
      </Typography>
    </Box>
  );
};