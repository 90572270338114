import { createContext, useContext, useState, useEffect, FC } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  signInWithPhoneNumber,
  RecaptchaVerifier
} from "firebase/auth";

import { auth } from "../config/firebase";
import { useLazyQuery } from "@apollo/client";
import { GET_USER_BY_MAIL_OR_PHONE } from "../gql/user";
import { Spinner } from "../components/spinner";
import { NameModal } from "../components/profile";

const AuthContext = createContext<any>(null);
const provider = new GoogleAuthProvider();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider: FC<any> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [getUserByMailOrPhoneQuery] = useLazyQuery(GET_USER_BY_MAIL_OR_PHONE);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openNameModal, setOpenNameModal] = useState(false);
  const [doNotUpdateProfile, setDoNotUpdateProfile] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("accessToken") ? true : false;
    const doNotUpdateProfile = localStorage.getItem("doNotUpdateProfile") ? true : false;
    setDoNotUpdateProfile(doNotUpdateProfile);
    setIsLoggedIn(token);
  }, []);
  let recaptchaVerifier: any;

  const signInWithPhone = (phone: string) => {
    if (!recaptchaVerifier) {
      recaptchaVerifier  = new RecaptchaVerifier(auth, 'recaptcha-container', {
      'size': 'invisible',
      'callback': (response: any) => {
        console.log(response);
      },
      'expired-callback': () => {
        console.log('Expired');
      }
    });
  }
    return signInWithPhoneNumber(auth, phone, recaptchaVerifier);
  };

  const signInWithGoogle = () => {
    return signInWithPopup(auth, provider);
  };

  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user: any) => {
      if (user) {
        setLoading(true);
        const emailAddress = user?.email;
        const phoneNumber = user?.phoneNumber;
        const getUserByMailOrPhoneResult = await getUserByMailOrPhoneQuery({
          variables: {
            mail: emailAddress,
            phone: phoneNumber
          },
        });
        const loggedInUser = getUserByMailOrPhoneResult?.data?.getUserByMailOrPhone;
        user.getIdToken().then((token: string) => {
          localStorage.setItem("accessToken", token);
          user.profileInfo = loggedInUser;
          if (
            loggedInUser?.userRole &&
            (loggedInUser?.userRole?.role?.name === "Host" || loggedInUser?.userRole?.role?.name === "Admin")
          )
            user.isHost = true;
            if (
              loggedInUser?.userRole &&
              (loggedInUser?.userRole?.role?.name === "Admin")
            )
              user.isAdmin = true;
            if (!loggedInUser?.userInfo?.firstName || !loggedInUser?.userInfo?.lastName) {
              setOpenNameModal(true);
            }
            setCurrentUser(user);
          setLoading(false);
        });
      } else {
        setLoading(false);
        localStorage.removeItem("accessToken");
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    error,
    setError,
    signInWithGoogle,
    signInWithPhone,
    logout,
    isLoggedIn,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : <Spinner />}
      {/* {openNameModal && !doNotUpdateProfile && (
        <NameModal
          open={openNameModal && !doNotUpdateProfile}
          onClose={() => setOpenNameModal(false)}
        />
      )} */}
    </AuthContext.Provider>
  );
};