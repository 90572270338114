import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { CANCEL_EVENT_BOOKING, GET_EVENT_BOOKING_BY_USER_ID } from "../../gql/events";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "../spinner";
import { useState } from "react";
import { ConfirmDialog } from "../confirm-dialog";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import InfoIcon from '@mui/icons-material/Info';

export const BookedEvents = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const { data: bookedEventsData, loading: bookedEventsLoading } = useQuery(
    GET_EVENT_BOOKING_BY_USER_ID,
    {
      variables: {
        userId: currentUser?.profileInfo?.id,
      },
    }
  );
  const [selectedBookingId, setSelectedBookingId] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<any>(null);

  const [cancelEventBookingMutation] = useMutation(
    CANCEL_EVENT_BOOKING,
    {
      refetchQueries: ["GetEventBookingByUserId"],
    }
  );

  const isExpired = (event: any) => {
    if (event?.recurringDays) return false;
    let condition =
        new Date(
          `${event?.date?.toString()} ${event?.time?.toString()}`
        ).getTime() < new Date().getTime();
    return condition;
  };

  const cancelBooking = (bookingId: string, index: number) => {
    setCurrentIndex(index);
    setSelectedBookingId(bookingId);
    setConfirmMessage("Are you sure you want to cancel this booking?");
    setOpenConfirm(true);
  };

  const onConfirm = () => {
    setLoading(true);
    setOpenConfirm(false)
    const updateBookingStatusObj = {
      eventBookingId: selectedBookingId,
      updatedBy: currentUser?.profileInfo?.id,
    };
    cancelEventBookingMutation({
      variables: {
        updateBookingStatusInput: {
          ...updateBookingStatusObj,
        },
      },
      onCompleted: (data: any) => {
        if (data?.cancelEventBooking?.isSuccess) {
          console.log("Cancelled Event Booking");
          setLoading(false);
          setCurrentIndex(null);
          setOpenConfirm(false);
        }
      },
      onError: (error: any) => {
        console.log(error);
        setLoading(false);
        setCurrentIndex(null);
        alert(
          "Error while processing this request, please try again after sometime."
        );
      },
    });
  };

  if (bookedEventsLoading) {
    return (
      <Box mt={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <Box>
        {bookedEventsData &&
        bookedEventsData?.getEventBookingByUserId?.length > 0 ? (
          <Box>
            {bookedEventsData?.getEventBookingByUserId.map(
              (item: any, index: number) => {
                return (
                  <Box
                    padding="16px"
                    alignItems="center"
                    key={index}
                    onClick={() =>
                      navigate(`/event-detail?id=${item?.event?.id}`)
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    <Card
                      sx={{
                        borderRadius: "16px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        alt="Event Image"
                        src={item?.event?.image}
                        sx={{ width: "92px", height: "92px", margin: "10px" }}
                        variant="rounded"
                      />
                      <CardContent
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "16px 16px 16px 0",
                          width: "100%",
                        }}
                      >
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              color: "#717171",
                            }}
                          >
                            {item?.event?.date}
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "16px",
                            }}
                          >
                            {item?.event?.title}
                          </Typography>
                          {/* <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  color: "#008080",
                                }}
                              >
                                 {item?.event?.organizedBy?.firstName}&nbsp;
                            {item?.event?.organizedBy?.lastName}
                              </Typography> */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: { xs: "column", md: "row" },
                            }}
                          >
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  color: "#008080",
                                }}
                              >
                                Booked On:&nbsp;
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  color: "#717171",
                                }}
                              >
                                {item?.bookedDate}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  color: "#008080",
                                }}
                              >
                                &nbsp;Ticket(s) Booked:&nbsp;
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  color: "#717171",
                                }}
                              >
                                {item?.numberOfTickets}
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "12px",
                                color: "#008080",
                              }}
                            >
                              Status:&nbsp;
                            </Typography>
                            {item?.event?.cancelledBy ? (
                              <>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "12px",
                                    color: "red",
                                  }}
                                >
                                  Event is cancelled by host&nbsp;
                                </Typography>
                                <Typography className={`cancel-event-tooltip-${item.id}`}>
                                  <InfoIcon
                                    style={{ color: "red", fontSize: 14 }}
                                  />
                                </Typography>
                                <Tooltip
                                  anchorSelect={`.cancel-event-tooltip-${item.id}`}
                                  content={item?.event?.cancellationReason}
                                />
                              </>
                            ) : (
                              <>
                                {isExpired(item?.event) ? (
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontSize: "12px",
                                      color: "red",
                                    }}
                                  >
                                    Expired
                                  </Typography>
                                ) : (
                                  <>
                                    {item?.cancelledBy &&
                                    item?.cancelledDate ? (
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "12px",
                                          color: "#717171",
                                        }}
                                      >
                                        Cancelled
                                      </Typography>
                                    ) : (
                                      <>
                                        {item?.rejectedBy &&
                                        item?.rejectedDate ? (
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              fontSize: "12px",
                                              color: "yellow",
                                            }}
                                          >
                                            Rejected By Host
                                          </Typography>
                                        ) : (
                                          <>
                                            {item?.approvedBy &&
                                            item?.approvedBy ? (
                                              <Typography
                                                variant="body2"
                                                sx={{
                                                  fontSize: "12px",
                                                  color: "green",
                                                }}
                                              >
                                                Approved By Host
                                              </Typography>
                                            ) : (
                                              <Typography
                                                variant="body2"
                                                sx={{
                                                  fontSize: "12px",
                                                  color: "#717171",
                                                }}
                                              >
                                                Pending Approval from Host
                                              </Typography>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </Box>
                        </Box>
                        {!item?.rejectedBy &&
                          !item?.cancelledBy &&
                          !isExpired(item?.event) &&
                          !item?.event?.cancelledBy && (
                            <Box
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {loading && index === currentIndex ? (
                                <CircularProgress
                                  sx={{ color: "#c05942" }}
                                  style={{ width: 15, height: 15 }}
                                />
                              ) : (
                                <Button
                                  variant="outlined"
                                  sx={{
                                    color: "#c05942",
                                    backgroundColor: "white",
                                    padding: "2px",
                                    fontSize: "12px",
                                    opacity: 0.9,
                                    border: "1px solid #c05942",
                                    zIndex: "1000",
                                    ":hover": {
                                      bgcolor: "white", // theme.palette.primary.main
                                      color: "#c05942",
                                      opacity: 1,
                                      border: "1px solid #c05942",
                                    },
                                  }}
                                  onClick={(event: any) => {
                                    cancelBooking(item?.id, index);
                                    event.stopPropagation();
                                  }}
                                >
                                  <Typography
                                    textTransform={"none"}
                                    fontSize={12}
                                  >
                                    Cancel
                                  </Typography>
                                </Button>
                              )}
                            </Box>
                          )}
                      </CardContent>
                    </Card>
                  </Box>
                );
              }
            )}
          </Box>
        ) : (
          <Box mt={1}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "16px",
              }}
            >
              You haven't booked any event yet.&nbsp;
              <Link to="/events">Click</Link> to explore events.
            </Typography>
          </Box>
        )}
      </Box>
      {openConfirm && (
        <ConfirmDialog
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
          onConfirm={onConfirm}
          message={confirmMessage}
        />
      )}
    </>
  );
};
