import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { FC } from "react";
import { NearbyPeopleTile } from "../nearby-people-tile";
import { useAuth } from "../../contexts/AuthContext";

interface ConnectionsCarouselProps {
  connections: any;
}

export const ConnectionsCarousel: FC<ConnectionsCarouselProps> = ({
  connections,
}) => {
  const { currentUser } = useAuth();
  const getButtonText = (connection: any): string => {
    if (connection.status === "APPROVED") {
      return "Connected";
    } else if (connection.status === "PENDING") {
      if (connection?.userId === currentUser?.profileInfo?.id) {
        return "Pending";
      } else {
        return "Approve";
      }
    }
    return "";
  };
  return (
    <ImageList
      className="event-community-carousel"
      sx={{
        gridAutoFlow: "column",
        gridTemplateColumns: {
          xs: "repeat(auto-fit, minmax(30%, 2fr)) !important",
          sm: "repeat(auto-fit, minmax(22%, 2fr)) !important",
          lg: "repeat(auto-fit, minmax(11%, 2fr)) !important",
        },
        gridAutoColumns: {
          xs: "minmax(30%, 2fr)",
          sm: "minmax(22%, 2fr)",
          lg: "minmax(11%, 2fr)",
        },
        overflowX: "auto",
        paddingBottom: 0,
      }}
    >
      {connections.map((item: any, index: number) => (
        <ImageListItem key={index} sx={{ paddingRight: 1 }}>
          <NearbyPeopleTile
            item={
              item?.userId === currentUser?.profileInfo?.id
                ? item?.connectedToUser
                : item?.user
            }
            buttonText={getButtonText(item)}
            id={item?.id}
            openChat={false}
            showName={false}
            contactInfo={
              item?.userId === currentUser?.profileInfo?.id
                ? item?.connectedUserPhone ?? item?.connectedUserMail
                : item?.userPhone ?? item?.userMail
            }
          ></NearbyPeopleTile>
        </ImageListItem>
      ))}
    </ImageList>
  );
};
