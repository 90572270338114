import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import {
  GET_COMMUNITY_JOINED_BY_USER_ID,
  DELETE_USER_COMMUNITY,
} from "../../gql/communities";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "../spinner";
import { useState } from "react";
import { ConfirmDialog } from "../confirm-dialog";

export const JoinedCommunities = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { data: joinedCommunitiesData, loading: joinedCommunitieLoading } = useQuery(
    GET_COMMUNITY_JOINED_BY_USER_ID,
    {
      variables: {
        userId: currentUser?.profileInfo?.id,
      },
    }
  );

  const [deleteUserCommunityMutation] = useMutation(DELETE_USER_COMMUNITY, {
    refetchQueries: ["GetCommunityJoinedByUserId"],
  });
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [selectedCommunityId, setSelectedCommunityId] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<any>(null);

  const leaveCommunity = (communityId: any, index: number) => {
    setCurrentIndex(index);
    setSelectedCommunityId(communityId);
    setConfirmMessage("Are you sure you want to leave this community?");
    setOpenConfirm(true);
  };

  const onConfirm = () => {
    setLoading(true);
    deleteUserCommunityMutation({
      variables: {
        id: selectedCommunityId,
      },
      onCompleted: (data: any) => {
        if (data.deleteUserCommunity) {
          console.log("Left Community");
          setLoading(false);
          setCurrentIndex(null);
          setOpenConfirm(false);
        }
      },
      onError: (error: any) => {
        console.log(error);
        setLoading(false);
        setCurrentIndex(null);
        alert(
          "Error while processing this request, please try again after sometime."
        );
      },
    });
  };

  if (joinedCommunitieLoading) {
    return (
      <Box mt={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <Box>
        {joinedCommunitiesData &&
        joinedCommunitiesData?.getCommunityJoinedByUserId?.length > 0 && joinedCommunitiesData?.getCommunityJoinedByUserId?.filter((item: any) => !item?.community?.deletedBy).length > 0 ? (
          <Box>
            {joinedCommunitiesData?.getCommunityJoinedByUserId.map(
              (item: any, index: number) => {
                return (
                 <>{!item?.community.deletedBy && (
                  <Box
                    padding="16px"
                    alignItems="center"
                    key={index}
                    onClick={() =>
                      navigate(`/community-detail?id=${item?.community?.id}`)
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    <Card
                      sx={{
                        borderRadius: "16px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        alt="Community Image"
                        src={item?.community?.image}
                        sx={{ width: "92px", height: "92px", margin: "10px" }}
                        variant="rounded"
                      />
                      <CardContent
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "16px 16px 16px 0",
                          width: "100%",
                        }}
                      >
                        <Box>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "16px",
                            }}
                          >
                            {item?.community?.name}
                          </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  color: "#008080",
                                }}
                              >
                                 {item?.community?.organizedBy?.firstName}&nbsp;
                            {item?.community?.organizedBy?.lastName}
                              </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: { xs: "column", md: "row" },
                            }}
                          >
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  color: "#008080",
                                }}
                              >
                                Created On:&nbsp;
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  color: "#717171",
                                }}
                              >
                                {item?.community.createdDate}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  color: "#008080",
                                }}
                              >
                                Joined On:&nbsp;
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  color: "#717171",
                                }}
                              >
                                {item?.joinedDate}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                            {loading && index === currentIndex ? (
                              <CircularProgress sx={{ color: "#c05942" }} style={{ width: 15, height: 15 }} />
                            ) : (
                              <Button
                                variant="outlined"
                                sx={{
                                  color: "#c05942",
                                  backgroundColor: "white",
                                  padding: "2px",
                                  fontSize: "12px",
                                  opacity: 0.9,
                                  border: "1px solid #c05942",
                                  zIndex: "1000",
                                  ":hover": {
                                    bgcolor: "white", // theme.palette.primary.main
                                    color: "#c05942",
                                    opacity: 1,
                                    border: "1px solid #c05942",
                                  },
                                }}
                                onClick={(event: any) => {
                                  leaveCommunity(item?.id, index);
                                  event.stopPropagation();
                                }}
                              >
                                  <Typography textTransform={"none"} fontSize={12}>
                                    Leave
                                  </Typography>
                              </Button>
                            )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                  )}</>
                );
              }
            )}
          </Box>
        ) : (
          <Box mt={1}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "16px",
              }}
            >
              You haven't joined any community yet.&nbsp;
              <Link to="/communities">Click</Link> to explore communities.
            </Typography>
          </Box>
        )}
      </Box>
      {openConfirm && (
        <ConfirmDialog
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
          onConfirm={onConfirm}
          message={confirmMessage}
        />
      )}
    </>
  );
};
