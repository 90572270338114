import { Box, Button, Chip, Typography } from "@mui/material";
import { FC, useState } from "react";
import { TagPopup } from "./tag";
import { useAuth } from "../../contexts/AuthContext";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BoyIcon from "@mui/icons-material/Boy";
import GirlIcon from "@mui/icons-material/Girl";
import { CREATE_USER_TAGS } from "../../gql/tags";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const About: FC<any> = ({ host, tags }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [openTagPopup, setOpenTagPopup] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>(tags);
  const [tagColors, setTagColors] = useState<Map<string, string>>(new Map());

  const [createUserTagsMutation] = useMutation(CREATE_USER_TAGS, {
    refetchQueries: ['GetUserTag']
  });

  const user = host ? host : currentUser?.profileInfo;

  useEffect(() => {
    setSelectedTags(tags);
  }, [tags]);

  const handleOpenTagPopup = () => {
    setOpenTagPopup(true);
  };

  const handleCloseTagPopup = () => {
    setOpenTagPopup(false);
  };

  const handleSaveTags = (tags: string[], refresh?: boolean) => {
    const newTags = tags.filter((tag) => !tagColors.has(tag));
    newTags.forEach((tag) => {
      const randomColor = getRandomColor();
      setTagColors((prevColors) => prevColors.set(tag, randomColor));
    });

    setSelectedTags(tags);
    setOpenTagPopup(false);
    saveUserTags(tags);
  };

  const saveUserTags = (tags: any, refresh?: boolean) => {
    createUserTagsMutation({
      variables: {
        userTagInput: {
          userId: currentUser?.profileInfo?.id,
          tags: tags,
        },
      },
      onCompleted: (data: any) => {
        if (data.createUserTag) {
          console.log("Tags are saved successfully");
          if (refresh) {
            window.location.reload();
          }
        }
      },
      onError: (error: any) => {
        console.log(error);
        alert("Error while saving tags. Please try again after sometime.");
      },
    });
  };
  const getRandomColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  };

  return (
    <Box display="flex" flexDirection="column" m={2}>
      {user?.userInfo && (
        <Box>
          <Box>
          {user && (
            <>
              <Typography variant="h6" fontSize={18} fontWeight={"bold"}>
                Logged In As
              </Typography>
              <Typography fontSize={16}>{user?.phone ?? user?.email}</Typography>
            </>
          )}
          </Box>
          <Box display={"flex"} flexDirection={"row"} gap={2} mb={2} mt={2}>
            <Box
              flexDirection={"row"}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              <CalendarMonthIcon
                style={{ color: "#008080", fontSize: 30 }}
              ></CalendarMonthIcon>
              <Box>
                <Typography variant="h6" fontSize={12} color="#717171">
                  Date of Birth
                </Typography>
                {user?.userInfo?.dateOfBirth && <Typography variant="h6" fontSize={16} fontWeight={600}>
                  {new Date(user?.userInfo?.dateOfBirth).toDateString()}
                </Typography>}
              </Box>
            </Box>
            <Box
              flexDirection={"row"}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              {user?.userInfo?.gender === "Male" ? (
                <BoyIcon style={{ color: "#008080", fontSize: 30 }}></BoyIcon>
              ) : (
                <GirlIcon style={{ color: "#008080", fontSize: 30 }}></GirlIcon>
              )}
              <Box>
                <Typography variant="h6" fontSize={12} color="#717171">
                  Gender
                </Typography>
                <Typography variant="h6" fontSize={16} fontWeight={600}>
                  {user?.userInfo?.gender}
                </Typography>
              </Box>
            </Box>
            {/* <Box
              flexDirection={"row"}
              display={"flex"}
              alignItems={"center"}
              gap={1}
              ml={3}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{
                  fontSize: "12px",
                  color: "white",
                  border: "2px solid white",
                  backgroundColor: "#008080",
                  textTransform: "none",
                  borderRadius: "10px",
                  cursor: "pointer",
                  ":hover": {
                    bgcolor: "#c05942",
                    color: "white",
                  },
                }}
                onClick={() => navigate("/edit-profile?returnUrl=/profile")}
              >
                Edit Profile
              </Button>
            </Box> */}
          </Box>
          {user?.userInfo?.bio && (
            <>
              <Typography variant="h6" fontSize={18} fontWeight={"bold"}>
                Bio
              </Typography>
              <Typography fontSize={16}>{user?.userInfo?.bio}</Typography>
            </>
          )}
        </Box>
      )}
      {selectedTags && (
        <>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            mt={3}
            gap={1}
          >
            <Typography variant="h6" fontSize={18} fontWeight={"bold"}>
              Tags
            </Typography>
            {!host && (
              <ControlPointIcon
                sx={{ color: "#c05942", fontSize: 18, cursor: "pointer" }}
                onClick={handleOpenTagPopup}
              ></ControlPointIcon>
            )}
          </Box>
          <Box display="flex" gap={1} mt={1} flexWrap={"wrap"}>
            {selectedTags.map((tag: string, index: number) => (
              <Chip
                key={index}
                label={tag}
                color="primary"
                variant="outlined"
              />
            ))}
          </Box>
          <TagPopup
            open={openTagPopup}
            onClose={handleCloseTagPopup}
            onSave={handleSaveTags}
            selectedTags={selectedTags}
          />
        </>
      )}
    </Box>
  );
};
