import { Typography, Box, Button, Link } from "@mui/material";
import { CommunityCarousel } from "../../components/community-carousel";
import {
  GET_COMMUNITIES,
  GET_COMMUNITY_BY_ID,
  GET_COMMUNITY_JOINED_BY_USER_ID,
  JOIN_COMMUNITY,
} from "../../gql/communities";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CategoryIcon from "@mui/icons-material/Category";
import GroupsIcon from "@mui/icons-material/Groups";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useAuth } from "../../contexts/AuthContext";
import { Spinner } from "../../components/spinner";
import { EventMap } from "../../components/event-map";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShareIcon from "@mui/icons-material/Share";

export const CommunityDetail = () => {
  const navigate = useNavigate();
  const { currentUser, loading } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const communityId = searchParams.get("id");
  const [joinedCommunitiesIds, setJoinedCommunitiesIds] = useState<any>([]);
  if (!communityId) navigate("/");

  const {
    data: communitiesData,
    loading: communitiesLoading,
    error: communitiesError,
  } = useQuery(GET_COMMUNITIES);

  const {
    data: communityData,
    loading: communityLoading,
    error: communityError,
  } = useQuery(GET_COMMUNITY_BY_ID, {
    variables: {
      id: communityId,
    },
  });

  const { data: joinedCommunitiesData } = useQuery(
    GET_COMMUNITY_JOINED_BY_USER_ID,
    {
      variables: {
        userId: currentUser?.profileInfo?.id,
      },
    }
  );
  const [data, setData] = useState(communityData);
  const [otherCommunities, setOtherCommunities] = useState(communitiesData);
  const [joinCommunityMutation] = useMutation(JOIN_COMMUNITY, {
    refetchQueries: [
      "GetCommunityJoinedByUserId",
      "GetCommunityById",
      "GetCommunities",
      "GetCommunityByUserId",
      "GetUserTag",
    ],
  });
  const [processing, setProcessing] = useState(false);
  const [result, setResult] = useState({ isSubmitted: false, message: "" });
  const [mapOpen, setMapOpen] = useState(false);

  useEffect(() => {
    if (communityData) setData(communityData.getCommunityById);
    if (communitiesData)
      setOtherCommunities(
        communitiesData.getCommunity.filter(
          (item: any) => item.id !== communityId
        )
      );
  }, [communityData, communitiesData, communityId]);

  useEffect(() => {
    let joinedCommunitiesIdsArr: any = [];
    joinedCommunitiesData?.getCommunityJoinedByUserId?.map((item: any) => {
      joinedCommunitiesIdsArr.push(item?.community?.id);
    });
    setJoinedCommunitiesIds(joinedCommunitiesIdsArr);
  }, [joinedCommunitiesData]);

  const joinCommunity = () => {
    setResult({ isSubmitted: false, message: "" });
    setProcessing(true);
    if (!loading && !currentUser) {
      navigate(`/login?returnUrl=/community-detail?id=${data.id}`);
      return;
    }
    const joinCommunityObj = {
      communityId: data.id,
      joinedBy: currentUser?.profileInfo?.id,
      createdBy: currentUser?.profileInfo?.id,
    };
    joinCommunityMutation({
      variables: {
        userCommunityInput: {
          ...joinCommunityObj,
        },
      },
      onCompleted: (data: any) => {
        if (data.createUserCommunity) {
          setResult({
            isSubmitted: true,
            message: "Thanks for joining community.",
          });
          setProcessing(false);
          navigate("/communities");
        }
      },
      onError: (error: any) => {
        console.log(error);
        setResult({
          isSubmitted: false,
          message: "We are facing some issue. Please try again after sometime.",
        });
        setProcessing(false);
      },
    });
  };

  const isDisabled = () => {
    return (
      data?.createdBy === currentUser?.profileInfo?.id ||
      joinedCommunitiesIds?.indexOf(communityId) > -1 ||
      data?.deletedBy
    );
  };

  const copyLink = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(document.location.href).then(
      function () {
        toast.success("Copied!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      },
      function (err) {
        toast.error("Something went wrong, please try again after sometime", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    );
  };

  if (communityLoading) {
    return (
      <Box mt={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box style={{ backgroundColor: "#F5F5F5", paddingBottom: 64 }}>
      <ToastContainer />
      <Box>
        <img
          src={require("../../images/bg.png")}
          alt="bg"
          width="100%"
          height="20%"
        />
      </Box>
      {data && (
        <Box
          sx={{
            paddingX: { xs: 2, md: 5, lg: 20 },
            paddingY: { xs: 1, md: 2, lg: 5 },
          }}
        >
          <Box
            sx={[
              styled.flex,
              {
                display: { xs: "block", sm: "flex" },
                alignItems: "flex-start",
              },
            ]}
            gap={5}
          >
            <Box sx={{ width: { xs: "100%", md: "80vw" } }}>
              <Box mb={2}>
                <Box
                  component="img"
                  src={data?.image}
                  alt="event"
                  width={"100%"}
                  sx={{ height: { xs: "30vh", md: "60vh" } }}
                />
              </Box>
              <Box>
                <Typography variant="h5" color="#717171" fontSize={18} fontWeight={'bold'}>
                  {data?.description}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{ backgroundColor: "white" }}
              padding={4}
              sx={{ marginTop: { xs: 3, md: 0 } }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box>
                  <Typography fontSize={18} fontWeight={"bold"}>
                    {data?.name}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    sx={{
                      color: "white",
                      backgroundColor: "#c05942",
                      padding: "5px 8px",
                      fontSize: "12px",
                      opacity: 0.9,
                      ":hover": {
                        bgcolor: "#c05942", // theme.palette.primary.main
                        color: "white",
                        opacity: 1,
                      },
                    }}
                    onClick={(e: any) => copyLink(e)}
                  >
                    <Typography
                      component="span"
                      textTransform={"none"}
                      fontSize={12}
                    >
                      Share&nbsp;
                    </Typography>
                    <ShareIcon style={{ fontSize: 12 }} />
                  </Button>
                </Box>
              </Box>
              <Box mb={"20px"}>
                <hr style={{ borderColor: "##EFEFEF" }} />
              </Box>
              <Box>
                <Box display="flex" flexDirection="column" gap={4}>
                  <Box
                    flexDirection={"row"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <AccountCircleIcon
                      style={{ color: "#c05942", fontSize: 30 }}
                    ></AccountCircleIcon>
                    <Box>
                      <Typography variant="h6" fontSize={12} color="#717171">
                        Organised by
                      </Typography>
                      {data?.createdBy === currentUser?.profileInfo?.id ? (
                        <Typography variant="h6" fontSize={16} fontWeight={600}>
                          You
                        </Typography>
                      ) : (
                        <Typography variant="h6" fontSize={16} fontWeight={600}>
                          {data.organizedBy?.firstName}&nbsp;
                          {data.organizedBy?.lastName}
                        </Typography>
                      )}

                      <Link
                        href={
                          data?.createdBy === currentUser?.profileInfo?.id
                            ? "profile"
                            : `/host-profile?id=${data?.createdBy}`
                        }
                        color="#c05942"
                        underline="none"
                        fontSize={12}
                      >
                        View Profile
                      </Link>
                    </Box>
                  </Box>
                  <Box
                    flexDirection={"row"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <CategoryIcon
                      style={{ color: "#c05942", fontSize: 30 }}
                    ></CategoryIcon>
                    <Box>
                      <Typography variant="h6" fontSize={12} color="#717171">
                        Category
                      </Typography>
                      <Typography variant="h6" fontSize={16} fontWeight={600}>
                        {data?.category}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box
                    flexDirection={"row"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <VisibilityIcon
                      style={{ color: "#c05942", fontSize: 30 }}
                    ></VisibilityIcon>
                    <Box>
                      <Typography variant="h6" fontSize={12} color="#717171">
                        Visibility
                      </Typography>
                      <Typography variant="h6" fontSize={16} fontWeight={600}>
                        {data?.isPrivate ? "Private" : "Public"} Group
                      </Typography>
                    </Box>
                  </Box> */}

                  <Box
                    flexDirection={"row"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <LocationOnIcon
                      style={{ color: "#c05942", fontSize: 30 }}
                    ></LocationOnIcon>
                    <Box>
                      <Typography variant="h6" fontSize={12} color="#717171">
                        Location
                      </Typography>
                      <Typography variant="h6" fontSize={16} fontWeight={600}>
                        {data?.address || "Online"}
                      </Typography>
                      {data?.coordinates && (
                        <Link
                          href="#"
                          color="#c05942"
                          underline="none"
                          fontSize={12}
                          onClick={() => setMapOpen(true)}
                        >
                          View Map
                        </Link>
                      )}
                    </Box>
                  </Box>
                  <Box
                    flexDirection={"row"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <GroupsIcon
                      style={{ color: "#c05942", fontSize: 30 }}
                    ></GroupsIcon>
                    <Box>
                      <Typography variant="h6" fontSize={12} color="#717171">
                        Member(s) Joined
                      </Typography>
                      <Typography variant="h6" fontSize={16} fontWeight={600}>
                        {data?.joinedMembers}
                      </Typography>
                    </Box>
                  </Box>
                  {data?.createdBy === currentUser?.profileInfo?.id ? (
                    <Button
                      sx={{
                        backgroundColor: "#c05942",
                        color: "white",
                        paddingX: 12,
                        paddingY: 2,
                        opacity: 0.9,
                        ":hover": {
                          bgcolor: "#c05942", // theme.palette.primary.main
                          color: "white",
                          opacity: 1,
                        },
                      }}
                      onClick={() => navigate("manage-communities")}
                      variant="contained"
                    >
                      <Typography fontSize={14} textTransform={"none"}>
                        Manage
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        backgroundColor: "#c05942",
                        color: "white",
                        paddingX: 12,
                        paddingY: 2,
                        opacity: 0.9,
                        ":hover": {
                          bgcolor: "#c05942", // theme.palette.primary.main
                          color: "white",
                          opacity: 1,
                        },
                      }}
                      onClick={joinCommunity}
                      variant="contained"
                      disabled={isDisabled()}
                    >
                      {data?.deletedBy ? (
                        <Typography fontSize={14} textTransform={"none"}>
                          Deleted
                        </Typography>
                      ) : (
                        <>
                          {!processing ? (
                            <Typography fontSize={14} textTransform={"none"}>
                              Join
                            </Typography>
                          ) : (
                            <Typography fontSize={14} textTransform={"none"}>
                              Joining...
                            </Typography>
                          )}
                        </>
                      )}
                    </Button>
                  )}
                  {result && result.message && (
                    <Typography color={result.isSubmitted ? "#c05942" : "red"}>
                      {result.message}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            {otherCommunities && otherCommunities.length > 0 && (
              <Box mt={2}>
                <Box sx={styled.flex}>
                  <Box>
                    <Typography variant="h5" fontWeight={600} fontSize={24}>
                      More Communities
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="button"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/communities")}
                      fontSize={12}
                    >
                      See All
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <CommunityCarousel
                    communities={otherCommunities}
                  ></CommunityCarousel>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {mapOpen && (
        <EventMap
          open={mapOpen}
          handleClose={() => setMapOpen(false)}
          location={JSON.parse(data?.coordinates)}
        />
      )}
    </Box>
  );
};

const styled = {
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
};
