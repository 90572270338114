import React, { FC, useState } from "react";
import {
  TextField,
  Button,
  Stack,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import {
  dialogActionsStyles,
  dialogContentStyles,
  dialogHeaderStyles,
} from "../../styles/dialog";
import CloseIcon from '@mui/icons-material/Close';

interface ConfirmDialogProps {
  open: boolean;
  onConfirm: (reason?: string) => void;
  onClose: () => void;
  message: string;
  askForReason?: boolean;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open = false,
  onConfirm,
  onClose,
  message,
  askForReason,
}) => {

  const [reason, setReason] = useState<string | undefined>('');

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={dialogHeaderStyles}>
        <Box display={'flex'} justifyContent={'space-between'}>
        <Typography textTransform={"none"}>Confirm</Typography>
        <CloseIcon style={{ color: 'white', cursor: 'pointer' }} onClick={onClose} />
        </Box>
        </DialogTitle>
      <Box>
        <DialogContent sx={dialogContentStyles} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Box>
            <Typography component="span" fontSize={14}>{message}</Typography>
          </Box>
          {askForReason && (
          <Box>
          <TextField
              key="reason"
              onChange={(e) => {
                setReason(e.target.value);
              }}
              onBlur={(e) => {
                setReason(e.target.value);
              }}
              name="bio"
              label="Please provide cancellation reason"
              fullWidth
              autoComplete="none"
              type="text"
              value={reason}
              multiline
              rows={2}
            />
          </Box>)
          }
          <Box display={'flex'} gap={1} justifyContent={'end'}>
          <Button
              variant="outlined"
              sx={{
                color: "#c05942",
                backgroundColor: "white",
                padding: "4px",
                fontSize: "12px",
                opacity: 0.9,
                border: "1px solid #c05942",
                ":hover": {
                  bgcolor: "white", // theme.palette.primary.main
                  color: "#c05942",
                  opacity: 1,
                  border: "1px solid #c05942",
                },
              }}
              onClick={onClose}
            >
              <Typography fontSize={14} textTransform={"none"}>Cancel</Typography>
            </Button>
          <Button
              variant="outlined"
              sx={{
                color: "#008080",
                backgroundColor: "white",
                padding: "4px",
                fontSize: "12px",
                opacity: 0.9,
                border: "1px solid #008080",
                ":hover": {
                  bgcolor: "white", // theme.palette.primary.main
                  color: "#008080",
                  opacity: 1,
                  border: "1px solid #008080",
                },
              }}
              onClick={() => {
                onConfirm(reason);
              }}
              disabled={askForReason ? (reason ? false : true) : false}
            >
              <Typography fontSize={14} textTransform={"none"}>Confirm</Typography>
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
