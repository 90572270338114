import { gql } from "@apollo/client";

export const GET_ALL_COMMUNITIES = gql`
    query GetAllCommunities {
        getAllCommunity {
            id
            name
            description
            isActive
            category
            isPrivate
            address
            coordinates
            image
            createdBy
            createdDate
            deletedBy
            joinedMembers
            organizedBy {
                id
                firstName
                lastName
            }
            event {
                id
                title
                image
            }
        }
    }
`;

export const GET_COMMUNITIES = gql`
    query GetCommunities {
        getCommunity {
            id
            name
            description
            isActive
            category
            address
            coordinates
            isPrivate
            image
            createdBy
            createdDate
            deletedBy
            joinedMembers
            organizedBy {
                id
                firstName
                lastName
            }
            event {
                id
                title
                image
            }
        }
    }
`;

export const GET_COMMUNITY_BY_ID = gql`
    query GetCommunityById($id: ID) {
        getCommunityById(id: $id) {
            id
            name
            description
            isActive
            category
            isPrivate
            image
            address
            coordinates
            createdBy
            deletedBy
            joinedMembers
            organizedBy {
                id
                firstName
                lastName
            }
            event {
                id
                title
                image
            }
        }
    }
`;

export const GET_COMMUNITY_BY_USER_ID = gql`
    query GetCommunityByUserId($userId: ID) {
        getCommunityByUserId(userId: $userId) {
            id
            name
            description
            isActive
            category
            isPrivate
            image
            address
            coordinates
            joinedMembers
            createdDate
            deletedBy
            organizedBy {
                id
                firstName
                lastName
            }
            event {
                id
                title
                image
            }
        }
    }
`;

export const CREATE_COMMUNITY = gql`
    mutation CreateCommunity($communityInput: CommunityInput) {
        createCommunity(communityInput: $communityInput) {
            id
            isSuccess
            error
        }
    }
`;

export const JOIN_COMMUNITY = gql`
    mutation CreateUserCommunity($userCommunityInput: UserCommunityInput) {
        createUserCommunity(userCommunityInput: $userCommunityInput) {
            id
            isSuccess
            error
        }
    }
`;

export const GET_COMMUNITY_JOINED_BY_USER_ID = gql`
    query GetCommunityJoinedByUserId($userId: ID) {
        getCommunityJoinedByUserId(userId: $userId) {
            id
            joinedDate
            community {
                id
                name
                image
                createdDate
                category
            deletedBy
                organizedBy {
                id
                firstName
                lastName
            }
            event {
                id
                title
                image
            }
        }
        }
    }
`;

export const DELETE_USER_COMMUNITY = gql`
    mutation DeleteUserCommunity($id: ID!) {
        deleteUserCommunity(id: $id) {
            id
            isSuccess
            error
        }
    }
`;

export const GET_USER_COMMUNITY_BY_ID = gql`
    query GetUserCommunityById($id: ID) {
        getUserCommunityById(id: $id) {
            id
            joinedDate
            community {
                id
                name
                image
                createdDate
                organizedBy {
                id
                firstName
                lastName
            }
            event {
                id
                title
                image
            }
        }
            user {
                email
                phone
                userInfo {
                firstName
                lastName
            }
            }
        }
    }
`;


export const DELETE_COMMUNITY = gql`
    mutation DeleteCommunity($deleteCommunityInput: DeleteCommunityInput) {
        deleteCommunity(deleteCommunityInput: $deleteCommunityInput) {
            id
            isSuccess
            error
        }
    }
`;

export const GET_NEARBY_COMMUNITIES = gql`
    query GetNearbyCommunities($nearbyCommunitiesInput: NearbyCommunitiesInput!) {
        getNearbyCommunities(nearbyCommunitiesInput: $nearbyCommunitiesInput) {
            id
            name
            description
            isActive
            category
            address
            coordinates
            isPrivate
            image
            createdBy
            createdDate
            deletedBy
            joinedMembers
            organizedBy {
                id
                firstName
                lastName
            }
            event {
                id
                title
                image
            }
            distance
        }
    }
    `;

export const GET_ONLINE_COMMUNITIES = gql`
query GetOnlineCommunities {
    getOnlineCommunities {
        id
        name
        description
        isActive
        category
        address
        coordinates
        isPrivate
        image
        createdBy
        createdDate
        deletedBy
        joinedMembers
        organizedBy {
            id
            firstName
            lastName
        }
        event {
            id
            title
            image
        }
    }
}
`;