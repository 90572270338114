import { useEffect, useState, SyntheticEvent } from "react";
import { Box, Typography, Paper, Tabs, Tab, Chip } from "@mui/material";
import {
  About,
  BookedEvents,
  JoinedCommunities,
  Reviews,
  CreatedEventsPopup,
  CreatedCommunitiesPopup,
  FavouriteEvents,
} from "../../components/profile";
import { UserAvatar } from "../../components/user-avatar";
import { useQuery } from "@apollo/client";
import { useAuth } from "../../contexts/AuthContext";
import {
  GET_EVENT_BY_USER_ID,
} from "../../gql/events";
import {
  GET_COMMUNITY_BY_USER_ID,
} from "../../gql/communities";
import { useNavigate } from "react-router-dom";
import { GET_USER_TAG } from "../../gql/tags";
import { GET_USER_CONNECTION } from "../../gql/user-connection";

export const Profile = () => {
  const navigate = useNavigate();
  //const imageUrls = ["url-to-image-1", "url-to-image-2", "url-to-image-3"];
  const { currentUser, loading } = useAuth();

  useEffect(() => {
    if (!loading && !currentUser) navigate("/login?returnUrl=/profile");
  }, [loading, currentUser, navigate]);
  const { data: createdEventsData } = useQuery(GET_EVENT_BY_USER_ID, {
    variables: {
      userId: currentUser?.profileInfo?.id,
    },
  });
  const { data: createdCommunitiesData } = useQuery(GET_COMMUNITY_BY_USER_ID, {
    variables: {
      userId: currentUser?.profileInfo?.id,
    },
  });
  const [value, setValue] = useState("one");

  const [openCreatedEventsPopup, setOpenCreatedEventsPopup] = useState(false);
  const [openCreatedCommunitiesPopup, setOpenCreatedCommunitiesPopup] =
    useState(false);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [tags, setTags] = useState<any>([]);

  const { data: tagsData } = useQuery(GET_USER_TAG, {
    variables: {
      userId: currentUser?.profileInfo?.id,
    },
  });

  const { data: connectionsData } = useQuery(GET_USER_CONNECTION, {
    variables: {
      userId: currentUser?.profileInfo?.id,
    },
  });

  const userTags = tagsData?.getUserTag;
  const userConnections = connectionsData?.getUserConnection;

  useEffect(() => {
    const userTagsArr = [];
    if (userTags && userTags.length > 0) {
      for (let i = 0; i < userTags.length; i++) {
        userTagsArr.push(userTags[i].tag);
      }
    }
    const filteredUserTags = userTagsArr?.filter(
      (tag: String, index: number, inputArray: any) =>
        tag !== "Marketplace" &&
        inputArray.indexOf(tag) === index &&
        tag != null
    );
    setTags(filteredUserTags);
  }, [userTags]);

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#008080",
          borderBottomLeftRadius: 60,
          borderBottomRightRadius: 60,
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          paddingBottom: { xs: 4, sm: 6 },
          paddingTop: { xs: 2, sm: 6 },
          gap: { xs: 2, sm: 1 },
        }}
        //px={"5%"}
      >
        <Box sx={styled.flex} px={2} gap={2}>
          <UserAvatar />
          {currentUser?.isAdmin ? (
            <Chip
              label="ADMIN"
              sx={{ backgroundColor: "#c05942", color: "white" }}
            />
          ) : currentUser?.isHost ? (
            <Chip
              label="HOST"
              sx={{ backgroundColor: "#c05942", color: "white" }}
            />
          ) : (
            <Chip
              label="Become a Host"
              sx={{
                backgroundColor: "#c05942",
                color: "white",
                opacity: 0.9,
                cursor: "pointer",
                ":hover": {
                  backgroundColor: "#c05942",
                  opacity: 1,
                },
              }}
              onClick={() => navigate("/become-a-host?returnUrl=/profile")}
            />
          )}
        </Box>

        <Box sx={styled.flex} pr={2}>
          <Paper
            sx={{
              ...styled.commonPaperStyles,
              backgroundColor: "white",
              cursor: "pointer",
            }}
            onClick={() => navigate("/connections")}
          >
            <Typography
              variant="h6"
              sx={{ color: "#008080", ...styled.commonTypographyStyles }}
            >
              {userConnections?.filter((item: any) => item.status === 'APPROVED').length ?? 0}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#008080", fontSize: 12, fontWeight: "medium" }}
            >
              Connection(s)
            </Typography>
          </Paper>
          {currentUser?.isHost && (
            <>
              {createdEventsData && createdEventsData?.getEventByUserId && (
                <Paper
                  sx={{
                    ...styled.commonPaperStyles,
                    backgroundColor: "rgba(187, 220, 220, 0.45)",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenCreatedEventsPopup(true)}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "white", ...styled.commonTypographyStyles }}
                  >
                    {createdEventsData?.getEventByUserId.length}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "white", fontSize: 12, fontWeight: "medium" }}
                  >
                    Event(s)
                  </Typography>
                  {/* <Typography
                  variant="body2"
                  sx={{ color: "white", fontSize: 12, fontWeight: "medium" }}
                >
                  Created
                </Typography> */}
                </Paper>
              )}
              {createdCommunitiesData &&
                createdCommunitiesData?.getCommunityByUserId && (
                  <Paper
                    sx={{
                      ...styled.commonPaperStyles,
                      backgroundColor: "#c05942",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenCreatedCommunitiesPopup(true)}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "white",
                        ...styled.commonTypographyStyles,
                      }}
                    >
                      {createdCommunitiesData?.getCommunityByUserId.length}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "white",
                        fontSize: 12,
                        fontWeight: "medium",
                      }}
                    >
                      Community(s)
                    </Typography>
                    {/* <Typography
                    variant="body2"
                    sx={{
                      color: "#008080",
                      fontSize: 12,
                      fontWeight: "medium",
                    }}
                  >
                    Created
                  </Typography> */}
                  </Paper>
                )}
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          paddingX: { xs: 2, sm: 5, md: 10 },
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            "& .MuiTab-root.Mui-selected": { color: "#008080" },
            "& .MuiTab-root": { minWidth: 60, padding: "5px 10px" },
            "& .MuiTabs-flexContainer": { justifyContent: "space-between" },
          }}
          variant="scrollable"
        >
          <Tab value="one" label="About" style={{ textTransform: "none" }} />
          <Tab
            value="two"
            label="My Bookings"
            style={{ textTransform: "none" }}
          />
          <Tab
            value="three"
            label="My Communities"
            style={{ textTransform: "none" }}
          />
          <Tab
            value="four"
            label="Favourites"
            style={{ textTransform: "none" }}
          />
          <Tab value="five" label="Reviews" style={{ textTransform: "none" }} />
        </Tabs>
        {value === "one" && <About tags={tags} />}
        {value === "two" && <BookedEvents />}
        {value === "three" && <JoinedCommunities />}
        {value === "four" && <FavouriteEvents />}
        {value === "five" && <Reviews />}
      </Box>
      {createdEventsData && createdEventsData?.getEventByUserId && (
        <CreatedEventsPopup
          open={openCreatedEventsPopup}
          onClose={() => setOpenCreatedEventsPopup(false)}
          createdEventsData={createdEventsData?.getEventByUserId}
        />
      )}
      {createdCommunitiesData &&
        createdCommunitiesData?.getCommunityByUserId && (
          <CreatedCommunitiesPopup
            open={openCreatedCommunitiesPopup}
            onClose={() => setOpenCreatedCommunitiesPopup(false)}
            createdCommunitiesData={
              createdCommunitiesData?.getCommunityByUserId
            }
          />
        )}
    </Box>
  );
};

const styled = {
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  commonPaperStyles: {
    borderRadius: "6.5px",
    padding: "10px",
    marginLeft: "20px",
    textAlign: "center",
    width: "100px",
  },
  commonTypographyStyles: {
    fontSize: 18,
    fontWeight: "semibold",
  },
};
