import { Link, useNavigate } from "react-router-dom";
import { GET_NEARBY_EVENTS } from "../../gql/events";
import { GET_NEARBY_COMMUNITIES } from "../../gql/communities";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { EventCarousel } from "../../components/event-carousel";
import { CommunityCarousel } from "../../components/community-carousel";
import EventIcon from "@mui/icons-material/Event";
import GroupsIcon from "@mui/icons-material/Groups";
import SecurityIcon from "@mui/icons-material/Security";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import EditRoadIcon from "@mui/icons-material/EditRoad";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import Diversity2Icon from '@mui/icons-material/Diversity2';import { Teaser } from '../../components/teaser';
import { useAuth } from "../../contexts/AuthContext";
import { TagInterceptor } from "../../components/tag-interceptor";
import { useContext, useState } from "react";
import { GET_PEOPLE_NEAR_YOU } from '../../gql/user-connection';
import { useEffect } from 'react';
import { NearbyPeopleCarousel } from "../../components/nearby-people-carousel";
import { LocationContext } from "../../contexts/LocationContext";

const actions = [
  { icon: <Link to="/create-event"><EventAvailableOutlinedIcon /></Link>, name: 'Event', route: '/create-event' },
  { icon:<Link to="/create-community"><Diversity2Icon /></Link>, name: 'Community', route: '/create-community' },
];

export const Home = () => {
  const { currentLocation } = useContext(LocationContext);
  const { data: eventsData } =
  useQuery(GET_NEARBY_EVENTS, {
    variables: {
      nearbyEventsInput: {
        lat: currentLocation.lat,
        lng: currentLocation.lng,
      },
    },
  });
  const {
    data: communitiesData,
  } = useQuery(GET_NEARBY_COMMUNITIES, {
    variables: {
      nearbyCommunitiesInput: {
        lat: currentLocation.lat,
        lng: currentLocation.lng,
      },
    },
  });

  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [distance, setDistance] = useState<number>(10);
  const [nearbyUsers, setNearbyUsers] = useState<any>([]);
  const [getUsersNearYou, { loading }] = useLazyQuery(GET_PEOPLE_NEAR_YOU);


  useEffect(() => {
    if (currentUser) {
      getUsersNearYou({
      variables: {
        getUserBasedonTagsInput: {
          id: currentUser?.profileInfo?.id,
          distance,
        },
      },
      onCompleted: (data: any) => {
        if (data && data?.getUserBasedonTags && data?.getUserBasedonTags?.length > 0) {
          setNearbyUsers(data?.getUserBasedonTags);
        } else if (distance < 100) {
          setDistance(distance + 10);
        }
      },
    });
  }
  }, [currentUser, distance, getUsersNearYou])
 

  return (
    <Box style={{ backgroundColor: "white", paddingBottom: 64 }}>
      {currentUser && <TagInterceptor />}
      <Teaser />
      <Box
        sx={{
          paddingX: { xs: 2, md: 5, lg: 20 },
          paddingY: { xs: 1, md: 2, lg: 5 },
        }}
      >
        <Box>
          <Box sx={{ textAlign: "center", marginTop: 1 }}>
            {/* <Typography variant="h4" fontSize={28}>
              HobbyHopp is not just a platform—it's a gateway to exciting
              experiences, meaningful connections, and vibrant communities right
              in their own backyard
            </Typography> */}
            {/* <Typography variant="h2" fontSize={32} fontWeight={'bold'} letterSpacing={2}>
              HOBBYHOPP
            </Typography> */}
            <Box
                component="img"
                src="/logo.svg"
                alt="HobbyHopp"
                sx={{ width: 200, height: 60 }}
              ></Box>
             <Typography variant="h4" fontSize={22} color={"#717171"} mt={1} fontWeight={'bold'} letterSpacing={1}>
              The Offline Social Media
            </Typography>
          </Box>
          <Box>
              <Box component='img' src={require('../../images/home-banner.JPG')} height={100} width='100%' style={{ objectFit: 'contain' }} />
          </Box>
          <Box sx={{ textAlign: "center" }}>
            {/* <Typography variant="h5" fontSize={20} color="#888491">
              Find like minded people near you.
              <br />
              Explore different Hobbies together by finding different events and
              forming the real communities. <br />
              Discover the vibe of your mohalla.
            </Typography> */}
            <Box>
          <Box sx={{ textAlign: "center", backgroundColor: '#008080', borderRadius: 0 }}>
            <Typography style={styled.points}>
              Find events happening in & around your neighbourhood!
            </Typography>
            <Typography style={styled.points}>Host your events and start inviting people!</Typography>
            <Typography style={styled.points}>
              Join local communities to find your tribe!
            </Typography>
          </Box>
        </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          paddingX: { xs: 2, md: 5, lg: 20 },
          paddingY: { xs: 2, md: 2, lg: 5 },
          gap: 2,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box>
          <Box sx={styled.flex}>
            <Box>
              <Typography variant="h5" fontWeight={600} fontSize={20}>
                People Near You
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="button"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/connections")}
                fontSize={12}
              >
                See All
              </Typography>
            </Box>
          </Box>

          {loading ? (
            <>
              <Box textAlign={'center'}>
                <img src="/radar.gif" alt="Loading..." height={200} />
              </Box>
            </>
          ) : (
            <>
              {nearbyUsers && nearbyUsers.length > 0 ? (
                <Box display={"flex"} flexDirection={"row"} gap={2}>
                  <NearbyPeopleCarousel
                    nearbyPeople={nearbyUsers}
                  ></NearbyPeopleCarousel>
                </Box>
              ) : (
                <Box textAlign={"center"}>
                  <Typography variant="h5" fontSize={16}>
                    No nearby users found.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
        {eventsData && (
          <Box>
            <Box sx={styled.flex}>
              <Box>
                <Typography variant="h5" fontWeight={600} fontSize={20}>
                  Events Near You
                </Typography>
              </Box>
              {/* <Box>
                <Typography
                  variant="button"
                  style={{ cursor: "pointer" }}
                  onClick={() => goToSpecificEventPage("trending")}
                  fontSize={12}
                >
                  See All
                </Typography>
              </Box> */}
            </Box>
            <Box>
              <EventCarousel events={eventsData.getNearbyEvents}></EventCarousel>
            </Box>
          </Box>
        )}
        {communitiesData && (
          <Box>
            <Box sx={styled.flex}>
              <Box>
                <Typography variant="h5" fontWeight={600} fontSize={20}>
                  Communities Near You
                </Typography>
              </Box>
              {/* <Box>
                <Typography
                  variant="button"
                  style={{ cursor: "pointer" }}
                  onClick={() => goToSpecificCommunityPage("trending")}
                  fontSize={12}
                >
                  See All
                </Typography>
              </Box> */}
            </Box>
            <Box>
              <CommunityCarousel
                communities={communitiesData.getNearbyCommunities}
              ></CommunityCarousel>
            </Box>
          </Box>
        )}
      </Box>
      {/* <Box
        sx={{
          paddingX: { xs: 2, md: 5, lg: 20 },
          paddingY: { xs: 1, md: 2, lg: 5 },
        }}
      >
        <Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4">Be part of a</Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4" fontWeight={1000}>
              Real Community
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }} mt={5}>
            <Typography variant="h5" fontSize={20} color="#888491">
              HobbyHopp is made up of many different communities.These allow you
              to meet people with the same tastes and desires!
            </Typography>
          </Box>
        </Box>
        <Box mt={3}>
          <CommunityTypeCarousel
            categories={categoriesData?.getCategories}
          ></CommunityTypeCarousel>
        </Box>
      </Box> */}
      <Box
        sx={{
          paddingX: { xs: 2, md: 5, lg: 20 },
          paddingY: { xs: 1, md: 2, lg: 5 },
        }}
      >
        <Box>
          <Box sx={{ textAlign: "center", backgroundColor: '#008080', borderRadius: 0 }}>
            <Typography style={styled.points}>
              Find like minded people with ease
            </Typography>
            <Typography style={styled.points}>Explore different Hobbies</Typography>
            <Typography style={styled.points}>
              Join or form your own hyper localised community
            </Typography>
            <Typography style={styled.points}>
              Discover the vibe of your neighbourhood
            </Typography>
          </Box>
        </Box>
        <Box
          m={3}
          sx={[styled.flex, { flexDirection: { xs: "column", sm: "row" } }]}
          textAlign={"center"}
        >
          <Box>
            <EventIcon
              style={{ color: "#008080", width: 40, height: 40 }}
            ></EventIcon>
            <Typography variant="h4" fontSize={16} fontWeight={600}>
              Events
            </Typography>
            <Typography variant="h5" fontSize={14} color="#888491">
              Easy-to-use features to create and manage your events.
            </Typography>
          </Box>
          <Box>
            <GroupsIcon
              style={{ color: "#008080", width: 40, height: 40 }}
            ></GroupsIcon>
            <Typography variant="h4" fontSize={16} fontWeight={600}>
              Communities
            </Typography>
            <Typography variant="h5" fontSize={14} color="#888491">
              Track your connections by creating or joining a community.
            </Typography>
          </Box>
          <Box>
            <SecurityIcon
              style={{ color: "#008080", width: 40, height: 40 }}
            ></SecurityIcon>
            <Typography variant="h4" fontSize={16} fontWeight={600}>
              Security & Support
            </Typography>
            <Typography variant="h5" fontSize={14} color="#888491">
              Secure data and payment processing backed by a team eager to see
              you succeed.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          paddingX: { xs: 2, md: 5, lg: 20 },
          paddingY: { xs: 1, md: 2, lg: 5 },
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h4">
            Be a Star Event Host in 3 Easy Steps
          </Typography>
        </Box>
        <Box
          sx={{ display: { xs: "block", sm: "flex" }, sm: styled.flex }}
          textAlign={"center"}
          mt={5}
          color={"white"}
        >
          <Box
            sx={{
              backgroundColor: "#008080",
              width: "100%",
              borderBottom: "1px solid white",
            }}
            p={3}
          >
            <Typography variant="h4" fontSize={24}>
              Step 01
            </Typography>
            <Typography variant="h5" fontSize={18}>
              Connect With People
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#008080",
              width: "100%",
              borderBottom: "1px solid white",
            }}
            p={3}
          >
            <Typography variant="h4" fontSize={24}>
              Step 02
            </Typography>
            <Typography variant="h5" fontSize={18}>
              Host Your Event{" "}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#008080",
              width: "100%",
              borderBottom: "1px solid white",
            }}
            p={3}
          >
            <Typography variant="h4" fontSize={24}>
              Step 03
            </Typography>
            <Typography variant="h5" fontSize={18}>
              Repeat and Grow
            </Typography>
          </Box>
        </Box>
        <Box mt={2}>
          <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
            <Typography variant="h6" fontSize={16}>
              Sign up for free and create your event easily in minutes
            </Typography>
          </Box>
          <Box
            sx={{ display: { xs: "block", sm: "flex" }, sm: styled.flex }}
            textAlign={"center"}
            mt={2}
            pl={7}
            pr={7}
            color={"white"}
            gap={4}
          >
            <Box sx={{ backgroundColor: "white", width: "100%" }} p={3} mb={2}>
              <RememberMeIcon
                style={{ color: "#008080", width: 40, height: 40 }}
              ></RememberMeIcon>
              <Typography variant="h4" fontSize={16} fontWeight={600}>
                Sign up for free
              </Typography>
              <Typography variant="h5" fontSize={14} color="#888491">
                Sign up easily using your Phone or Google account and create
                your events in minutes.
              </Typography>
            </Box>
            <Box sx={{ backgroundColor: "white", width: "100%" }} p={3} mb={2}>
              <EditRoadIcon
                style={{ color: "#008080", width: 40, height: 40 }}
              ></EditRoadIcon>
              <Typography variant="h4" fontSize={16} fontWeight={600}>
                Customise your event page as you like{" "}
              </Typography>
              <Typography variant="h5" fontSize={14} color="#888491">
                Add logo, collage hero images, and add details to create an
                outstanding event page.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          paddingX: { xs: 2, md: 5, lg: 20 },
          paddingY: { xs: 1, md: 2, lg: 5 },
        }}
      >
        <Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4">
              Transforming Thousands of Event Hosts Just Like You{" "}
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }} my={5}>
            <Typography variant="h5" fontSize={20} color="#888491">
              Platform to explore new hobbies by joining or hosting communities
              and events offline
            </Typography>
          </Box>
        </Box>
      </Box>
      <SpeedDial
        ariaLabel="create event or community"
        sx={[styled.floatinButton, { display: { xs: "none", sm: "flex" } }]}
        icon={<SpeedDialIcon />}
        color="primary"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
          />
        ))}
      </SpeedDial>
    </Box>
  );
};

const styled = {
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  floatinButton: {
    right: '20px',
    bottom: '20px',
    position: 'fixed',
  },
  points: {
    borderBottom: '1px solid white',
    color: 'white',
    fontSize: 16,
    padding: 12,
  }
};
