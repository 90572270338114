import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { CommunityCard } from "../community-card";
import { FC } from "react";

interface CommunityCarouselProps {
  communities: any;
};

export const CommunityCarousel: FC<CommunityCarouselProps> = ({ communities }) => {
  return (
    <ImageList
    className="event-community-carousel"
      sx={{
        gridAutoFlow: "column",
        gridTemplateColumns: { xs: "repeat(auto-fit, minmax(80%, 2fr)) !important", sm: "repeat(auto-fit, minmax(44%, 2fr)) !important", lg: "repeat(auto-fit, minmax(28%, 2fr)) !important" },
        gridAutoColumns: { xs: "minmax(80%, 2fr)", sm: "minmax(44%, 2fr)", lg: "minmax(28%, 2fr)"},
        overflowX: "auto",
        paddingBottom: 2
      }}
    >
      {communities.map((item: any, index: number) => (
        <ImageListItem key={index} sx={{paddingRight: 1 }}>
          <CommunityCard community={item}></CommunityCard>
        </ImageListItem>
      ))}
    </ImageList>
  );
}
