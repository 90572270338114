import { Modal, Box, Typography } from "@mui/material";
import { FC } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import { GoogleMapComponent } from "../google-map";

export const Location: FC<any> = ({ location, open, handleClose, onLocationSelected, currentAddress }) => {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: { xs: "10%", sm: "20%" },
          left: { xs: "10%", sm: "20%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          width: { xs: "80%", sm: "60%" },
          height: { xs: "80%", sm: "60%" },
          overflow: "scroll",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#008080",
          }}
          p={2}
        >
          <Typography color={"white"}>Select Location</Typography>
          <CloseIcon
            style={{ color: "white", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>
        <Box p={1} textAlign={'center'}>
          <GoogleMapComponent location={location} onLocationSelected={onLocationSelected} onClose={handleClose} currentAddress={currentAddress} /> 
        </Box>
      </Box>
    </Modal>
  );
};

