import {
  Typography,
  Box,
  MenuItem,
  Select,
  InputLabel,
  Button,
  FormControl,
} from "@mui/material";
import { EventCarousel } from "../../components/event-carousel";
import { GET_NEARBY_EVENTS } from "../../gql/events";
import { useQuery } from "@apollo/client";
import { Spinner } from "../../components/spinner";
import { useState, useEffect, useContext } from "react";
import { GET_CATEGORIES } from "../../gql/categories";
import { LocationContext } from "../../contexts/LocationContext";

export const Event = () => {
  const { currentLocation } = useContext(LocationContext);
  const distanceArr = [5, 20, 50, 100];
  const [selectedDistance, setSelectedDistance] = useState<string | number>();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [events, setEvents] = useState([]);
  const { data: eventsData, loading: nearbyEventsLoading } =
    useQuery(GET_NEARBY_EVENTS, {
      variables: {
        nearbyEventsInput: {
          lat: currentLocation.lat,
          lng: currentLocation.lng,
        },
      },
    });

  const { data: categoriesData } = useQuery(GET_CATEGORIES);

  useEffect(() => {
    if (eventsData && eventsData.getNearbyEvents && eventsData.getNearbyEvents.length > 0) {
      setEvents(eventsData.getNearbyEvents);
    }
  }, [eventsData]) 

  const filterEvents = () => {
    let filteredEvents = eventsData.getNearbyEvents;
    if (selectedCategory) {
      filteredEvents = filteredEvents?.filter(
        (event: any) => event.category === selectedCategory
      );
    }
    if (selectedDistance) {
      filteredEvents = filteredEvents?.filter(
        (event: any) =>
          selectedDistance >= event.distance
      );
    }
    setEvents(filteredEvents);
  };

  if (nearbyEventsLoading) {
    return (
      <Box mt={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box style={{ backgroundColor: "#F5F5F5", paddingBottom: 64 }}>
      {categoriesData && categoriesData?.getCategories && (
        <Box
          p="10% 20%"
          sx={{
            backgroundImage: `url("/images/bg.png")`,
            backgroundSize: "cover",
          }}
        >
          <Box textAlign={"center"}>
            <Box mb={2}>
              <Typography component={"span"} fontWeight={"bold"} fontSize={24}>
                Discover Events For All The Things You Love
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <FormControl size="small" sx={{ width: "100%" }}>
                <InputLabel id="distance-select-label">Distance</InputLabel>
                <Select
                  labelId="distance-select-label"
                  value={selectedDistance}
                  onChange={(e) => {
                    setSelectedDistance(e.target.value);
                  }}
                  style={{ backgroundColor: "#f5f5f5" }}
                  label="Distance"
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {distanceArr?.map((distance: any, index: number) => {
                    return (
                      <MenuItem key={index} value={distance}>
                        {distance}KM
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ width: "100%" }}>
                <InputLabel id="category-select-label">Category</InputLabel>
                <Select
                  labelId="category-select-label"
                  value={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                  }}
                  style={{ backgroundColor: "#f5f5f5" }}
                  label="Category"
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {categoriesData?.getCategories?.map(
                    (category: any, index: number) => {
                      return (
                        <MenuItem key={index} value={category.name}>
                          {category.name}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
              <Box>
                <Button
                  sx={{
                    width: "100%",
                    color: "white",
                    backgroundColor: "#008080",
                    padding: "8px 16px",
                    fontSize: "12px",
                    opacity: 0.9,
                    ":hover": {
                      bgcolor: "#008080", // theme.palette.primary.main
                      color: "white",
                      opacity: 1,
                    },
                    "&.Mui-disabled": {
                      color: "#d5d5d5",
                    },
                  }}
                  onClick={filterEvents}
                >
                  <Typography
                    component="span"
                    textTransform={"none"}
                    fontSize={16}
                  >
                    Search
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {!nearbyEventsLoading && (
        <Box
          sx={{
            paddingX: { xs: 2, md: 5, lg: 20 },
            paddingY: { xs: 4, md: 2, lg: 5 },
          }}
        >
        <Box>
          <Typography
            variant="h5"
            fontWeight={600}
            fontSize={20}
            color={"#008080"}
          >
            Events Near You
          </Typography>
        </Box>
          {events && events.length > 0 ? (
            <Box>
              <Box>
                <EventCarousel events={events}></EventCarousel>
              </Box>
            </Box>
          ) : (
            <Box textAlign={"center"} pt={5}>
              <Typography
                variant="h5"
                fontWeight={600}
                fontSize={20}
                color={"#008080"}
              >
                No events found.
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
