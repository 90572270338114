import { useState } from "react";
import { categories, indianStates } from "./data";

const PostContactForm = async (
  values: any,
  successCallback: any,
  errorCallback: any
) => {
  // store contact information in DB
  // if successful
  console.log(values);
  if (true) return successCallback();
  else return errorCallback();
};

const initialFormValues = {
  image: "",
  name: "",
  category: categories[0],
  capacity: 0,
  date: "",
  time: "10:00 AM",
  duration: 1,
  description: "",
  price: 0,
  venue: "",
  address: "",
  formSubmitted: false,
  success: false,
};

export const useFormControls = () => {
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({} as any);

  const validate: any = (fieldValues = values) => {
    let temp: any = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "Please provide event name.";

    if ("image" in fieldValues)
      temp.image = fieldValues.image ? "" : "Please upload event image.";

    if ("capacity" in fieldValues)
      temp.capacity =
        fieldValues.capacity && fieldValues.capacity >= 1
          ? ""
          : "Please provide event capacity.";

    if ("price" in fieldValues)
      temp.price =
        fieldValues.price && fieldValues.price >= 0
          ? ""
          : "Please provide event fee.";

    if ("date" in fieldValues)
      temp.date = fieldValues.date ? "" : "Please provide event date.";

    if ("time" in fieldValues)
      temp.time = fieldValues.time ? "" : "Please provide event time.";

    if ("duration" in fieldValues)
      temp.duration = fieldValues.duration && fieldValues.duration >= 1
        ? ""
        : "Please provide event duration.";

    if ("description" in fieldValues)
      temp.description = fieldValues.description
        ? ""
        : "Please provide event description.";

    if ("venue" in fieldValues)
      temp.venue = fieldValues.venue ? "" : "Please provide event venue.";

    if ("address" in fieldValues)
      temp.address = fieldValues.address
        ? ""
        : "Event address is required.";

    if ("address2" in fieldValues)
      temp.address2 = fieldValues.address2
        ? ""
        : "Event address line 2 is required.";

    if ("country" in fieldValues)
      temp.country = fieldValues.country ? "" : "Please select country.";

    if ("state" in fieldValues)
      temp.state = fieldValues.state ? "" : "Please select state.";

    if ("city" in fieldValues)
      temp.city = fieldValues.city ? "" : "Please select city.";

    setErrors({
      ...temp,
    });
  };

  const handleInputValue = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSuccess = () => {
    setValues({
      ...initialFormValues,
      formSubmitted: true,
      success: true,
    });
    return {
      isSubmitted: true,
      message: "Your event is successfully created!",
    };
  };

  const handleError = () => {
    setValues({
      ...initialFormValues,
      formSubmitted: true,
      success: false,
    });
    return {
      isSubmitted: false,
      message: "We are facing some issue. Please try again after sometime.",
    };
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.image &&
      fieldValues.name &&
      fieldValues.capacity &&
      //fieldValues.price &&
      fieldValues.duration &&
      //fieldValues.date &&
      fieldValues.description &&
      fieldValues.venue &&
      fieldValues.address
      // fieldValues.address2 &&
      // fieldValues.country &&
      // fieldValues.state &&
      // fieldValues.city &&
      // fieldValues.postalCode;

    return isValid;
  };

  const setInitialValues = (values: any) => {
    setValues(values);
  }

  const handleFormSubmit = async (e: any): Promise<any> => {
    e.preventDefault();
    if (formIsValid()) {
      //const result = await PostContactForm(values, handleSuccess, handleError);
      return values;
    }
  };

  return {
    values,
    errors,
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    setInitialValues,
  };
};
