import { useState } from "react";
import { categories } from "./data";

const PostCreateCommunityForm = async (
  values: any,
  successCallback: any,
  errorCallback: any
) => {
  // store contact information in DB
  // if successful
  if (true) return successCallback();
  else return errorCallback();
};

const initialFormValues = {
  image: "",
  name: "",
  category: categories[0],
  description: "",
  isPrivate: false,
  formSubmitted: false,
  success: false
};

export const useFormControls = () => {
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({} as any);

  const validate: any = (fieldValues = values) => {
    let temp: any = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "Please provide community name.";

    if ("image" in fieldValues)
      temp.image = fieldValues.image ? "" : "Please upload community profile pic.";

    if ("description" in fieldValues)
      temp.description = fieldValues.description ? "" : "Please provide community description.";

    setErrors({
      ...temp
    });
  };

  const handleInputValue = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
    validate({ [name]: value });
  };

  const handleSuccess = () => {
    setValues({
      ...initialFormValues,
      formSubmitted: true,
      success: true
    });
    return { isSubmitted: true, message: 'Your event is successfully created!'};
  };

  const handleError = () => {
    setValues({
      ...initialFormValues,
      formSubmitted: true,
      success: false
    });
    return { isSubmitted: false, message: 'We are facing some issue. Please try again after sometime.'}
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.name &&
      fieldValues.image &&
      fieldValues.description

    return isValid;
  };

  const handleFormSubmit = async (e: any): Promise<any> => {
    e.preventDefault();
    if (formIsValid()) {
      //const result = await PostCreateCommunityForm(values, handleSuccess, handleError);
      return values;
    }
  };

  return {
    values,
    errors,
    handleInputValue,
    handleFormSubmit,
    formIsValid
  };
};
