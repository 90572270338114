import { Fragment, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import Diversity2OutlinedIcon from "@mui/icons-material/Diversity2Outlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import HubIcon from "@mui/icons-material/Hub";


const actions = [
  {
    icon: <EventAvailableOutlinedIcon />,
    name: "Event",
    route: "/create-event",
  },
  {
    icon: <Diversity2OutlinedIcon />,
    name: "Community",
    route: "/create-community",
  },
];

export const BottomBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentUser } = useAuth();
  const [speedDialOpen, setspeedDialOpen] = useState(false);

  const getIconColor = (route: string) => {
    if (pathname === route) return "#008080";
    else return "#888491";
  };

  useEffect(() => {
    setspeedDialOpen(false);
  }, [pathname]);

  return (
    <Fragment>
      <AppBar
        position="fixed"
        color="primary"
        sx={{ top: "auto", bottom: 0, display: { xs: "flex", sm: "none" } }}
      >
        <Toolbar
          sx={{ backgroundColor: "white", gap: "21px", paddingX: "18px" }}
        >
          <Box>
            <IconButton
              onClick={() => navigate("/")}
              sx={{ flexDirection: "column", color: getIconColor("/") }}
            >
              <CottageOutlinedIcon sx={{ fontSize: "20px" }} />
              <Typography fontSize={12}>Home</Typography>
            </IconButton>
          </Box>
          <Box>
            <IconButton
              onClick={() => navigate("/connections")}
              sx={{ flexDirection: "column", color: getIconColor("/connections") }}
            >
              <HubIcon sx={{ fontSize: "20px" }} />
              <Typography fontSize={12}>Connections</Typography>
            </IconButton>
          </Box>
          <Box>
            <SpeedDial
              ariaLabel="create event or community"
              icon={<SpeedDialIcon />}
              color="primary"
              sx={{ right: "44vw", bottom: "30px", position: "fixed" }}
              open={speedDialOpen}
              onOpen={() => setspeedDialOpen(true)}
              onClose={() => setspeedDialOpen(false)}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  tooltipOpen
                  onClick={() => {
                    setspeedDialOpen(false);
                    navigate(action.route);
                  }}
                />
              ))}
            </SpeedDial>
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          <Box>
            <IconButton
              onClick={() => navigate("/events")}
              sx={{ flexDirection: "column", color: getIconColor("/events") }}
            >
              <EventAvailableOutlinedIcon sx={{ fontSize: "20px" }} />
              <Typography fontSize={12}>Events</Typography>
            </IconButton>
          </Box>
          <Box>
            <IconButton
              onClick={() => navigate("/communities")}
              sx={{ flexDirection: "column", color: getIconColor("/communities") }}
            >
              <Diversity2OutlinedIcon sx={{ fontSize: "20px" }} />
              <Typography fontSize={12}>Communities</Typography>
            </IconButton>
          </Box>
          {/* <Box>
            {currentUser ? (
              <IconButton
                onClick={() => navigate("/profile")}
                sx={{ flexDirection: "column", color: getIconColor("/profile") }}
              >
                <Person2OutlinedIcon sx={{ fontSize: "20px" }} />
                <Typography fontSize={12}>Profile</Typography>
              </IconButton>
            ) : (
              <IconButton
                onClick={() => navigate("/login")}
                sx={{ flexDirection: "column", color: getIconColor("/login") }}
              >
                <LoginOutlinedIcon sx={{ fontSize: "20px" }} />
                <Typography fontSize={12}>Sign In</Typography>
              </IconButton>
            )}
          </Box> */}
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};
