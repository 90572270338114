export const inputFieldValues = [
    {
      name: "image",
      id: "image",
      label: "Upload community profile pic*",
      flex: "100%",
      isMedia: true,
      type: "file"
    },
    {
      name: "name",
      label: "Give your community a name*",
      id: "name",
      flex: "100%",
      placeholder: "Enter community name here",
      type: "text"
    },
    {
      name: "category",
      label: "Choose a category for your community",
      id: "category",
      flex: "32%",
      placeholder: "Select Category",
      isDropdown: true,
    },
    {
      name: "description",
      label: "Describe your community*",
      id: "description",
      multiline: true,
      rows: 5,
      flex: "100%",
      placeholder: "Description",
      type: "text"
    },
    {
      name: "address",
      label: "Provide community address",
      id: "address",
      flex: "100%",
      placeholder: "Select Address",
      type: "text",
      isAddress: true,
    },
  ]; 

export const categories = ['Education', 'Dance & Music', 'Arts & Crafts', 'Dating', 'Trekking & Hiking', 'Sports(Indoor/ Outdoor)', 'Charity', 'Meetups', 'Miscellaneous'];
