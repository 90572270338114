import { styled, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import Diversity2OutlinedIcon from "@mui/icons-material/Diversity2Outlined";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import Avatar from "@mui/material/Avatar";
import { UserAvatar } from "../user-avatar";
import { Search } from "../search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Location } from "../location";
import { Notification } from "../notification";
import { GET_NOTIFICATION_BY_USER_ID } from "../../gql/notifications";
import { useQuery } from "@apollo/client";
import { LocationContext } from "../../contexts/LocationContext";
import HubIcon from "@mui/icons-material/Hub";

const links = [
  { label: "Home", route: "/" },
  { label: "Connections", route: "/connections" },
  { label: "Events", route: "/events" },
  { label: "Communities", route: "/communities" },
  { label: "Create Event", route: "/create-event" },
  { label: "Create Community", route: "/create-community" },
  { label: "Contact us", route: "/contact-us" },
  // { label: "About Us", route: "/about-us" },
];

const mobileLinks = [
  {
    label: "Home",
    route: "/",
    icon: <CottageOutlinedIcon sx={{ fontSize: "18px", color: "white" }} />,
  },
  {
    label: "Connections",
    route: "/connections",
    icon: (
      <HubIcon sx={{ fontSize: "18px", color: "white" }} />
    ),
  },
  {
    label: "Events",
    route: "/events",
    icon: (
      <EventAvailableOutlinedIcon sx={{ fontSize: "18px", color: "white" }} />
    ),
  },
  {
    label: "Communities",
    route: "/communities",
    icon: <Diversity2OutlinedIcon sx={{ fontSize: "18px", color: "white" }} />,
  },
  {
    label: "Create Event",
    route: "/create-event",
    icon: (
      <EditCalendarOutlinedIcon sx={{ fontSize: "18px", color: "white" }} />
    ),
  },
  {
    label: "Create Community",
    route: "/create-community",
    icon: <GroupAddOutlinedIcon sx={{ fontSize: "18px", color: "white" }} />,
  },
  {
    label: "Contact us",
    route: "/contact-us",
    icon: <CallOutlinedIcon sx={{ fontSize: "18px", color: "white" }} />,
  },
  // {
  //   label: "About Us",
  //   route: "/about-us",
  //   icon: <InfoOutlinedIcon sx={{ fontSize: "18px", color: "white" }} />,
  // },
];

const useStyles = () => {
  makeStyles({
    drawer: {
      background: "#008080",
    },
  });
};

export const Header = () => {
  const location = useLocation();
  const {
    currentLocation,
    setCurrentLocation,
    currentAddress,
    setCurrentAddress,
  } = useContext(LocationContext);
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);

  const isProfileMenuOpen = Boolean(profileAnchorEl);
  const isMobileMenuOpen = Boolean(mobileMenuAnchorEl);
  const { pathname } = useLocation();

  const onLocationSelected = (coordinates: any, address?: string) => {
    console.log(coordinates);
    setCurrentLocation(coordinates);
    setCurrentAddress(address || "");
  };

  const { data: notificationsData, loading } = useQuery(
    GET_NOTIFICATION_BY_USER_ID,
    {
      variables: {
        userId: currentUser?.profileInfo?.id,
      },
    }
  );

  const useScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
      // if (pathname === '/events') setLogo('logo_without_hh');
      // else if (pathname === '/communities') setLogo('logo_with_hh');
      // else setLogo('logo');
    }, [pathname]);
  };
  useScrollToTop();

  const handleProfileMenuOpen = (event: any) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const logOut = async () => {
    await logout();
    localStorage.removeItem("accessToken");
    window.location.reload();
  };

  const profileMenuId = "profile-menu";
  const renderProfileMenu = (
    <Menu
      anchorEl={profileAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
      sx={{ marginTop: 4 }}
    >
      <MenuItem
        onClick={() => {
          setProfileAnchorEl(null);
          navigate("/profile");
        }}
      >
        Profile
      </MenuItem>
      <MenuItem
        onClick={() => {
          setProfileAnchorEl(null);
          logOut();
        }}
      >
        Sign Out
      </MenuItem>
    </Menu>
  );

  const mobileMenuList = () => {
    return (
      <Box
        role="presentation"
        onClick={handleMobileMenuClose}
        onKeyDown={handleMobileMenuClose}
      >
        <List>
          {currentUser && currentUser.profileInfo && (
            <Box>
              <ListItem key={"profile"} disablePadding>
                <ListItemButton
                  onClick={() => {
                    setMobileMenuAnchorEl(null);
                    navigate("/profile");
                  }}
                  style={{ borderBottom: "1px solid white" }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <Person2OutlinedIcon
                      sx={{ fontSize: "16px", color: "white" }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                      Profile
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </Box>
          )}
          {mobileLinks.map((link, index) => (
            <Box key={index}>
              <ListItem key={index} disablePadding>
                <ListItemButton
                  onClick={() => {
                    setMobileMenuAnchorEl(null);
                    navigate(link.route);
                  }}
                  style={{ borderBottom: "1px solid white" }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    {link.icon}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                      {link.label}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              {/* {index + 1 !== mobileLinks.length && <Divider />} */}
            </Box>
          ))}
          <Box>
            <ListItem key={"signin"} disablePadding>
              <ListItemButton
                onClick={() => {
                  setMobileMenuAnchorEl(null);
                  handleAuth();
                }}
              >
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  {currentUser ? (
                    <LoginOutlinedIcon
                      sx={{ fontSize: "16px", color: "white" }}
                    />
                  ) : (
                    <LogoutOutlinedIcon sx={{ fontSize: 16, color: "white" }} />
                  )}
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                    {currentUser ? "Sign Out" : "Sign In"}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            {/* {index + 1 !== mobileLinks.length && <Divider />} */}
          </Box>
        </List>
      </Box>
    );
  };

  const handleAuth = async () => {
    try {
      setMobileMenuAnchorEl(null);
      if (currentUser) {
        await logout();
        localStorage.removeItem("accessToken");
        window.location.reload();
      } else navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };
  const classes: any = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }} pb={7}>
      <AppBar>
        <Toolbar
          sx={{
            display: "grid",
            backgroundColor: "white",
            boxShadow: "0 2px 4px -1px rgba(57,76,96,.15)",
          }}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box display={"flex"} alignItems={"center"}>
              <Box sx={{ display: { xs: "block", sm: "none" } }}>
                <IconButton
                  size="large"
                  edge="start"
                  color="primary"
                  aria-label="open drawer"
                  sx={{
                    mr: 0,
                    display: { xs: "flex", sm: "none" },
                    alignItems: "center",
                  }}
                  onClick={handleMobileMenuOpen}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
              {/* <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: "contents", cursor: "pointer" }}
                color={"primary"}
                fontSize={"24px"}
                fontFamily={"fantasy"}
                letterSpacing={"0px"}
                onClick={() => navigate("/")}
              >
                HobbyHopp
              </Typography> */}
              {/* <img src={require('../../images/logo.png')} alt='HobbyHopp' /> */}
              <Box
                component="img"
                src="/logo.svg"
                alt="HobbyHopp"
                sx={{ cursor: "pointer", width: 120, height: 25 }}
                onClick={() => navigate("/")}
              ></Box>
              <Box sx={{ display: { xs: "none", md: "block" }, marginLeft: 2 }}>
                <Grid
                  container
                  spacing={4}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ display: "flex" }}
                >
                  <Grid item>
                    {links.map((link, index) => (
                      <Box
                        key={index}
                        sx={{
                          textAlign: "center",
                          display: "inline-block",
                        }}
                        pl={2}
                      >
                        <Typography
                        component={'span'}
                          onClick={() => navigate(link.route)}
                          sx={{
                            cursor: "pointer",
                            color: location.pathname === link.route ? '#008080' : 'rgb(13, 18, 22)',
                            textUnderlineOffset: "3px",
                            "&:hover": {
                              color : "#008080",
                            },
                          }}
                        >
                          {link.label}
                        </Typography>
                      </Box>
                    ))}
                    {!currentUser?.isHost && (
                      <Box
                        sx={{
                          textAlign: "center",
                          display: "inline-block",
                        }}
                        pl={2}
                      >
                        <span
                          onClick={() => navigate("/become-a-host")}
                          style={{
                            color: "rgb(13, 18, 22)",
                            cursor: "pointer",
                          }}
                        >
                          Become A Host
                        </span>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            {pathname !== "/login" && (
              <Box display={"flex"} alignItems={"center"} gap={2}>
                <SearchIcon
                  onClick={() => setSearchOpen(true)}
                  style={{ color: "#008080", cursor: "pointer" }}
                />
                <LocationOnIcon
                  onClick={() => setMapOpen(true)}
                  style={{ color: "#008080", cursor: "pointer" }}
                />
                {currentUser ? (
                  <Box display={"flex"} flexDirection={"row"}>
                    <IconButton
                      size="large"
                      color="primary"
                      onClick={() => setNotificationOpen(true)}
                    >
                      {notificationsData &&
                      notificationsData?.getNotificationByUserId &&
                      notificationsData?.getNotificationByUserId?.filter(
                        (notification: any) => !notification.isRead
                      ).length > 0 ? (
                        <Badge variant="dot" color="secondary">
                          <NotificationsIcon />
                        </Badge>
                      ) : (
                        <NotificationsIcon />
                      )}
                    </IconButton>
                    <IconButton
                      size="large"
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={profileMenuId}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="primary"
                      sx={{ display: { xs: "none", sm: "flex" } }}
                    >
                      <AccountCircle />
                    </IconButton>
                    <IconButton
                      size="large"
                      edge="end"
                      onClick={() => navigate('/profile')}
                      color="primary"
                      sx={{ display: { xs: "flex", sm: "none" } }}
                    >
                      <AccountCircle />
                    </IconButton>
                  </Box>
                ) : (
                  <Box>
                    <Button
                      sx={{
                        textTransform: "none",
                        color: "white",
                        backgroundColor: "#008080",
                        padding: "10px 16px",
                        opacity: 0.9,
                        ":hover": {
                          bgcolor: "#008080",
                          color: "white",
                          opacity: 1,
                        },
                        display: { xs: "none", sm: "block" },
                      }}
                      variant="text"
                      onClick={() => navigate("/login")}
                    >
                      <Typography color="white" fontSize={13} fontWeight={500}>
                        Sign In
                      </Typography>
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={"left"}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        classes={{ paper: classes?.drawer }}
      >
        <Box
          sx={{ width: "280px", height: "100%", backgroundColor: "#008080" }}
          padding={3}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <Box>
            <UserAvatar showEdit={false} />
            {mobileMenuList()}
          </Box>
          {!currentUser?.isHost && (
            <Box paddingTop={2}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={1}
                alignItems={"center"}
              >
                <Button
                  sx={{
                    textTransform: "none",
                    color: "white",
                    backgroundColor: "#c05942",
                    padding: 1,
                    width: 220,
                    opacity: 0.9,
                    ":hover": {
                      bgcolor: "#c05942",
                      color: "white",
                      opacity: 1,
                    },
                  }}
                  variant="text"
                  onClick={() => {
                    setMobileMenuAnchorEl(null);
                    navigate("/become-a-host");
                  }}
                >
                  <Typography
                    color="white"
                    fontSize={14}
                    fontWeight={500}
                    letterSpacing={"1px"}
                  >
                    Become a Host
                  </Typography>
                </Button>
                {/* <Button
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#008080",
                padding: "10px 16px",
                width: 250,
                ":hover": {
                  bgcolor: "#008080",
                  color: "white",
                },
              }}
              variant="text"
              onClick={handleAuth}
            >
              <Typography color="white" fontSize={13} fontWeight={500}>
                {currentUser ? "Sign Out" : "Sign In"}
              </Typography>
            </Button> */}
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
      {renderProfileMenu}
      {searchOpen && (
        <Search open={searchOpen} handleClose={() => setSearchOpen(false)} />
      )}
      {mapOpen && (
        <Location
          open={mapOpen}
          handleClose={() => setMapOpen(false)}
          onLocationSelected={onLocationSelected}
          location={currentLocation}
          currentAddress={currentAddress}
        />
      )}
      {notificationOpen && (
        <Notification
          open={notificationOpen}
          handleClose={() => setNotificationOpen(false)}
          notifications={notificationsData?.getNotificationByUserId}
        />
      )}
    </Box>
  );
};
