import { Button, Typography, Container, Grid, Box, Link } from "@mui/material";
import { Facebook, Twitter } from "@mui/icons-material";

export const Footer = () => {
  return (
    <Box bgcolor="white" color="#008080" sx={{ display: { xs: "none", sm: "block" } }}>
      <Box p={2} textAlign={'center'}>
          <Typography variant="body2">HobbyHopp © {new Date().getUTCFullYear()} All rights reserved</Typography>
        </Box>
      {/* <Container style={{ backgroundColor: "#87A5A5", borderRadius: "16px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Box display="flex" alignItems="center">
              <img alt="" src="/logo.svg" width="70px" height="44px" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">Information</Typography>
            <ul>
              <li>
                <Link color="inherit">About Us</Link>
              </li>
              <li>
                <Link color="inherit">Contact Us</Link>
              </li>
              <li>
                <Link color="inherit">Privacy Policy</Link>
              </li>
              <li>
                <Link color="inherit">Terms & Conditions</Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={1} sm={4}>
          <Button variant="contained"
            sx= { {backgroundColor: "#1B1819", borderRadius: "10px",color: "white", }}>
              About Us
            </Button>
            <Box mt={2}>
              <Typography variant="body2">+91 675565627222</Typography>
              <Typography variant="body2">info@ipsum.com</Typography>
            </Box>
          </Grid>
        </Grid>
        <Box mt={4} display="flex" alignItems="center">
          <Facebook />
          <Box mx={1}>
            <img alt="" src="/vector.svg" width="16px" height="16px" />
          </Box>
          <Twitter />
          <Box flexGrow={1} ml={1}>
            <Typography variant="body2">
              Noida, Uttar Pradesh, India
            </Typography>
          </Box>
        </Box>
        </Container>
        <Container>
        <Box mt={4} display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h4">Do you have any questions?</Typography>
            <Typography variant="body2">Feel free to send us your questions</Typography>
          </Box>
          <Button variant="contained"
            sx= { {backgroundColor: "#1B1819", borderRadius: "10px",color: "white", }}>
            Send a Message
          </Button>
        </Box>
        <Box mt={2} p={2} textAlign={'center'}>
          <Typography variant="body2">HobbyHopp © {new Date().getUTCFullYear()} All rights reserved.</Typography>
        </Box>
        </Container> */}
    </Box>
  );
};
