import { Header } from "./components/header";
import "./App.css";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { BottomBar } from "./components/bottom-bar";
import AppRoutes from "./AppRoute";
import { useEffect, useState } from "react";
import { LocationContext } from "./contexts/LocationContext";
import { Footer } from "./components/footer";
import { LocationDialog } from "./components/location-dialog";

function App() {
  const [currentLocation, setCurrentLocation] = useState({
    lat: 28.6139,
    lng: 77.209,
  });
  const [currentAddress, setCurrentAddress] = useState("");
  const [openLocationDialog, setOpenLocationDialog] = useState(false);
  const value = {
    currentLocation,
    setCurrentLocation,
    currentAddress,
    setCurrentAddress,
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: "#008080",
      },
      secondary: {
        main: "#c05942",
      },
    },
  });

  useEffect(() => {
    getCurrentLocation();
  }, []);

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(showPosition, showError);
  };

  const showPosition = (position: any) => {
    console.log("Location: ", position);
    const { latitude: lat, longitude: lng } = position.coords;
    setCurrentLocation({ lat, lng });
  };

  const showError = (error: any) => {
    setOpenLocationDialog(true);
  };

  return (
    <LocationContext.Provider value={value}>
      <AuthProvider>
        <Router>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Header />
            <AppRoutes />
            <Footer />
            <BottomBar />
          </ThemeProvider>
        </Router>
        {openLocationDialog && (
          <LocationDialog
            open={openLocationDialog}
            onClose={() => setOpenLocationDialog(false)}
          />
        )}
      </AuthProvider>
    </LocationContext.Provider>
  );
}

export default App;
