import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  DialogContentText,
} from "@mui/material";
import {
  dialogActionsStyles,
  dialogContentStyles,
  dialogHeaderStyles,
} from "../../styles/dialog";

interface LocationDialogProps {
  open: boolean;
  onClose: () => void;
}

export const LocationDialog: FC<LocationDialogProps> = ({
  open = false,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={dialogHeaderStyles}>
        Enable Location Services
      </DialogTitle>
      <DialogContent sx={dialogContentStyles}>
        <DialogContentText>
          For best experience, please enable your location services.
        </DialogContentText>
      </DialogContent>
      <DialogActions style={dialogActionsStyles}>
        <Button
          variant="outlined"
          sx={{
            color: "#008080",
            backgroundColor: "white",
            padding: "4px",
            fontSize: "12px",
            opacity: 0.9,
            border: "1px solid #008080",
            ":hover": {
              bgcolor: "white", // theme.palette.primary.main
              color: "#008080",
              opacity: 1,
              border: "1px solid #008080",
            },
          }}
          onClick={onClose}
        >
          <Typography fontSize={14} textTransform={"none"}>
            OK
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
