import { gql } from "@apollo/client";

export const GET_NOTIFICATION_BY_USER_ID = gql`
    query GetNotificationByUserId($userId: ID!) {
        getNotificationByUserId(userId: $userId) {
            id
            isRead
            message
            sentDate
            sentTo
        }
    }
`;

export const MARK_NOTIFICATION_AS_READ = gql`
    mutation MarkNotificationRead($id: ID!) {
        markNotificationRead(id: $id) {
            id
            isSuccess
            error
        }
    }
`;