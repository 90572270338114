import { SyntheticEvent, useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { Spinner } from "../../components/spinner";
import { useLazyQuery } from "@apollo/client";
import { GET_USER_CONNECTION } from "../../gql/user-connection";
import { ConnectionsCarousel } from "../../components/connections-carousel";
import { Requests } from "../../components/connections/requests";
import { NearbyPeople } from "../../components/connections/nearby-people";

export const Connections = () => {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();
  const [value, setValue] = useState("one");

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!loading && !currentUser) {
      navigate("/login?returnUrl=/connections");
    }
  }, [currentUser, navigate, loading]);

  const [userConnections, setUserConnections] = useState<any>([]);
  const [pendingForMyApprovalRequests, setPendingForMyApprovalRequests] =
    useState<any>([]);
  const [pendingForApprovalRequests, setPendingForApprovalRequests] =
    useState<any>([]);
  const [connections, setConnections] = useState<any>([]);

  const [getUserConnections, { loading: getUserConnectionsLoading }] =
    useLazyQuery(GET_USER_CONNECTION);

  useEffect(() => {
    if (currentUser) {
      getUserConnections({
        variables: {
          userId: currentUser?.profileInfo?.id,
        },
        onCompleted: (data: any) => {
          if (
            data &&
            data?.getUserConnection &&
            data?.getUserConnection?.length > 0
          ) {
            setUserConnections(data?.getUserConnection);
          }
        },
      });
    }
  }, [currentUser, getUserConnections]);

  useEffect(() => {
    if (userConnections && userConnections.length > 0) {
      const approvedConnections = userConnections.filter(
        (item: any) => item.status === "APPROVED"
      );
      setConnections(approvedConnections);
      const pendingConnections = userConnections.filter(
        (item: any) => item.status === "PENDING"
      );
      const pendingForMyApproval: any = [];
      const pendingForApproval: any = [];
      pendingConnections?.map((item: any) => {
        if (item.userId === currentUser?.profileInfo?.id) {
          pendingForApproval.push(item);
        } else {
          pendingForMyApproval.push(item);
        }
      });
      setPendingForMyApprovalRequests(pendingForMyApproval);
      setPendingForApprovalRequests(pendingForApproval);
    }
  }, [currentUser?.profileInfo?.id, userConnections]);

  if (getUserConnectionsLoading || loading) {
    return (
      <Box mt={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box style={{ paddingBottom: 64 }}>
      {/* <Box>
        <img
          src={require("../../images/bg.png")}
          alt="bg"
          width="100%"
          height="20%"
        />
      </Box> */}
      {!loading && (
        <>
          {!getUserConnectionsLoading && (
            <>
              <Box
                sx={{
                  backgroundColor: "#008080",
                  borderBottomLeftRadius: 60,
                  borderBottomRightRadius: 60,
                  display: 'flex',
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: connections && connections.length > 0 ? 'space-between' : 'center',
                  gap: { xs: 2, sm: 1 },
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
              >
                {!connections && connections.length > 0 ? (
                  <>
                    <Box>
                      <Box display={"flex"} flexDirection={"row"} gap={2}>
                        <ConnectionsCarousel
                          connections={connections}
                        ></ConnectionsCarousel>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <Box textAlign={"center"} p={2} width={'100%'}>
                    <Typography variant="h5" fontSize={18} color="white">
                      Currently, you have no connections.
                    </Typography>
                    <Typography variant="h5" fontSize={14} color="white">
                      Look for people near you with similar interests and send
                      them a request.
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  paddingX: { xs: 2, sm: 5, md: 10 },
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  sx={{
                    "& .MuiTab-root.Mui-selected": { color: "#008080" },
                    "& .MuiTab-root": { minWidth: 60, padding: "5px 10px" },
                    "& .MuiTabs-flexContainer": {
                      justifyContent: "space-between",
                    },
                  }}
                  variant="scrollable"
                >
                  <Tab
                    value="one"
                    label="People Near You"
                    style={{ textTransform: "none" }}
                  />
                  <Tab
                    value="two"
                    label="Request Received"
                    style={{ textTransform: "none" }}
                  />
                  <Tab
                    value="three"
                    label="Request Sent"
                    style={{ textTransform: "none" }}
                  />
                </Tabs>
                {value === "one" && <NearbyPeople />}
                {value === "two" && (
                  <Requests
                    requests={pendingForMyApprovalRequests}
                    errorMessage="You don't have any connection request."
                  />
                )}
                {value === "three" && (
                  <Requests
                    requests={pendingForApprovalRequests}
                    errorMessage="You haven't sent any connection request."
                  />
                )}
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};
