import { Box, Avatar, Typography, Tooltip, IconButton } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import EditIcon from "@mui/icons-material/Edit";
import { styled as muiStyled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { FC } from "react";

const EditIconButton = muiStyled(IconButton)({
  position: "absolute",
  bottom: 0,
  right: 0,
  backgroundColor: "#ffffff",
  borderRadius: "50%",
  padding: "2px",
  border: "2px solid #c05942",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
});

const getRandomNumber = Math.floor(Math.random() * 5) + 1;
export const UserAvatar: FC<any> = ({ showEdit = true, showName = true }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const getUserAvatar = () => {
    if (currentUser?.profileInfo?.userInfo?.image) {
      if (currentUser?.profileInfo?.userInfo?.image?.length === 1) {
        return require(`../../images/avatar${currentUser?.profileInfo?.userInfo?.image}.png`);
      } else {
        return currentUser?.profileInfo?.userInfo?.image;
      }
    } else {
      return require(`../../images/avatar${getRandomNumber}.png`);
    }
  };
  return (
    <Box>
      {currentUser ? (
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={1}
          alignItems={"center"}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={1}
            alignItems={"center"}
          >
            <Box style={{ display: "inline-block", position: "relative" }}>
              <Avatar
                src={getUserAvatar()}
                sx={[
                  styled.avatar,
                  {
                    "& .MuiAvatar-img": {
                      objectFit: "contain",
                      width: 80,
                      height: 80,
                    },
                  },
                ]}
              />
              {showEdit && (<Tooltip title="Edit Profile" placement="top">
                <EditIconButton aria-label="edit" onClick={() => navigate("/edit-profile?returnUrl=/profile")}>
                  <EditIcon fontSize="small" />
                </EditIconButton>
              </Tooltip>)}
            </Box>
            {showName && (<Box>
              <Typography
                fontSize={16}
                color={"white"}
                style={{ overflowWrap: "anywhere" }}
              >
                {currentUser?.profileInfo?.userInfo?.firstName ||
                  currentUser?.email ||
                  currentUser?.phoneNumber}
                &nbsp;
                {currentUser?.profileInfo?.userInfo?.lastName}
              </Typography>
              <Typography
                fontSize={12}
                color={"white"}
                style={{ overflowWrap: "anywhere" }}
              >
                {currentUser?.profileInfo?.userInfo?.address}
              </Typography>
            </Box>)}
          </Box>
        </Box>
      ) : (
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={1}
          alignItems={"center"}
        >
          <Box style={{ display: "inline-block", position: "relative" }}>
            <Avatar
              src={require(`../../images/avatar${getRandomNumber}.png`)}
              sx={[
                styled.avatar,
                { "& .MuiAvatar-img": { objectFit: "contain" } },
              ]}
            />
            {showEdit && (<Tooltip title="Edit Profile" placement="top">
              <EditIconButton aria-label="edit" onClick={() => navigate("/edit-profile?returnUrl=/profile")}>
                <EditIcon fontSize="small" />
              </EditIconButton>
            </Tooltip>)}
          </Box>
          <Box>
            <Typography
              fontSize={16}
              color={"white"}
              style={{ overflowWrap: "anywhere" }}
            >
              Hello, Guest
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const styled = {
  avatar: {
    width: 80,
    height: 80,
    border: "2px solid #c05942",
  },
};
