import { useContext, useEffect, useState } from "react";
import { useFormControls } from "./controls";
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { inputFieldValues } from "./data";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../config/firebase";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { CREATE_COMMUNITY } from "../../gql/communities";
import { useMutation, useQuery } from "@apollo/client";
import { LocationContext } from "../../contexts/LocationContext";
import { Location } from "../location";
import { GET_CATEGORIES } from "../../gql/categories";

export const CreateCommunityForm = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { currentLocation } = useContext(LocationContext);
  const [processing, setProcessing] = useState(false);
  const [result, setResult] = useState({ isSubmitted: false, message: "" });
  const [address, setAddress] = useState<string | undefined>();
  const [img, setImg] = useState(null);
  const [mapOpen, setMapOpen] = useState(false);
  const [communityName, setCommunityName] = useState(uuid());
  const [createCommunityMutation] = useMutation(CREATE_COMMUNITY, {
    refetchQueries: [
      "GetCommunities",
      "GetCommunityByUserId",
      "GetCommunityJoinedByUserId",
    ],
  });
  const { data: categoriesData } = useQuery(GET_CATEGORIES);
  const categories = categoriesData?.getCategories;
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    if (categories) {
      setSelectedCategory(categories[0]?.name);
    }
  }, [categories]);

  const { handleInputValue, handleFormSubmit, formIsValid, errors } =
    useFormControls();
  const [communityLocation, setCommunityLocation] = useState(currentLocation);

  const onLocationSelected = (coordinates: any, address?: string) => {
    setCommunityLocation(coordinates);
    setAddress(address);
  };

  const handleSubmit: any = async (community: any) => {
    setProcessing(true);
    if (img) {
      const storageRef = ref(storage, `communities/${communityName}`);

      const uploadTask = uploadBytesResumable(storageRef, img);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log("Uploading");
        },
        (error) => {
          alert(
            "Error while uploading community profile pic. Please try again after sometime."
          );
          console.log(error);
          return false;
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            const values = await handleFormSubmit(community);
            const communityObj = {
              name: values.name,
              description: values.description,
              image: downloadURL,
              category: values.category?.name,
              isPrivate: false,
              createdBy: currentUser?.profileInfo?.id,
              coordinates: JSON.stringify(communityLocation),
              address: address ? address : "Online",
            };
            createCommunityMutation({
              variables: {
                communityInput: {
                  ...communityObj,
                },
              },
              onCompleted: (data: any) => {
                if (data.createCommunity?.isSuccess) {
                  setResult({
                    isSubmitted: true,
                    message: "Thanks for creating a community.",
                  });
                  setProcessing(false);
                  navigate(`/community-detail?id=${data.createCommunity.id}`);
                } else {
                  alert(data.createCommunity?.error);
                }
              },
              onError: (error: any) => {
                console.log(error);
                setResult({
                  isSubmitted: false,
                  message:
                    "We are facing some issue. Please try again after sometime.",
                });
                setProcessing(false);
              },
            });
          });
        }
      );
    }
  };

  const handleMediaChange = (event: any) => {
    setImg(event.target.files[0]);
    const e = {
      target: {
        name: "image",
        value: event.target.files[0],
      },
    };
    handleInputValue(e);
  };

  return (
    <>
      {categories && (
        <Box style={{ backgroundColor: "white", borderRadius: 2 }}>
          {result && result.message && (
            <Typography
              color={result.isSubmitted ? "#c05942" : "red"}
              margin={2}
            >
              {result.message}
            </Typography>
          )}
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={"5px"}
            paddingTop={"20px"}
            paddingLeft={"10px"}
          >
            <InfoIcon style={{ fontSize: 16, fontWeight: "bold" }}></InfoIcon>
            <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
              Provide detail of your community
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "15px",
              marginBottom: "15px",
              borderTop: "1px solid #E1E1E1;",
            }}
          ></Box>
          <form autoComplete="off">
            <Box
              display={"flex"}
              flexDirection={"row"}
              flexWrap={"wrap"}
              gap={"1%"}
              paddingX={"20px"}
            >
              {inputFieldValues.map((inputFieldValue, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      textAlign: "start",
                      flex: { xs: "100%", md: inputFieldValue.flex },
                    }}
                  >
                    <Box>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                          marginBottom: 5,
                        }}
                      >
                        {inputFieldValue.label}
                      </Typography>
                      {inputFieldValue.isMedia && (
                        <Box>
                          <Button
                            style={{
                              width: "100%",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            <TextField
                              key={index}
                              onChange={handleMediaChange}
                              name={inputFieldValue.name}
                              type="file"
                              style={{
                                width: "100%",
                                padding: 20,
                                borderColor: "#f5f5f5",
                              }}
                            />
                          </Button>
                        </Box>
                      )}
                      {inputFieldValue.isDropdown && (
                        <Select
                          value={selectedCategory}
                          onChange={(e) => {
                            setSelectedCategory(e.target.value);
                            handleInputValue(e);
                          }}
                          fullWidth
                          style={{ backgroundColor: "#f5f5f5" }}
                          name={inputFieldValue.name}
                        >
                          {categories?.map((category: any, index: number) => {
                            return (
                              <MenuItem key={index} value={category.name}>
                                {category.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                      {/* {inputFieldValue.isToggle && (
                    <Box>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                      >
                        <Switch
                          key={index}
                          onChange={() => setIsPrivate(!isPrivate)}
                          name={inputFieldValue.name}
                          value={isPrivate}
                        />
                        <Typography>
                          {!isPrivate ? "Public" : "Private"}
                        </Typography>
                      </Box>
                      <Typography fontSize={12} color="#828282">
                        {!isPrivate
                          ? "Anyone can join this community"
                          : "You will approve join request"}
                      </Typography>
                    </Box>
                  )} */}
                      {!inputFieldValue.isMedia &&
                        !inputFieldValue.isDropdown &&
                        !inputFieldValue.isAddress && (
                          <TextField
                            key={index}
                            onChange={(e) => {
                              if (inputFieldValue.name === "name")
                                setCommunityName(e.target.value + "-" + uuid());
                              handleInputValue(e);
                            }}
                            onBlur={handleInputValue}
                            name={inputFieldValue.name}
                            label={inputFieldValue.placeholder}
                            error={errors[inputFieldValue.name]}
                            multiline={inputFieldValue.multiline ?? false}
                            fullWidth
                            rows={inputFieldValue.rows ?? 1}
                            autoComplete="none"
                            {...(errors[inputFieldValue.name] && {
                              error: true,
                              helperText: errors[inputFieldValue.name],
                            })}
                            style={{ backgroundColor: "#f5f5f5" }}
                            type={inputFieldValue.type}
                          />
                        )}
                      {inputFieldValue.isAddress && (
                        <TextField
                          key={index}
                          onClick={() => setMapOpen(true)}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                          onBlur={handleInputValue}
                          name={inputFieldValue.name}
                          label={address ? "" : inputFieldValue.placeholder}
                          error={errors[inputFieldValue.name]}
                          multiline={inputFieldValue.multiline ?? false}
                          fullWidth
                          rows={inputFieldValue.rows ?? 1}
                          autoComplete="none"
                          {...(errors[inputFieldValue.name] && {
                            error: true,
                            helperText: errors[inputFieldValue.name],
                          })}
                          style={{ backgroundColor: "#f5f5f5" }}
                          type={inputFieldValue.type}
                          value={address}
                        />
                      )}
                    </Box>
                    {index !== inputFieldValues.length - 1 && (
                      <Box
                        sx={{
                          marginTop: "20px",
                          marginBottom: "15px",
                          borderTop: "1px solid #E1E1E1;",
                        }}
                      ></Box>
                    )}
                  </Box>
                );
              })}
            </Box>
            <Typography
              fontSize={14}
              textTransform={"none"}
              textAlign="right"
              mr={2}
              color="#c05942"
            >
              *If address is not provided, it will be marked as <b>Online</b>{" "}
              community.
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              padding={"20px"}
              gap={1}
            >
              {/* <Button
            variant="contained"
            style={{
              backgroundColor: "#c05942",
              color: "white",
              padding: "10px 15px",
            }}
            onClick={() => setMapOpen(true)}
          >
            <Typography fontSize={14} textTransform={"none"}>
              Select Location
            </Typography>
          </Button> */}
              <Button
                variant="contained"
                disabled={!formIsValid()}
                sx={{
                  backgroundColor: "#c05942",
                  color: "white",
                  padding: "10px 15px",
                  "&: hover": {
                    backgroundColor: "#c05942",
                  },
                }}
                onClick={handleSubmit}
              >
                {!processing ? (
                  <Typography fontSize={14} textTransform={"none"}>
                    Create Community
                  </Typography>
                ) : (
                  <Typography fontSize={14} textTransform={"none"}>
                    Creating...
                  </Typography>
                )}
              </Button>
            </Box>
          </form>
          {mapOpen && (
            <Location
              open={mapOpen}
              handleClose={() => setMapOpen(false)}
              onLocationSelected={onLocationSelected}
              location={communityLocation}
              currentAddress={address}
            />
          )}
        </Box>
      )}
    </>
  );
};
