import { gql } from "@apollo/client";

export const GET_CONTACT_US = gql`
    query GetContactUs {
        getContactUs {
            id
            firstName
            lastName
            email
            phone
            message
        }
    }
`;

export const CREATE_CONTACT_US = gql`
    mutation CreateContactUs($contactUsInput: ContactUsInput) {
        createContactUs(contactUsInput: $contactUsInput) {
            id
            isSuccess
            error
        }
    }
`;