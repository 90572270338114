import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { EventCard } from "../event-card";
import { FC, useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { GET_USER_EVENT_FAVOURITE_BY_USER_ID } from "../../gql/events";
import { useQuery } from "@apollo/client";

interface EventCarouselProps {
  events: any;
}

export const EventCarousel: FC<EventCarouselProps> = ({ events }) => {
  const { currentUser } = useAuth();
  const [filteredEvents, setFilteredEvents] = useState<any[]>([]);

  useEffect(() => {
    const eventArr: any[] = [];
    events.map((item: any) => {
      if (isEventValid(item)) {
        eventArr.push(item);
      }
    });
    setFilteredEvents(eventArr);
  }, [events]);

  const isEventValid = (event: any) => {
    if (event.cancelledBy) return false;
    if (event.recurringDays) return true;
    const condition =
      new Date(
        `${event.date?.toString()} ${event.time?.toString()}`
      ).getTime() > new Date().getTime();
    return condition;
  };

  const { data: userEventFavouriteData } = useQuery(
    GET_USER_EVENT_FAVOURITE_BY_USER_ID,
    {
      variables: {
        userId: currentUser?.profileInfo?.id,
      },
    }
  );

  const getUserEventFavourite = (id: String) => {
    const favEvents =
      userEventFavouriteData?.getUserEventFavouriteByUserId?.filter(
        (item: any) => item.eventId === id
      );
    if (favEvents?.length > 0) return true;
    return false;
  };

  return (
    <ImageList
      className="event-community-carousel"
      sx={{
        gridAutoFlow: "column",
        gridTemplateColumns: {
          xs: "repeat(auto-fit, minmax(80%, 2fr)) !important",
          sm: "repeat(auto-fit, minmax(44%, 2fr)) !important",
          lg: "repeat(auto-fit, minmax(28%, 2fr)) !important",
        },
        gridAutoColumns: {
          xs: "minmax(80%, 2fr)",
          sm: "minmax(44%, 2fr)",
          lg: "minmax(28%, 2fr)",
        },
        overflowX: "auto",
        paddingBottom: 2,
      }}
    >
      {filteredEvents.map((item: any, index: number) => (
        <ImageListItem key={index} sx={{ paddingRight: 1 }}>
          <EventCard
            event={item}
            isFavourite={getUserEventFavourite(item.id)}
          ></EventCard>
        </ImageListItem>
      ))}
    </ImageList>
  );
};
