import { useState, useEffect } from "react";
import { useFormControls } from "./controls";
import { Box, Button, Link, TextField, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";

export const LoginForm = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const returnUrl = searchParams.get("returnUrl");
  const { currentUser, signInWithGoogle, signInWithPhone } = useAuth();
  const [otpVerifier, setOtpVerifier] = useState<any>();
  const [otpSent, setOtpSent] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState({ isSubmitted: false, message: "" });
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const { handleInputValue, handleFormSubmit, formIsValid, errors } =
    useFormControls();
  useEffect(() => {
    if (currentUser) {
      if (returnUrl) navigate(returnUrl);
      else navigate("/");
    }
  }, [currentUser, navigate, returnUrl]);

  // const handleSubmit = async (event: any) => {
  //   try {
  //   setProcessing(true);
  //   const result = await handleFormSubmit(event);
  //   setResult({...result});
  //   setProcessing(false);
  //   } catch (error) {
  //     setResult({isSubmitted: false, message: 'Failed to login. Please try again after sometime.'})
  //   }
  // };

  const logInWithGoogle = async (e: any) => {
    setResult({ isSubmitted: false, message: "" });
    try {
      e.preventDefault();
      await signInWithGoogle();
      if (returnUrl) navigate(returnUrl);
      else navigate("/");
    } catch (error) {
      setResult({
        isSubmitted: false,
        message: "Failed to login. Please try again after sometime.",
      });
    }
  };

  const logInWithPhone = async (e: any) => {
    setResult({ isSubmitted: false, message: "" });
    try {
      e.preventDefault();
      setProcessing(true);
      const otpVerifier = await signInWithPhone('+91' + phone);
      setOtpVerifier(otpVerifier);
      setProcessing(false);
      setOtpSent(true);
      setMinutes(2);
      setSeconds(59);
    } catch (error) {
      setProcessing(false);
      setResult({
        isSubmitted: false,
        message: "Incorrect phone number",
      });
    }
  };

  const verifyOtp = async (e: any) => {
    setResult({ isSubmitted: false, message: "" });
    try {
      e.preventDefault();
      setProcessing(true);
      await otpVerifier?.confirm(otp);
      setProcessing(false);
      setOtpSent(false);
      if (returnUrl) navigate(returnUrl);
      else navigate("/");
    } catch (error) {
      setProcessing(false);
      setResult({
        isSubmitted: false,
        message: "Wrong OTP entered",
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      style={{ backgroundColor: "white", borderRadius: 10, padding: 40 }}
    >
      <Box gap={1} display={"flex"} flexDirection={"column"} marginBottom={1}>
        <Typography>Welcome Back</Typography>
        <Typography variant="h2" fontSize={24} fontWeight={"700"}>
          Log In to your account
        </Typography>
      </Box>
      <Box>
        {result && result.message && (
          <Typography color={result.isSubmitted ? "#008080" : "red"} marginY={1}>
            {result.message}
          </Typography>
        )}
        <div id="recaptcha-container"></div>
        <form onSubmit={(e: any) => e.preventDefault()}>
          <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
            {!otpSent ? (
              <Box sx={{ width: '100%' }}>
                <Box sx={{ marginBottom: "3%", flex: "100%" }} key="phone">
                  <TextField
                    onChange={(event) => setPhone(event.target.value)}
                    onBlur={handleInputValue}
                    name="phone"
                    label="Phone"
                    fullWidth
                    {...(errors['phone'] && {
                      error: true,
                      helperText: errors['phone'],
                    })}
                    error={errors['phone']}
                    style={{ width: "100%" }}
                    inputProps={{ maxLength: 10 }}
                    type="number"
                    variant="outlined"
                  />
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                  <Button
                    variant="contained"
                    disabled={!formIsValid()}
                    style={{
                      backgroundColor: "#008080",
                      color: "white",
                      width: "100%",
                      padding: "10px 15px",
                    }}
                    onClick={(e) => logInWithPhone(e)}
                  >
                    {!processing ? (
                      <Typography fontSize={14} textTransform={"none"}>Send OTP</Typography>
                    ) : (
                      <Typography fontSize={14} textTransform={"none"}>Sending...</Typography>
                    )}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box sx={{ width: '100%' }}>
                <Box sx={{ marginBottom: "3%", flex: "100%" }} key="otp">
                  <TextField
                    type="number"
                    onChange={(event) => setOtp(event.target.value)}
                    onBlur={handleInputValue}
                    name="otp"
                    label="OTP"
                    style={{ width: "100%" }}
                  />
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                  <Button
                    variant="contained"
                    disabled={!otp}
                    style={{
                      backgroundColor: "#008080",
                      color: "white",
                      width: "100%",
                      padding: "10px 15px",
                    }}
                    onClick={verifyOtp}
                  >
                    {!processing ? (
                      <Typography fontSize={14} textTransform={'none'}>Verify OTP</Typography>
                    ) : (
                      <Typography fontSize={14} textTransform={'none'}>Verifying...</Typography>
                    )}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </form>
        <Box marginY={1}>
          {otpSent && (
            <Box>
              {seconds > 0 || minutes > 0 ? (
                <Typography color="#008080" fontSize={12}>
                  Resend OTP in: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </Typography>
              ) : (
                <Typography color="#777777" fontSize={12}>
                  Didn't recieve code?{" "}
                  <Typography
                    component={"span"}
                    color="#008080"
                    fontSize={12}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={(e) => logInWithPhone(e)}
                  >
                    Resend OTP
                  </Typography>
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Box marginY={1} textAlign={"center"}>
          <Typography style={{ backgroundColor: "white", fontSize: 12 }}>
            Or
          </Typography>
        </Box>
        <Box marginY={1} display={"flex"} justifyContent={"center"}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#c05942",
              color: "#fff",
              width: "100%",
              padding: 15,
              borderRadius: 15,
              border: "1px solid #c05942",
            }}
            onClick={logInWithGoogle}
          >
            <GoogleIcon
              style={{ fontSize: 16, marginRight: 10, color: "#fff" }}
            ></GoogleIcon>
            <Typography fontSize={12} textTransform={'none'}>Log In with Google</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
