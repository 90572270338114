
import { Route, Routes, Navigate } from 'react-router-dom';
// import { AboutUs } from './pages/AboutUs';
import { ContactUs } from './pages/Contact-Us';
import { Home } from './pages/Home';
import { NotFound } from './pages/NotFound';
import { Event } from './pages/Event';
import { EventDetail } from './pages/EventDetail';
import { CommunityDetail } from './pages/CommunityDetail';
import { Community } from './pages/Community';
import { Login } from './pages/authentication/login';
import { CreateEvent } from './pages/CreateEvent';
import { EditEvent } from './pages/EditEvent';
import { CreateCommunity } from './pages/CreateCommunity';
import { BecomeHost } from './pages/BecomeHost';
import { Profile } from './pages/Profile';
import { EditProfile } from './pages/EditProfile';
import { HostProfile } from './pages/HostProfile';
import { ManageEvents } from './pages/ManageEvents';
import { ManageCommunities } from './pages/ManageCommunities';
import { ProtectedRoute } from './pages/ProtectedRoute';
import { useAuth } from './contexts/AuthContext';
import { useContext, useEffect } from 'react';
import { LocationContext } from './contexts/LocationContext';
import { Connections } from './pages/Connections';
import { UPDATE_USER_LOCATION } from './gql/user';
import { useMutation } from '@apollo/client';

function AppRoutes() {
  const { currentUser } = useAuth();
  const { currentLocation } = useContext(LocationContext);
  const [updateUserLocationMutation] = useMutation(UPDATE_USER_LOCATION);

  useEffect(() => {
    if (currentUser && currentLocation) {
      updateUserLocationMutation({
        variables: {
          updateUserLocationInput: {
            id: currentUser?.profileInfo?.id,
            coordinates: currentLocation
          },
        },
        onCompleted: (data: any) => {
          if (data.updateUserName) {
            console.log("User Location is updated successfully");
          }
        },
        onError: (error: any) => {
          console.log(error);
          alert(
            "Error while updating user location. Please try again after sometime."
          );
        },
      });
    }
  }, [currentUser, currentLocation, updateUserLocationMutation]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/events" element={<Event />} />
      <Route path="/event-detail" element={<EventDetail />} />
      <Route path="/create-event" element={<CreateEvent />} />
      <Route path="/edit-event" element={<EditEvent />} />
      <Route path="/community-detail" element={<CommunityDetail />} />
      <Route path="/create-community" element={<CreateCommunity />} />
      <Route path="/manage-events" element={<ManageEvents />} />
      <Route path="/manage-communities" element={<ManageCommunities />} />
      <Route path="/communities" element={<Community />} />
      {/* <Route path="/about-us" element={<AboutUs />} /> */}
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/become-a-host" element={<BecomeHost />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/edit-profile" element={<EditProfile />} />
      <Route path="/host-profile" element={<HostProfile />} />
      <Route path="/admin" element={<ProtectedRoute route="/admin" />} />
      <Route path="/connections" element={<Connections />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}

export default AppRoutes;
