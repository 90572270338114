import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import {
  GET_USER_COMMUNITY_BY_ID,
  GET_COMMUNITY_BY_USER_ID,
  DELETE_COMMUNITY,
} from "../../gql/communities";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Spinner } from "../../components/spinner";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ConfirmDialog } from "../../components/confirm-dialog";

export const ManageCommunities = () => {
  let navigate = useNavigate();
  const { currentUser, loading } = useAuth();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [getUserCommunityByIdQuery] = useLazyQuery(GET_USER_COMMUNITY_BY_ID);
  const [mLoading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedCommunityId, setSelectedCommunityId] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [deleteCommunityMutation] = useMutation(DELETE_COMMUNITY, {
    refetchQueries: ["GetCommunityByUserId"],
  });

  useEffect(() => {
    if (!loading && !currentUser) navigate("/login?returnUrl=/manage-communities");
  });
  const { data: createdCommunitiesData, loading: createdCommunitiesLoading } = useQuery(
    GET_COMMUNITY_BY_USER_ID,
    {
      variables: {
        userId: currentUser?.profileInfo?.id,
      },
    }
  );

  const expandCommunityDetails = async (community: any, index: any) => {
    setLoading(true);
    if (currentIndex === index) {
      setLoading(false);
      setCurrentIndex(null);
    } else {
      setCurrentIndex(index);
      const getUserCommunityByIdResult = await getUserCommunityByIdQuery({
        variables: {
          id: community?.id,
        },
      });
      const result = getUserCommunityByIdResult?.data?.getUserCommunityById;
      setLoading(false);
      console.log(result);
      let rowData: any = [];
      result?.map((userCommunity: any, index: number) => {
        const rowObj = {
          id: userCommunity.id,
          communityId: userCommunity.id,
          joinedBy: userCommunity?.user?.phone ? userCommunity?.user?.phone : userCommunity?.user?.email,
          joinedOn: userCommunity?.joinedDate,
        };
        rowData.push(rowObj);
      });
      setRows(rowData);
    }
  };

  const deleteCommunity = (community: any) => {
    setSelectedCommunityId(community?.id);
    setConfirmMessage('Are you sure you want to delete this community?');
    setOpenConfirm(true);
  };

  const onConfirm = () => {
    setOpenConfirm(false);
    setLoading(true);
      const deleteCommunityObj = {
        communityId: selectedCommunityId,
        updatedBy: currentUser?.profileInfo?.id,
      };
      deleteCommunityMutation({
        variables: {
          deleteCommunityInput: {
            ...deleteCommunityObj,
          },
        },
        onCompleted: (data: any) => {
          if (data?.deleteCommunity?.isSuccess) {
            console.log("Deleted Community");
            setLoading(false);
            setOpenConfirm(false);
          }
        },
        onError: (error: any) => {
          console.log(error);
          setLoading(false);
          alert(
            "Error while processing this request, please try again after sometime."
          );
        },
      });
  };

  const columns: GridColDef[] = [
    { field: "communityId", headerName: "Id", width: 150 },
    { field: "joinedBy", headerName: "Joined By", width: 300, },
    { field: "joinedOn", headerName: "Joined On", width: 150 },
  ];

  if (createdCommunitiesLoading) {
    return (
      <Box mt={4}>
        <Spinner />
      </Box>
    );
  }
  return (
    <Box>
      <Box
        width={"100%"}
        style={{ backgroundColor: "#008080", display: "flex" }}
        py={2}
        pl={2}
      >
        <KeyboardArrowLeftIcon
          style={{ color: "white", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <Typography component="span" color={"white"} fontSize={18}>
          Manage Communities
        </Typography>
      </Box>
      <Box
        sx={{
          paddingX: { xs: 2, sm: 10 },
          paddingY: 2,
        }}
      >
        <Box display={"flex"} flexDirection={"column"}>
          {createdCommunitiesData &&
            createdCommunitiesData?.getCommunityByUserId &&
            createdCommunitiesData?.getCommunityByUserId.map(
              (item: any, index: number) => {
                return (
                  <List key={index}>
                    <ListItemButton
                      onClick={() => expandCommunityDetails(item, index)}
                      style={{ backgroundColor: "#c05942", color: "white" }}
                    >
                      <ListItemText primary={item?.name} />
                      {currentIndex === index ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                      in={currentIndex === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box>
                        <Box
                          m={2}
                          display={"flex"}
                          flexDirection={"row"}
                          gap={2}
                          justifyContent={"space-between"}
                        >
                          <Box>
                            <Typography
                              component="span"
                              color={"#008080"}
                              fontSize={14}
                            >
                              Created Date:&nbsp;
                            </Typography>

                            <Typography
                              component="span"
                              color={"#777777"}
                              fontSize={14}
                            >
                              {item?.createdDate}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component="span"
                              color={"#008080"}
                              fontSize={14}
                            >
                              Members:&nbsp;
                            </Typography>

                            <Typography
                              component="span"
                              color={"#777777"}
                              fontSize={14}
                            >
                              {item?.joinedMembers}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          m={2}
                          display={"flex"}
                          flexDirection={"row"}
                          gap={2}
                          justifyContent={"space-between"}
                        >
                          <Box>
                                <Typography
                                  component="span"
                                  color={"#c05942"}
                                  fontSize={14}
                                  style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    navigate(`/community-detail?id=${item.id}`)
                                  }
                                >
                                  View
                                </Typography>
                              </Box>
                          <Box>
                            <Typography
                              component="span"
                              color={"red"}
                              fontSize={14}
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => deleteCommunity(item)}
                            >
                              Delete
                            </Typography>
                          </Box>
                        </Box>
                        {mLoading && (
                          <Box mt={4}>
                            <Spinner />
                          </Box>
                        )}
                        {rows && rows.length > 0 ? (
                          <div style={{ width: "100%" }}>
                            <DataGrid
                              rows={rows}
                              columns={columns}
                              initialState={{
                                pagination: {
                                  paginationModel: { page: 0, pageSize: 5 },
                                },
                              }}
                              pageSizeOptions={[5, 10]}
                            />
                          </div>
                        ) : (
                          <>
                            {!mLoading && (
                              <Box textAlign={"center"}>
                                <Typography
                                  component="span"
                                  color={"#777777"}
                                  fontSize={14}
                                  style={{
                                    textDecoration: "underline",
                                  }}
                                >
                                  No user has joined this community yet.
                                </Typography>
                              </Box>
                            )}
                          </>
                        )}
                      </Box>
                    </Collapse>
                  </List>
                );
              }
            )}
        </Box>
      </Box>
      {openConfirm && <ConfirmDialog open={openConfirm} onClose={() => setOpenConfirm(false)} onConfirm={onConfirm} message={confirmMessage}/>}
    </Box>
  );
};
