import { FunctionComponent } from "react";
import { Box, Typography } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ContactUsForm } from '../../components/contact-us-form';
import { useNavigate } from "react-router-dom";

export const ContactUs: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <Box style={{ backgroundColor: "#F5F5F5" }} pb={8}>
      <Box>
        <img
          src={require("../../images/bg.png")}
          alt="bg"
          width="100%"
          height="20%"
        />
      </Box>
      <Box
        sx={{
          paddingX: { xs: 2, md: 5, lg: 20 },
          paddingY: 2,
          textAlign: "center",
        }}
      >
        <Box justifyContent={"center"} alignItems={"center"}>
          <Typography variant="h4" fontWeight={1000}>
            Contact Us
          </Typography>
          <Typography variant="h5" fontSize={16} color="#888491" marginTop={1}>
            Any question or remarks? Just write us a message!
          </Typography>
        </Box>
        <Box display={"flex"} marginY={2} sx={{ flexDirection: { xs: 'column', sm: 'row' }, backgroundColor: "white", borderRadius: 1 }}>
          <Box style={{ backgroundColor: "#008080", borderRadius: 5, margin: 16 }}>
            <Box margin={5} width={'100%'}>
              <Box
                marginY={5}
                alignItems={"flex-start"}
                flexDirection={"column"}
                display={"flex"}
              >
                <Typography variant="h5" fontWeight={700} color="white">
                  Contact Information
                </Typography>
                <Typography variant="h5" fontSize={16} color="#c1c1c1">
                  Send us your message!
                </Typography>
              </Box>
              <Box marginY={5}>
                <Box
                  marginY={4}
                  flexDirection={"row"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <PhoneIcon
                    style={{ color: "white", fontSize: 30, paddingRight: 10 }}
                  ></PhoneIcon>
                  <Typography color="white">+91 8826761919</Typography>
                </Box>
                <Box
                  marginY={4}
                  flexDirection={"row"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <MailIcon
                    style={{ color: "white", fontSize: 30, paddingRight: 10 }}
                  ></MailIcon>
                  <Typography color="white">ayush.gaur13081997@gmail.com</Typography>
                </Box>
                <Box
                  marginY={4}
                  flexDirection={"row"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <LocationOnIcon
                    style={{ color: "white", fontSize: 30, paddingRight: 10 }}
                  ></LocationOnIcon>
                  <Typography color="white">Noida, Uttar Pradesh, India</Typography>
                </Box>
              </Box>
              <Box marginY={5} display={"flex"} flexDirection={"row"} gap={3}>
                <Box>
                  <TwitterIcon onClick={() => navigate('/')}
                    style={{ color: "white", fontSize: 30, paddingRight: 10, cursor: 'pointer' }}
                  ></TwitterIcon>
                </Box>
                <Box>
                  <FacebookIcon onClick={() => navigate('/')}
                    style={{ color: "white", fontSize: 30, paddingRight: 10, cursor: 'pointer' }}
                  ></FacebookIcon>
                </Box>
                <Box>
                  <InstagramIcon onClick={() => navigate('/')}
                    style={{ color: "white", fontSize: 30, paddingRight: 10, cursor: 'pointer' }}
                  ></InstagramIcon>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 6 }}>
			  <Box style={{ margin: 10 }}>
				<ContactUsForm></ContactUsForm>
			  </Box>
		  </Box>
        </Box>
      </Box>
    </Box>
  );
};
