import { Modal, Box, Typography, Button, CircularProgress } from "@mui/material";
import { FC, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@apollo/client";
import { MARK_NOTIFICATION_AS_READ } from "../../gql/notifications";

export const Notification: FC<any> = ({ open, handleClose, notifications }) => {
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<null | number>(null);
  const [markAsReadMutation] = useMutation(MARK_NOTIFICATION_AS_READ, {
    refetchQueries: ["GetNotificationByUserId"],
  });

  const markAsRead = (id: string, index: number) => {
    setCurrentIndex(index);
    setLoading(true);
    markAsReadMutation({
      variables: {
        id: id,
      },
      onCompleted: (data: any) => {
        if (data?.markNotificationRead?.isSuccess) {
          console.log("Notification marked as read");
          setLoading(false);
        }
      },
      onError: (error: any) => {
        console.log(error);
        setLoading(false);
        alert(
          "Error while processing this request, please try again after sometime."
        );
      },
    });
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: { xs: "10%", sm: "20%" },
          left: { xs: "10%", sm: "20%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          width: { xs: "80%", sm: "60%" },
          height: { xs: "80%", sm: "60%" },
          overflow: "scroll",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#008080",
          }}
          p={2}
        >
          <Typography color={"white"}>Notifications</Typography>
          <CloseIcon
            style={{ color: "white", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>
        {!notifications || notifications.length === 0 ? (
          <Box p={2} textAlign={"center"} alignItems={"center"}>
            <Typography>You do not have any notification.</Typography>
          </Box>
        ) : (
          <Box p={2} textAlign={"center"} alignItems={"center"}>
            {notifications &&
              notifications.map((notification: any, index: number) => {
                return (
                  <Box
                    key={index}
                    p={2}
                    style={{ borderRadius: "8px", backgroundColor: "#f5f5f5" }}
                    mb={1}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    gap={1}
                  >
                    <Typography color="grey" fontSize={12}>
                      {notification?.message}
                    </Typography>
                    {!notification?.isRead && (
                      <Box>
                        <Button
                          sx={{
                            color: "white",
                            backgroundColor: "#008080",
                            padding: "5px 8px",
                            fontSize: "10px",
                            opacity: 0.9,
                            ":hover": {
                              bgcolor: "#008080", // theme.palette.primary.main
                              color: "white",
                              opacity: 1,
                            },
                          }}
                          onClick={() => markAsRead(notification?.id, index)}
                        >
                          {loading && index === currentIndex ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              style={{ width: 14, height: 14 }}
                            />
                          ) : (
                            <Typography
                              component="span"
                              textTransform={"none"}
                              fontSize={12}
                            >
                              Mark as Read
                            </Typography>
                          )}
                        </Button>
                      </Box>
                    )}
                  </Box>
                );
              })}
          </Box>
        )}
      </Box>
    </Modal>
  );
};
