import { gql } from "@apollo/client";

export const GET_PEOPLE_NEAR_YOU = gql`
    query GetUserBasedonTags($getUserBasedonTagsInput: getUserBasedonTagsInput) {
        getUserBasedonTags(getUserBasedonTagsInput: $getUserBasedonTagsInput) {
            id
            userInfo {
                bio
                firstName
                lastName
                image
                userId
            }
            userTag {
                tag
            }
            userConnection {
                userId
                connectedTo
                status
            }
        }
    }   
`;

export const GET_USER_CONNECTION = gql`
    query GetUserConnection($userId: ID!) {
        getUserConnection(userId: $userId) {
            id
            connectedTo
            status
            userId
            connectedToUser {
                id
                userInfo {
                    firstName
                    lastName
                    bio
                    image
                }
                userTag { 
                    tag
                }
            userConnection {
                id
                status  
            }
            }
            user {
                id
                userInfo {
                    firstName
                    lastName
                    bio
                    image
                }
                userTag { 
                    tag
                }
            userConnection {
                id
                status  
            }
            }
            userPhone
            userMail
            connectedUserPhone
            connectedUserMail
        }
    }
`;

export const CREATE_USER_CONNECTION = gql`
    mutation CreateUserConnection($userConnectionInput: UserConnectionInput!) {
        createUserConnection(userConnectionInput: $userConnectionInput) {
            isSuccess
        }
    }
`;

export const UPDATE_USER_CONNECTION = gql`
    mutation UpdateUserConnection($userConnectionInput: UpdateUserConnectionInput!) {
        updateUserConnection(userConnectionInput: $userConnectionInput) {
            isSuccess
        }
    }
`;

export const DELETE_USER_CONNECTION = gql`
    mutation DeleteUserConnection($id: ID!) {
        deleteUserConnection(id: $id) {
            isSuccess
        }
    }
`;