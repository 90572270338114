import { gql } from "@apollo/client";

export const GET_TEASERS = gql`
    query GetTeasers {
        getTeasers {
            id
            link
            image
            order
        }
    }
`;
